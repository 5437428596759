define('jason-frontend/templates/customers/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[4,30],[4,40]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[4,6],[4,96]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[5,31],[5,42]]]]],[],[]]],["loc",[null,[5,6],[5,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context mr10");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Kund:in zusammenführen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1,"id","customersVcardExportForm");
          dom.setAttribute(el1,"method","post");
          dom.setAttribute(el1,"action","api/customers/exportVcard");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","mandant_id");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","token");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","filterId");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","with-icon button context");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("vCard herunterladen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context ml10");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Umsatzauswertung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element30 = dom.childAt(fragment, [1]);
          var element31 = dom.childAt(fragment, [3]);
          var element32 = dom.childAt(element31, [1]);
          var element33 = dom.childAt(element31, [5]);
          var element34 = dom.childAt(element31, [7]);
          var element35 = dom.childAt(fragment, [5]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element30);
          morphs[1] = dom.createMorphAt(element30,1,1);
          morphs[2] = dom.createAttrMorph(element32, 'value');
          morphs[3] = dom.createAttrMorph(element33, 'value');
          morphs[4] = dom.createElementMorph(element34);
          morphs[5] = dom.createMorphAt(element34,1,1);
          morphs[6] = dom.createElementMorph(element35);
          morphs[7] = dom.createMorphAt(element35,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openMergeCustomer"],[],["loc",[null,[10,9],[10,39]]]],
          ["inline","button-merge",[],["content","","color","#FFF","size","16"],["loc",[null,[11,8],[11,58]]]],
          ["attribute","value",["concat",[["get","mandantId",["loc",[null,[15,56],[15,65]]]]]]],
          ["attribute","value",["concat",[["get","model.id",["loc",[null,[17,54],[17,62]]]]]]],
          ["element","action",["checkForm","customersVcardExportForm"],[],["loc",[null,[18,49],[18,98]]]],
          ["inline","button-download2",[],["content","vCard herunterladen","color","#FFF","size","16"],["loc",[null,[19,10],[19,83]]]],
          ["element","action",["openRevenue",["get","model.id",["loc",[null,[23,32],[23,40]]]]],[],["loc",[null,[23,9],[23,42]]]],
          ["inline","icon-cash",[],["color","#fff","size","20","showStroke",false],["loc",[null,[24,8],[24,61]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 33,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","with-icon button context");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Ordnerinhalt herunterladen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element28 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element28);
            morphs[1] = dom.createMorphAt(element28,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadFolder"],[],["loc",[null,[29,11],[29,38]]]],
            ["inline","button-download2",[],["content","vCard herunterladen","color","#FFF","size","16"],["loc",[null,[30,10],[30,83]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 37,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element29 = dom.childAt(fragment, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createElementMorph(element29);
          morphs[2] = dom.createMorphAt(element29,1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["get","filterName",["loc",[null,[28,17],[28,27]]]]],[],["loc",[null,[28,12],[28,28]]]]],[],0,null,["loc",[null,[28,6],[33,13]]]],
          ["element","action",["openDocumentUpload"],[],["loc",[null,[34,9],[34,40]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[35,8],[35,69]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 4
            },
            "end": {
              "line": 42,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Tier");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element27);
          return morphs;
        },
        statements: [
          ["element","action",["showCreatePatient"],[],["loc",[null,[39,9],[39,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 4
            },
            "end": {
              "line": 47,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element26 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element26);
          return morphs;
        },
        statements: [
          ["element","action",["openNewAppointment"],[],["loc",[null,[44,9],[44,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 6
              },
              "end": {
                "line": 58,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","with-icon button context primary");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Guthaben ausbezahlen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element24);
            morphs[1] = dom.createMorphAt(element24,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["payoutDeposit",["get","model",["loc",[null,[54,36],[54,41]]]]],[],["loc",[null,[54,11],[54,43]]]],
            ["inline","button-cash",[],["color","#fff","size","32","showStroke",false],["loc",[null,[55,10],[55,65]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 4
            },
            "end": {
              "line": 59,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldoübersicht drucken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element25 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element25);
          morphs[1] = dom.createMorphAt(element25,1,1);
          morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["print",["get","model",["loc",[null,[49,26],[49,31]]]]],[],["loc",[null,[49,9],[49,33]]]],
          ["inline","button-print",[],["color","#fff","size","32","showStroke",false],["loc",[null,[50,8],[50,64]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[53,16],[53,26]]]],0],[],["loc",[null,[53,12],[53,29]]]]],[],0,null,["loc",[null,[53,6],[58,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 4
            },
            "end": {
              "line": 64,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element23 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element23);
          morphs[1] = dom.createMorphAt(element23,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showCreateConsent"],[],["loc",[null,[61,9],[61,39]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[62,8],[62,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 60
            },
            "end": {
              "line": 69,
              "column": 204
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","button-ical",[],["size",48,"classNames","inline-block vertical-bottom","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[69,91],[69,204]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 204
              },
              "end": {
                "line": 69,
                "column": 362
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-ical",[],["size",48,"classNames","inline-block vertical-bottom","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[69,240],[69,362]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 204
            },
            "end": {
              "line": 69,
              "column": 362
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.etigaCodeRequested",["loc",[null,[69,214],[69,238]]]]],[],0,null,["loc",[null,[69,204],[69,362]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 6
              },
              "end": {
                "line": 75,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","clear title title--big");
            var el2 = dom.createTextNode("Guthaben: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[74,84],[74,94]]]]],[],[]]],["loc",[null,[74,60],[74,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 115
                  },
                  "end": {
                    "line": 77,
                    "column": 145
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("en");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 15
                },
                "end": {
                  "line": 77,
                  "column": 162
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Ausgangsrechnung");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" (Entwurf)");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","invoiceMeta.total_records",["loc",[null,[77,69],[77,98]]]],
              ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[77,125],[77,138]]]],1],[],["loc",[null,[77,121],[77,141]]]]],[],0,null,["loc",[null,[77,115],[77,152]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 6
              },
              "end": {
                "line": 78,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
            return morphs;
          },
          statements: [
            ["block","link-to",["cash.choose"],["classNames","is-highlighted"],0,null,["loc",[null,[77,15],[77,174]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 2
            },
            "end": {
              "line": 80,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","invoice__saldo");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldo: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var element22 = dom.childAt(element21, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element22, 'class');
          morphs[1] = dom.createMorphAt(element22,1,1);
          morphs[2] = dom.createMorphAt(element21,3,3);
          morphs[3] = dom.createMorphAt(element21,4,4);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["title title--big ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[72,63],[72,73]]]],0],[],["loc",[null,[72,59],[72,76]]]],"is-negative",""],[],["loc",[null,[72,38],[72,95]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[72,128],[72,138]]]]],[],[]]],["loc",[null,[72,104],[72,140]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[73,16],[73,26]]]],0],[],["loc",[null,[73,12],[73,29]]]]],[],0,null,["loc",[null,[73,6],[75,13]]]],
          ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[76,16],[76,29]]]],0],[],["loc",[null,[76,12],[76,32]]]]],[],1,null,["loc",[null,[76,6],[78,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 88,
              "column": 14
            },
            "end": {
              "line": 99,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-accounting");
          var el3 = dom.createTextNode("Verrechnung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-warning");
          var el3 = dom.createTextNode("Mahnwesen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","js-tabs__label tabs__label title");
          dom.setAttribute(el2,"href","#tab-saldo");
          var el3 = dom.createTextNode("Saldo\n                        Informationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1, 1]);
          var element19 = dom.childAt(fragment, [3, 1]);
          var element20 = dom.childAt(fragment, [5, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element18, 'class');
          morphs[1] = dom.createElementMorph(element18);
          morphs[2] = dom.createAttrMorph(element19, 'class');
          morphs[3] = dom.createElementMorph(element19);
          morphs[4] = dom.createElementMorph(element20);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[90,88],[90,97]]]],"accounting"],[],["loc",[null,[90,84],[90,111]]]],"is-active",""],[],["loc",[null,[90,63],[90,128]]]]]]],
          ["element","action",["selectTab","accounting"],[],["loc",[null,[90,153],[90,188]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[93,88],[93,97]]]],"warning"],[],["loc",[null,[93,84],[93,108]]]],"is-active",""],[],["loc",[null,[93,63],[93,125]]]]]]],
          ["element","action",["selectTab","warning"],[],["loc",[null,[93,147],[93,179]]]],
          ["element","action",["selectTab","saldo"],[],["loc",[null,[96,82],[96,112]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 109,
              "column": 0
            },
            "end": {
              "line": 113,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-appointments");
          var el3 = dom.createTextNode("Termine");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element17, 'class');
          morphs[1] = dom.createElementMorph(element17);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[111,84],[111,93]]]],"appointments"],[],["loc",[null,[111,80],[111,109]]]],"is-active",""],[],["loc",[null,[111,59],[111,126]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[111,153],[111,190]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 282,
                "column": 28
              },
              "end": {
                "line": 294,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field-label");
            var el2 = dom.createTextNode("Produktkategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field select");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","arrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","view",[["get","Ember.Select",["loc",[null,[285,41],[285,53]]]]],["content",["subexpr","@mut",[["get","productCategories",["loc",[null,[286,42],[286,59]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","reduction.productCategory",["loc",[null,[289,44],[289,69]]]]],[],[]],"prompt","Alle"],["loc",[null,[285,34],[291,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 295,
                "column": 28
              },
              "end": {
                "line": 307,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field-label");
            var el2 = dom.createTextNode("Leistungskategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field select");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","arrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","view",[["get","Ember.Select",["loc",[null,[298,41],[298,53]]]]],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[299,42],[299,59]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","reduction.serviceCategory",["loc",[null,[302,44],[302,69]]]]],[],[]],"prompt","Alle"],["loc",[null,[298,34],[304,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 318,
                "column": 28
              },
              "end": {
                "line": 322,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element14);
            morphs[1] = dom.createMorphAt(element14,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["addReduction",["get","reduction",["loc",[null,[319,55],[319,64]]]]],[],["loc",[null,[319,31],[319,66]]]],
            ["inline","button-add-list",[],["size","32","color","#fff","showStroke",false],["loc",[null,[320,30],[320,89]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 323,
                "column": 28
              },
              "end": {
                "line": 327,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element13);
            morphs[1] = dom.createMorphAt(element13,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["removeReduction",["get","reduction",["loc",[null,[324,62],[324,71]]]]],[],["loc",[null,[324,35],[324,73]]]],
            ["inline","button-delete-list",[],["size","32","color","#fff","showStroke",false],["loc",[null,[325,34],[325,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 249,
              "column": 18
            },
            "end": {
              "line": 330,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Patient(en)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-title");
          dom.setAttribute(el3,"class","field select");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","arrow");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Typ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-title");
          dom.setAttribute(el3,"class","field select");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","arrow");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-5/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-4/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-phone");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Rabatt in %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-2/24");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","line-action-buttons");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [5, 1]);
          var element16 = dom.childAt(fragment, [9, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 3]),1,1);
          morphs[2] = dom.createMorphAt(element15,1,1);
          morphs[3] = dom.createMorphAt(element15,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),3,3);
          morphs[5] = dom.createMorphAt(element16,1,1);
          morphs[6] = dom.createMorphAt(element16,2,2);
          return morphs;
        },
        statements: [
          ["inline","view",[["get","Ember.Select",["loc",[null,[254,39],[254,51]]]]],["content",["subexpr","@mut",[["get","model.patients",["loc",[null,[255,40],[255,54]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Alle","selection",["subexpr","@mut",[["get","reduction.patient",["loc",[null,[259,42],[259,59]]]]],[],[]]],["loc",[null,[254,32],[260,34]]]],
          ["inline","view",[["get","Ember.Select",["loc",[null,[269,39],[269,51]]]]],["content",["subexpr","@mut",[["get","customerReductionTypes",["loc",[null,[270,40],[270,62]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Alle","selection",["subexpr","@mut",[["get","reduction.type",["loc",[null,[274,42],[274,56]]]]],[],[]]],["loc",[null,[269,32],[275,34]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[282,38],[282,55]]]],"product"],[],["loc",[null,[282,34],[282,66]]]]],[],0,null,["loc",[null,[282,28],[294,35]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[295,38],[295,55]]]],"service"],[],["loc",[null,[295,34],[295,66]]]]],[],1,null,["loc",[null,[295,28],[307,35]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","reduction.reduction",["loc",[null,[313,42],[313,61]]]]],[],[]],"class","gui-input"],["loc",[null,[313,28],[313,81]]]],
          ["block","if",[["subexpr","eq",[["get","reduction",["loc",[null,[318,38],[318,47]]]],["get","model.reductions.lastObject",["loc",[null,[318,48],[318,75]]]]],[],["loc",[null,[318,34],[318,76]]]]],[],2,null,["loc",[null,[318,28],[322,27]]]],
          ["block","if",[["subexpr","not",[["subexpr","eq",[["get","reduction",["loc",[null,[323,43],[323,52]]]],["get","model.reductions.firstObject",["loc",[null,[323,53],[323,81]]]]],[],["loc",[null,[323,39],[323,82]]]]],[],["loc",[null,[323,34],[323,83]]]]],[],3,null,["loc",[null,[323,28],[327,35]]]]
        ],
        locals: ["reduction"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 421,
                "column": 26
              },
              "end": {
                "line": 423,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[422,28],[422,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 434,
                      "column": 40
                    },
                    "end": {
                      "line": 434,
                      "column": 200
                    }
                  },
                  "moduleName": "jason-frontend/templates/customers/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","button__icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[434,110],[434,131]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[434,142],[434,161]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[434,170],[434,194]]]]],[],[]]],["loc",[null,[434,88],[434,196]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 431,
                    "column": 34
                  },
                  "end": {
                    "line": 438,
                    "column": 34
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","patient-selector selected");
                var el2 = dom.createTextNode("\n                                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element7, 'class');
                morphs[1] = dom.createMorphAt(element7,1,1);
                morphs[2] = dom.createMorphAt(element7,3,3);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["button ",["subexpr","value-bool-evaluator",[["get","patient.category",["loc",[null,[433,80],[433,96]]]],"button--has-icon",""],[],["loc",[null,[433,57],[433,120]]]]," button--toggle no-hover  large-confirm"]]],
                ["block","if",[["get","patient.category",["loc",[null,[434,46],[434,62]]]]],[],0,null,["loc",[null,[434,40],[434,207]]]],
                ["content","patient.name",["loc",[null,[435,40],[435,56]]]]
              ],
              locals: ["patient"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 424,
                  "column": 28
                },
                "end": {
                  "line": 452,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-3/24@desk");
              dom.setAttribute(el2,"data-label","Datum");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24 l-13/24@desk");
              dom.setAttribute(el2,"data-label","Leistungsempfänger");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--right@desk table__cell--name l-12/24 l-24/24@tablet l-4/24@desk");
              dom.setAttribute(el2,"data-label","Summe brutto");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-3");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button icon-button--success list-action-square");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("Auswählen");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button icon-button--delete list-action-square");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var element10 = dom.childAt(element8, [7]);
              var element11 = dom.childAt(element10, [1]);
              var element12 = dom.childAt(element10, [3]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element8, [5, 1]),0,0);
              morphs[4] = dom.createElementMorph(element11);
              morphs[5] = dom.createMorphAt(element11,1,1);
              morphs[6] = dom.createMorphAt(element12,1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element12, [2]),0,0);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","invoice.created",["loc",[null,[427,54],[427,69]]]],"DD. MM. YYYY"],[],["loc",[null,[427,40],[427,86]]]],
              ["inline","time-format",[["get","invoice.created",["loc",[null,[428,66],[428,81]]]],"HH:mm"],[],["loc",[null,[428,52],[428,91]]]],
              ["block","each",[["get","invoice.selectedPatients",["loc",[null,[431,42],[431,66]]]]],[],0,null,["loc",[null,[431,34],[438,43]]]],
              ["inline","format-currency",[],["value",["subexpr","@mut",[["get","invoice.total",["loc",[null,[441,64],[441,77]]]]],[],[]]],["loc",[null,[441,40],[441,79]]]],
              ["element","action",["selectInvoice",["get","invoice.id",["loc",[null,[444,62],[444,72]]]]],[],["loc",[null,[444,37],[444,74]]]],
              ["inline","button-select",[],["color","white","showStroke",false],["loc",[null,[445,36],[445,84]]]],
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"item",["subexpr","@mut",[["get","invoice",["loc",[null,[448,101],[448,108]]]]],[],[]],"confirmed","closeInvoice","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[448,36],[448,194]]]],
              ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","invoice",["loc",[null,[448,225],[448,232]]]]],[],[]],"confirmed","closeInvoice","textToShow","Löschen","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[448,200],[448,339]]]]
            ],
            locals: ["invoice"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 423,
                "column": 26
              },
              "end": {
                "line": 453,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","invoices",["loc",[null,[424,36],[424,44]]]]],[],0,null,["loc",[null,[424,28],[452,37]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 411,
              "column": 20
            },
            "end": {
              "line": 457,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table box no-box@phone grid table--large");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Datum");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-13/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Leistungsempfänger");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell table__cell--right l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Summe brutto");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showLoading",["loc",[null,[421,32],[421,43]]]]],[],0,1,["loc",[null,[421,26],[453,33]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 457,
              "column": 18
            },
            "end": {
              "line": 459,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Keine Entwürfe gefunden");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 473,
                "column": 20
              },
              "end": {
                "line": 473,
                "column": 240
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createAttrMorph(element6, 'style');
            morphs[2] = dom.createAttrMorph(element6, 'data-id');
            morphs[3] = dom.createElementMorph(element6);
            morphs[4] = dom.createMorphAt(element6,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[473,96],[473,108]]]],"selected",""],[],["loc",[null,[473,75],[473,124]]]]]]],
            ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[473,153],[473,162]]]]]]],
            ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[473,177],[473,183]]]]]]],
            ["element","action",["selectSearchTag",["get","tag",["loc",[null,[473,214],[473,217]]]]],[],["loc",[null,[473,187],[473,219]]]],
            ["content","tag.name",["loc",[null,[473,220],[473,232]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 470,
              "column": 16
            },
            "end": {
              "line": 475,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter ml20 mb20");
          dom.setAttribute(el1,"id","tagFilters");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Tags");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","tags",["loc",[null,[473,28],[473,32]]]]],[],0,null,["loc",[null,[473,20],[473,249]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 509,
                  "column": 34
                },
                "end": {
                  "line": 509,
                  "column": 219
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[509,77],[509,89]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[509,125],[509,144]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[509,153],[509,177]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[509,196],[509,217]]]]],[],[]]],["loc",[null,[509,58],[509,219]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 514,
                    "column": 49
                  },
                  "end": {
                    "line": 516,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[515,32],[515,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 516,
                    "column": 40
                  },
                  "end": {
                    "line": 516,
                    "column": 158
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[516,60],[516,158]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 513,
                  "column": 30
                },
                "end": {
                  "line": 517,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["content","patient.name",["loc",[null,[514,32],[514,48]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[514,55],[514,74]]]]],[],0,null,["loc",[null,[514,49],[516,39]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[516,46],[516,58]]]]],[],1,null,["loc",[null,[516,40],[516,165]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 518,
                    "column": 32
                  },
                  "end": {
                    "line": 518,
                    "column": 87
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","patient.name",["loc",[null,[518,71],[518,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 518,
                    "column": 101
                  },
                  "end": {
                    "line": 520,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                              ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[519,32],[519,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 520,
                    "column": 38
                  },
                  "end": {
                    "line": 520,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[520,58],[520,156]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 517,
                  "column": 30
                },
                "end": {
                  "line": 521,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[518,59],[518,69]]]]],[],0,null,["loc",[null,[518,32],[518,99]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[518,107],[518,126]]]]],[],1,null,["loc",[null,[518,101],[520,37]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[520,44],[520,56]]]]],[],2,null,["loc",[null,[520,38],[520,163]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 528,
                  "column": 28
                },
                "end": {
                  "line": 528,
                  "column": 83
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.digitalRace.name",["loc",[null,[528,55],[528,83]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 528,
                  "column": 83
                },
                "end": {
                  "line": 528,
                  "column": 107
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.race",["loc",[null,[528,91],[528,107]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 534,
                  "column": 28
                },
                "end": {
                  "line": 538,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("wiederherstellen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(element4,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["undeletePatient",["get","patient.id",["loc",[null,[535,124],[535,134]]]]],[],["loc",[null,[535,97],[535,136]]]],
              ["inline","button-checked",[],["content","Gelöschten Datensatz wiederherstellen","color","#fff","showStroke",false],["loc",[null,[536,32],[536,128]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 544,
                    "column": 30
                  },
                  "end": {
                    "line": 546,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                var el2 = dom.createTextNode("Bearbeiten");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[545,32],[545,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 538,
                  "column": 28
                },
                "end": {
                  "line": 550,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("QR-Code herunterladen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(fragment, [5]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element2,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
              morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[3] = dom.createElementMorph(element3);
              morphs[4] = dom.createMorphAt(element3,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm-id",[],["color","#fff","showStroke",false,"itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[540,101],[540,111]]]]],[],[]],"confirmed","deletePatient","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[540,32],[541,113]]]],
              ["inline","text-with-confirm-id",[],["itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[542,68],[542,78]]]]],[],[]],"confirmed","deletePatient","textToShow","Löschen","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[542,38],[542,175]]]],
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[544,57],[544,67]]]]],["classNames","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[544,30],[546,42]]]],
              ["element","action",["selectBarcode",["get","patient",["loc",[null,[547,58],[547,65]]]]],[],["loc",[null,[547,33],[547,67]]]],
              ["inline","button-barcode",[],["color","#fff","showStroke",false,"classNames","mr10"],["loc",[null,[548,32],[548,99]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 503,
                "column": 26
              },
              "end": {
                "line": 553,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Geschlecht");
            var el3 = dom.createTextNode("\n                              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Art");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-12/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","patient-view");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Spezies");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Rasse");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Farbe");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  table__cell--action l-4/24@desk buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 1]),1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [9]),1,1);
            morphs[5] = dom.createMorphAt(dom.childAt(element5, [11]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element5, [13]),1,1);
            return morphs;
          },
          statements: [
            ["inline","icon-gender",[],["gender",["subexpr","@mut",[["get","patient.gender.id",["loc",[null,[506,57],[506,74]]]]],[],[]],"castrated",["subexpr","@mut",[["get","patient.castrated",["loc",[null,[506,85],[506,102]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.genderDetails",["loc",[null,[506,111],[506,132]]]]],[],[]],"size","32","color","#4A4A4A"],["loc",[null,[506,36],[506,160]]]],
            ["block","if",[["get","patient.category",["loc",[null,[509,40],[509,56]]]]],[],0,null,["loc",[null,[509,34],[509,226]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[513,36],[513,51]]]]],[],1,2,["loc",[null,[513,30],[521,37]]]],
            ["content","patient.category.name",["loc",[null,[525,28],[525,53]]]],
            ["block","if",[["get","patient.digitalRace",["loc",[null,[528,34],[528,53]]]]],[],3,4,["loc",[null,[528,28],[528,114]]]],
            ["content","patient.color",["loc",[null,[531,28],[531,45]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[534,34],[534,49]]]]],[],5,6,["loc",[null,[534,28],[550,35]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 502,
              "column": 24
            },
            "end": {
              "line": 554,
              "column": 24
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["subexpr","not",[["get","patient.dead",["loc",[null,[503,41],[503,53]]]]],[],["loc",[null,[503,36],[503,54]]]],["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","quickFilter",["loc",[null,[503,68],[503,79]]]],"all"],[],["loc",[null,[503,64],[503,86]]]],["subexpr","eq",[["get","quickFilter",["loc",[null,[503,91],[503,102]]]],"deleted"],[],["loc",[null,[503,87],[503,113]]]]],[],["loc",[null,[503,60],[503,114]]]],["get","patient.dead",["loc",[null,[503,115],[503,127]]]]],[],["loc",[null,[503,55],[503,128]]]]],[],["loc",[null,[503,32],[503,129]]]]],[],0,null,["loc",[null,[503,26],[553,33]]]]
        ],
        locals: ["patient"],
        templates: [child0]
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 582,
              "column": 18
            },
            "end": {
              "line": 593,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","patient-consent");
          dom.setAttribute(el2,"class","field-label title required");
          var el3 = dom.createTextNode("Status aus vorherigem Praxismanagementsystem");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","checkbox-custom checkbox-primary field-checkbox");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient-consent");
          var el4 = dom.createTextNode("DSGVO akzeptiert");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["disabled",true,"type","checkbox","name","patient.consent","id","patient-consent","checked",true],["loc",[null,[587,24],[587,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 605,
              "column": 6
            },
            "end": {
              "line": 609,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[606,11],[606,38]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[607,10],[607,105]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 610,
              "column": 6
            },
            "end": {
              "line": 614,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--cancel");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","customer",["loc",[null,[612,44],[612,52]]]]],[],[]],"confirmed","delete","size",52,"showStroke",false,"color","#fff","text","Wollen Sie diese/n Kunde/in wirklich löschen?"],["loc",[null,[612,10],[612,164]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 615,
              "column": 6
            },
            "end": {
              "line": 619,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[616,13],[616,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","70","content","Speichern"],["loc",[null,[617,12],[617,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 642,
              "column": 0
            },
            "end": {
              "line": 644,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-forward-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardDocumentPanel",["loc",[null,[643,42],[643,62]]]]],[],[]],"confirm","sendDocumentMail"],["loc",[null,[643,2],[643,91]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 688,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/customers/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2,"class","mb10");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-master-data");
        var el7 = dom.createTextNode("Stammdaten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-reminder");
        var el7 = dom.createTextNode("Erinnerungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-documents");
        var el7 = dom.createTextNode("Dokumente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-patients");
        var el7 = dom.createTextNode("Tiere");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-correspondence");
        var el7 = dom.createTextNode("Korrespondenz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-consents");
        var el7 = dom.createTextNode("Einverständniserklärungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","customerEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-accounting");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0 mb20");
        var el7 = dom.createTextNode("Zahlungsinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-payment-moral");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Zahlungsmoral");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-payment-moral");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-sued");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Gesperrt weil Nichtzahler");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-sued");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rechnungsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-addition");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Briefzusatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-firstname");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Vorname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-street");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Straße/Hausnummer");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-postalcode");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("PLZ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-uid");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("UID");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-different-invoice");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Alternative\n                          Rechnungsadresse?");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-different-invoice");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-lastname");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Nachname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-title");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Titel");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-title");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-town");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rechnungskonditionen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-conditions");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Konditionen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-reductionToPurchasePrice");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Rabatt zum Einkaufspreis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-reductionToPurchasePrice");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Bankverbindung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-iban");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("IBAN");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-bic");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("BIC");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rabattinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Anfahrtsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveStreet");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Straße");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-drivePostalcode");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("PLZ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-distanceKm");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Entfernung in km");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveNotice");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveTown");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-distanceMinutes");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Entfernung in Minuten");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-warning");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20 mt0");
        var el7 = dom.createTextNode("Kundenspezifische Mahnzeiten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning1");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis 1. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning2");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis 2. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-nopayer");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis Kunde als Nichtzahler markiert wird (nach dem Verschicken der 2. Mahnung).  (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","inline-icon");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","is-highlighted");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Tipp:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Um das Mahnwesen für diesen Kunden zu deaktivieren muss in den entsprechenden Feldern ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("0");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" eingetragen werden.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-reminder");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","patient-category");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Übermittlungseinstellung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","app-internal-person2");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    Standardeinstellung: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-saldo");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0");
        var el7 = dom.createTextNode("Ausgangsrechnungen (Entwürfe)");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0");
        var el7 = dom.createTextNode("Saldoübersicht");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper mt20");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-medias");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-documents");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-patients");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","quick-filter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Verstorbene ausblenden");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table grid table--large");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-2/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Name");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Spezies");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Rasse");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Farbe");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-appointments");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-6/24@desk mb30");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","filterUser");
        dom.setAttribute(el7,"class","input-element");
        dom.setAttribute(el7,"style","width: 100%");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Kategorie");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-correspondence");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-consents");
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {\n            $(this).val(picker.startDate.format('DD. MM. YYYY'));\n            $(this).focus();\n        });\n        $('.datetimepicker').daterangepicker({\n            singleDatePicker: true,\n            autoUpdateInput: false,\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element36 = dom.childAt(fragment, [0]);
        var element37 = dom.childAt(element36, [3]);
        var element38 = dom.childAt(fragment, [2]);
        var element39 = dom.childAt(element38, [1]);
        var element40 = dom.childAt(element38, [5]);
        var element41 = dom.childAt(element40, [1, 1]);
        var element42 = dom.childAt(element41, [1, 1]);
        var element43 = dom.childAt(element41, [5, 1]);
        var element44 = dom.childAt(element41, [7, 1]);
        var element45 = dom.childAt(element41, [9, 1]);
        var element46 = dom.childAt(element41, [13, 1]);
        var element47 = dom.childAt(element41, [15, 1]);
        var element48 = dom.childAt(element40, [3, 1]);
        var element49 = dom.childAt(element48, [1]);
        var element50 = dom.childAt(element48, [3]);
        var element51 = dom.childAt(element50, [9]);
        var element52 = dom.childAt(element51, [3]);
        var element53 = dom.childAt(element51, [5]);
        var element54 = dom.childAt(element51, [7]);
        var element55 = dom.childAt(element51, [9]);
        var element56 = dom.childAt(element50, [11]);
        var element57 = dom.childAt(element56, [3]);
        var element58 = dom.childAt(element56, [5]);
        var element59 = dom.childAt(element56, [7]);
        var element60 = dom.childAt(element50, [31]);
        var element61 = dom.childAt(element50, [33]);
        var element62 = dom.childAt(element48, [5]);
        var element63 = dom.childAt(element62, [3, 1]);
        var element64 = dom.childAt(element62, [5, 1]);
        var element65 = dom.childAt(element62, [7, 1]);
        var element66 = dom.childAt(element48, [7]);
        var element67 = dom.childAt(element66, [1]);
        var element68 = dom.childAt(element48, [9]);
        var element69 = dom.childAt(element48, [11]);
        var element70 = dom.childAt(element48, [13]);
        var element71 = dom.childAt(element48, [15, 1]);
        var element72 = dom.childAt(element71, [1]);
        var element73 = dom.childAt(element72, [3]);
        var element74 = dom.childAt(element72, [5]);
        var element75 = dom.childAt(element72, [7]);
        var element76 = dom.childAt(element48, [17]);
        var element77 = dom.childAt(element48, [21]);
        var element78 = dom.childAt(element38, [7]);
        var morphs = new Array(104);
        morphs[0] = dom.createMorphAt(dom.childAt(element36, [1]),1,1);
        morphs[1] = dom.createAttrMorph(element37, 'class');
        morphs[2] = dom.createMorphAt(element37,1,1);
        morphs[3] = dom.createMorphAt(element37,2,2);
        morphs[4] = dom.createMorphAt(element37,3,3);
        morphs[5] = dom.createMorphAt(element37,4,4);
        morphs[6] = dom.createMorphAt(element37,5,5);
        morphs[7] = dom.createMorphAt(element37,6,6);
        morphs[8] = dom.createAttrMorph(element38, 'class');
        morphs[9] = dom.createMorphAt(element39,0,0);
        morphs[10] = dom.createMorphAt(element39,2,2);
        morphs[11] = dom.createMorphAt(element39,4,4);
        morphs[12] = dom.createMorphAt(element38,3,3);
        morphs[13] = dom.createAttrMorph(element42, 'class');
        morphs[14] = dom.createElementMorph(element42);
        morphs[15] = dom.createMorphAt(element41,3,3);
        morphs[16] = dom.createAttrMorph(element43, 'class');
        morphs[17] = dom.createElementMorph(element43);
        morphs[18] = dom.createAttrMorph(element44, 'class');
        morphs[19] = dom.createElementMorph(element44);
        morphs[20] = dom.createAttrMorph(element45, 'class');
        morphs[21] = dom.createElementMorph(element45);
        morphs[22] = dom.createMorphAt(element41,11,11);
        morphs[23] = dom.createElementMorph(element46);
        morphs[24] = dom.createElementMorph(element47);
        morphs[25] = dom.createElementMorph(element48);
        morphs[26] = dom.createAttrMorph(element49, 'class');
        morphs[27] = dom.createMorphAt(element49,1,1);
        morphs[28] = dom.createAttrMorph(element50, 'class');
        morphs[29] = dom.createMorphAt(dom.childAt(element50, [3, 1, 3]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element50, [5, 1, 3]),1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element51, [1]),3,3);
        morphs[32] = dom.createAttrMorph(element52, 'class');
        morphs[33] = dom.createMorphAt(element52,3,3);
        morphs[34] = dom.createAttrMorph(element53, 'class');
        morphs[35] = dom.createMorphAt(element53,3,3);
        morphs[36] = dom.createAttrMorph(element54, 'class');
        morphs[37] = dom.createMorphAt(element54,3,3);
        morphs[38] = dom.createAttrMorph(element55, 'class');
        morphs[39] = dom.createMorphAt(element55,3,3);
        morphs[40] = dom.createMorphAt(dom.childAt(element56, [1, 3]),1,1);
        morphs[41] = dom.createAttrMorph(element57, 'class');
        morphs[42] = dom.createMorphAt(element57,3,3);
        morphs[43] = dom.createAttrMorph(element58, 'class');
        morphs[44] = dom.createMorphAt(dom.childAt(element58, [3]),1,1);
        morphs[45] = dom.createAttrMorph(element59, 'class');
        morphs[46] = dom.createMorphAt(element59,3,3);
        morphs[47] = dom.createMorphAt(dom.childAt(element50, [15, 1]),3,3);
        morphs[48] = dom.createMorphAt(dom.childAt(element50, [17, 1, 3]),1,1);
        morphs[49] = dom.createMorphAt(dom.childAt(element50, [21, 1]),3,3);
        morphs[50] = dom.createMorphAt(dom.childAt(element50, [23, 1]),3,3);
        morphs[51] = dom.createMorphAt(element50,27,27);
        morphs[52] = dom.createMorphAt(dom.childAt(element60, [1]),3,3);
        morphs[53] = dom.createMorphAt(dom.childAt(element60, [3]),3,3);
        morphs[54] = dom.createMorphAt(dom.childAt(element60, [5]),3,3);
        morphs[55] = dom.createMorphAt(dom.childAt(element61, [1]),3,3);
        morphs[56] = dom.createMorphAt(dom.childAt(element61, [3]),3,3);
        morphs[57] = dom.createMorphAt(dom.childAt(element61, [5]),3,3);
        morphs[58] = dom.createAttrMorph(element62, 'class');
        morphs[59] = dom.createMorphAt(element63,3,3);
        morphs[60] = dom.createMorphAt(dom.childAt(element63, [5, 1]),0,0);
        morphs[61] = dom.createMorphAt(element64,3,3);
        morphs[62] = dom.createMorphAt(dom.childAt(element64, [5, 1]),0,0);
        morphs[63] = dom.createMorphAt(element65,3,3);
        morphs[64] = dom.createMorphAt(dom.childAt(element65, [5, 1]),0,0);
        morphs[65] = dom.createMorphAt(dom.childAt(element62, [9]),0,0);
        morphs[66] = dom.createAttrMorph(element66, 'class');
        morphs[67] = dom.createMorphAt(dom.childAt(element67, [1, 3]),1,1);
        morphs[68] = dom.createMorphAt(dom.childAt(element67, [3]),0,0);
        morphs[69] = dom.createMorphAt(element68,3,3);
        morphs[70] = dom.createMorphAt(dom.childAt(element68, [7]),1,1);
        morphs[71] = dom.createAttrMorph(element69, 'class');
        morphs[72] = dom.createMorphAt(element69,1,1);
        morphs[73] = dom.createAttrMorph(element70, 'class');
        morphs[74] = dom.createMorphAt(element70,1,1);
        morphs[75] = dom.createMorphAt(element70,3,3);
        morphs[76] = dom.createAttrMorph(element73, 'class');
        morphs[77] = dom.createElementMorph(element73);
        morphs[78] = dom.createAttrMorph(element74, 'class');
        morphs[79] = dom.createElementMorph(element74);
        morphs[80] = dom.createAttrMorph(element75, 'class');
        morphs[81] = dom.createElementMorph(element75);
        morphs[82] = dom.createMorphAt(dom.childAt(element71, [3, 3]),1,1);
        morphs[83] = dom.createAttrMorph(element76, 'class');
        morphs[84] = dom.createMorphAt(dom.childAt(element76, [1, 1]),3,3);
        morphs[85] = dom.createMorphAt(element76,3,3);
        morphs[86] = dom.createMorphAt(dom.childAt(element48, [19]),1,1);
        morphs[87] = dom.createMorphAt(element77,1,1);
        morphs[88] = dom.createMorphAt(element77,3,3);
        morphs[89] = dom.createMorphAt(dom.childAt(element78, [1]),1,1);
        morphs[90] = dom.createMorphAt(element78,3,3);
        morphs[91] = dom.createMorphAt(element78,4,4);
        morphs[92] = dom.createMorphAt(element78,5,5);
        morphs[93] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[94] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[95] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[96] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[97] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[98] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[99] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[100] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[101] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[102] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[103] = dom.createMorphAt(fragment,25,25,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[3,14],[3,23]]]],"documents"],[],["loc",[null,[3,10],[3,36]]]]],[],0,null,["loc",[null,[3,4],[6,11]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[8,53],[8,66]]]],"10","16"],[],["loc",[null,[8,32],[8,78]]]],"/24"]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[9,14],[9,23]]]],"masterdata"],[],["loc",[null,[9,10],[9,37]]]]],[],1,null,["loc",[null,[9,4],[26,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[27,14],[27,23]]]],"documents"],[],["loc",[null,[27,10],[27,36]]]]],[],2,null,["loc",[null,[27,4],[37,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[38,14],[38,23]]]],"patients"],[],["loc",[null,[38,10],[38,35]]]]],[],3,null,["loc",[null,[38,4],[42,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[43,14],[43,23]]]],"appointments"],[],["loc",[null,[43,10],[43,39]]]]],[],4,null,["loc",[null,[43,4],[47,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[48,14],[48,23]]]],"saldo"],[],["loc",[null,[48,10],[48,32]]]]],[],5,null,["loc",[null,[48,4],[59,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[60,14],[60,23]]]],"consents"],[],["loc",[null,[60,10],[60,35]]]]],[],6,null,["loc",[null,[60,4],[64,11]]]],
        ["attribute","class",["concat",["site-content status status-",["subexpr","get-payment-moral",[["get","model",["loc",[null,[68,63],[68,68]]]],true],[],["loc",[null,[68,43],[68,75]]]]," card transaction-filter with-context-bar "]]],
        ["content","model.firstname",["loc",[null,[69,21],[69,40]]]],
        ["content","model.lastname",["loc",[null,[69,41],[69,59]]]],
        ["block","if",[["get","model.etigaAppConnected",["loc",[null,[69,66],[69,89]]]]],[],7,8,["loc",[null,[69,60],[69,369]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[70,8],[70,21]]]]],[],9,null,["loc",[null,[70,2],[80,9]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[86,88],[86,97]]]],"masterdata"],[],["loc",[null,[86,84],[86,111]]]],"is-active",""],[],["loc",[null,[86,63],[86,128]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[86,154],[86,189]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[88,20],[88,33]]]]],[],10,null,["loc",[null,[88,14],[99,21]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[101,84],[101,93]]]],"reminder"],[],["loc",[null,[101,80],[101,105]]]],"is-active",""],[],["loc",[null,[101,59],[101,122]]]]]]],
        ["element","action",["selectTab","reminder"],[],["loc",[null,[101,145],[101,178]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[104,86],[104,95]]]],"documents"],[],["loc",[null,[104,82],[104,108]]]],"is-active",""],[],["loc",[null,[104,61],[104,125]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[104,149],[104,183]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[107,88],[107,97]]]],"patients"],[],["loc",[null,[107,84],[107,109]]]],"is-active",""],[],["loc",[null,[107,63],[107,126]]]]]]],
        ["element","action",["selectTab","patients"],[],["loc",[null,[107,149],[107,182]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[109,6],[109,29]]]]],[],11,null,["loc",[null,[109,0],[113,7]]]],
        ["element","action",["selectTab","correspondance"],[],["loc",[null,[115,91],[115,130]]]],
        ["element","action",["selectTab","consents"],[],["loc",[null,[118,85],[118,118]]]],
        ["element","action",["save",["get","referer",["loc",[null,[123,71],[123,78]]]],["get","refererInvoiceId",["loc",[null,[123,79],[123,95]]]]],["on","submit"],["loc",[null,[123,55],[123,109]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[124,66],[124,75]]]],"masterdata"],[],["loc",[null,[124,62],[124,89]]]],"is-active",""],[],["loc",[null,[124,41],[124,106]]]]]]],
        ["inline","customers-edit-form",[],["edit",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[125,56],[125,61]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[125,70],[125,77]]]]],[],[]],"customerSalutations",["subexpr","@mut",[["get","customerSalutations",["loc",[null,[125,98],[125,117]]]]],[],[]],"countries",["subexpr","@mut",[["get","countries",["loc",[null,[125,128],[125,137]]]]],[],[]],"titles",["subexpr","@mut",[["get","titles",["loc",[null,[125,145],[125,151]]]]],[],[]],"setInputDirty","setInputDirty","addEmail","addEmail","removeEmail","removeEmail","addPhone","addPhone","removePhone","removePhone","addFax","addFax","removeFax","removeFax"],["loc",[null,[125,18],[125,313]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[127,66],[127,75]]]],"accounting"],[],["loc",[null,[127,62],[127,89]]]],"is-active",""],[],["loc",[null,[127,41],[127,106]]]]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[133,37],[133,49]]]]],["content",["subexpr","@mut",[["get","paymentMorals",["loc",[null,[134,38],[134,51]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Bitte wählen","name","user-payment-moral","selection",["subexpr","@mut",[["get","selectedPaymentMoral",["loc",[null,[139,40],[139,60]]]]],[],[]]],["loc",[null,[133,30],[140,32]]]],
        ["inline","input",[],["type","checkbox","name","user.sued","id","user-sued","checked",["subexpr","@mut",[["get","model.sued",["loc",[null,[149,94],[149,104]]]]],[],[]]],["loc",[null,[149,30],[149,106]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceAddition",["loc",[null,[161,38],[161,59]]]]],[],[]],"class","gui-input","name","user-invoice-addition"],["loc",[null,[161,24],[161,108]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[163,63],[163,85]]]],"","hidden"],[],["loc",[null,[163,42],[163,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceFirstname",["loc",[null,[165,40],[165,62]]]]],[],[]],"class","gui-input","name","user-invoice-firstname"],["loc",[null,[165,26],[165,112]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[167,63],[167,85]]]],"","hidden"],[],["loc",[null,[167,42],[167,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceStreet",["loc",[null,[169,40],[169,59]]]]],[],[]],"class","gui-input","name","user-invoice-street"],["loc",[null,[169,26],[169,106]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[171,63],[171,85]]]],"","hidden"],[],["loc",[null,[171,42],[171,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoicePostalcode",["loc",[null,[173,40],[173,63]]]]],[],[]],"class","gui-input","name","user-invoice-postalcode"],["loc",[null,[173,26],[173,114]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[175,63],[175,85]]]],"","hidden"],[],["loc",[null,[175,42],[175,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceUid",["loc",[null,[177,40],[177,56]]]]],[],[]],"class","gui-input","name","user-invoice-uid"],["loc",[null,[177,26],[177,100]]]],
        ["inline","input",[],["type","checkbox","name","user-different-invoice","id","user-different-invoice","checked",["subexpr","@mut",[["get","model.differentInvoice",["loc",[null,[185,116],[185,138]]]]],[],[]]],["loc",[null,[185,26],[185,140]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[189,63],[189,85]]]],"","hidden"],[],["loc",[null,[189,42],[189,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceLastname",["loc",[null,[191,40],[191,61]]]]],[],[]],"class","gui-input","name","user-invoice-lastname"],["loc",[null,[191,26],[191,110]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[193,61],[193,83]]]],"","hidden"],[],["loc",[null,[193,40],[193,98]]]]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[196,33],[196,45]]]]],["content",["subexpr","@mut",[["get","titles",["loc",[null,[197,41],[197,47]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Bitte wählen","name","user-invoice-title","selection",["subexpr","@mut",[["get","model.invoiceTitle",["loc",[null,[202,43],[202,61]]]]],[],[]]],["loc",[null,[196,26],[203,28]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[207,63],[207,85]]]],"","hidden"],[],["loc",[null,[207,42],[207,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceTown",["loc",[null,[209,40],[209,57]]]]],[],[]],"class","gui-input","name","user-invoice-town"],["loc",[null,[209,26],[209,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceConditions",["loc",[null,[218,40],[218,63]]]]],[],[]],"class","gui-input","name","user-invoice-conditions"],["loc",[null,[218,26],[218,114]]]],
        ["inline","input",[],["type","checkbox","name","user.reductionToPurchasePrice","id","user-reductionToPurchasePrice","checked",["subexpr","@mut",[["get","model.reductionToPurchasePrice",["loc",[null,[226,130],[226,160]]]]],[],[]]],["loc",[null,[226,26],[226,162]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.iban",["loc",[null,[237,40],[237,50]]]]],[],[]],"class","gui-input","name","user-iban","id","user-iban"],["loc",[null,[237,26],[237,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.bic",["loc",[null,[243,40],[243,49]]]]],[],[]],"class","gui-input","name","user-bic","id","user-bic"],["loc",[null,[243,26],[243,99]]]],
        ["block","each",[["get","model.reductions",["loc",[null,[249,26],[249,42]]]]],[],12,null,["loc",[null,[249,18],[330,27]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveStreet",["loc",[null,[336,40],[336,57]]]]],[],[]],"class","gui-input","name","user-driveStreet","id","user-driveStreet"],["loc",[null,[336,26],[336,123]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.drivePostalcode",["loc",[null,[340,40],[340,61]]]]],[],[]],"class","gui-input","name","user-drive-postalcode","title","Bitte geben Sie eine gültige PLZ an"],["loc",[null,[340,26],[340,154]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.distanceKm",["loc",[null,[344,40],[344,56]]]]],[],[]],"class","gui-input","name","user-drive-distanceKm"],["loc",[null,[344,26],[344,105]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveNotice",["loc",[null,[350,40],[350,57]]]]],[],[]],"class","gui-input","name","user-driveNotice","id","user-driveNotice"],["loc",[null,[350,26],[350,123]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveTown",["loc",[null,[354,40],[354,55]]]]],[],[]],"class","gui-input","name","user-driveTown","id","user-driveTown"],["loc",[null,[354,26],[354,117]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.distanceMinutes",["loc",[null,[358,40],[358,61]]]]],[],[]],"class","gui-input","name","user-drive-distanceMinutes"],["loc",[null,[358,26],[358,115]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[362,66],[362,75]]]],"warning"],[],["loc",[null,[362,62],[362,86]]]],"is-active",""],[],["loc",[null,[362,41],[362,103]]]]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning1",["loc",[null,[368,70],[368,92]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-warning1"],["loc",[null,[368,22],[368,179]]]],
        ["content","model.defaultDaysTillWarning1",["loc",[null,[368,251],[368,284]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning2",["loc",[null,[374,70],[374,92]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-warning2"],["loc",[null,[374,22],[374,179]]]],
        ["content","model.defaultDaysTillWarning2",["loc",[null,[374,251],[374,284]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillNopayer",["loc",[null,[380,70],[380,91]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-nopayer"],["loc",[null,[380,22],[380,177]]]],
        ["content","model.defaultDaysTillNopayer",["loc",[null,[380,297],[380,329]]]],
        ["inline","icon-info",[],["color","#008EC6","size","24"],["loc",[null,[384,44],[384,83]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[387,66],[387,75]]]],"reminder"],[],["loc",[null,[387,62],[387,87]]]],"is-active",""],[],["loc",[null,[387,41],[387,104]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","deliveryOptions",["loc",[null,[393,34],[393,49]]]]],[],[]],"value",["subexpr","@mut",[["get","model.reminderSetting",["loc",[null,[394,32],[394,53]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","value","optionValuePath","id","allowClear","true","placeholder","Standardeinstellungen beibehalten","name","customer.reminderSetting"],["loc",[null,[392,24],[401,26]]]],
        ["content","model.defaultReminderSetting",["loc",[null,[405,44],[405,76]]]],
        ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[411,30],[411,43]]]],0],[],["loc",[null,[411,26],[411,46]]]]],[],13,14,["loc",[null,[411,20],[459,25]]]],
        ["inline","customer-saldo",[],["deposits",["subexpr","@mut",[["get","deposits",["loc",[null,[463,50],[463,58]]]]],[],[]],"balanceSum",["subexpr","@mut",[["get","balanceSum",["loc",[null,[463,70],[463,80]]]]],[],[]],"depositSum",["subexpr","@mut",[["get","depositSum",["loc",[null,[463,92],[463,102]]]]],[],[]],"banktransferEnabled",["subexpr","@mut",[["get","banktransferEnabled",["loc",[null,[463,123],[463,142]]]]],[],[]]],["loc",[null,[463,24],[463,144]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[466,64],[466,73]]]],"medias"],[],["loc",[null,[466,60],[466,83]]]],"is-active",""],[],["loc",[null,[466,39],[466,100]]]]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[467,41],[467,47]]]]],[],[]],"deleteMedia","deleteMedia","editable",true,"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[467,18],[467,158]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[469,64],[469,73]]]],"documents"],[],["loc",[null,[469,60],[469,86]]]],"is-active",""],[],["loc",[null,[469,39],[469,103]]]]]]],
        ["block","if",[["get","showFilters",["loc",[null,[470,22],[470,33]]]]],[],15,null,["loc",[null,[470,16],[475,23]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[481,33],[481,47]]]]],[],[]]],["loc",[null,[476,16],[481,49]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[487,77],[487,88]]]],"all"],[],["loc",[null,[487,73],[487,95]]]],"selected",""],[],["loc",[null,[487,52],[487,112]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[487,114],[487,150]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[488,77],[488,88]]]],"hideDead"],[],["loc",[null,[488,73],[488,100]]]],"selected",""],[],["loc",[null,[488,52],[488,117]]]]]]],
        ["element","action",["toggleQuickFilter","hideDead"],[],["loc",[null,[488,119],[488,160]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[489,77],[489,88]]]],"deleted"],[],["loc",[null,[489,73],[489,99]]]],"selected",""],[],["loc",[null,[489,52],[489,116]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[489,118],[489,158]]]],
        ["block","each",[["get","patientList",["loc",[null,[502,32],[502,43]]]]],[],16,null,["loc",[null,[502,24],[554,33]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[559,64],[559,73]]]],"appointments"],[],["loc",[null,[559,60],[559,89]]]],"is-active",""],[],["loc",[null,[559,39],[559,106]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[564,30],[564,58]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[565,28],[565,42]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle"],["loc",[null,[563,20],[571,22]]]],
        ["inline","patient-appointments",[],["startTreatment","startTreatment","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[575,203],[575,215]]]]],[],[]]],["loc",[null,[575,16],[575,217]]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[578,50],[578,64]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[578,73],[578,80]]]]],[],[]]],["loc",[null,[578,18],[578,82]]]],
        ["block","if",[["get","model.consent",["loc",[null,[582,24],[582,37]]]]],[],17,null,["loc",[null,[582,18],[593,25]]]],
        ["inline","customer-consents",[],["actionReceiver",["subexpr","@mut",[["get","customerConsentsComponent",["loc",[null,[595,53],[595,78]]]]],[],[]]],["loc",[null,[595,18],[595,80]]]],
        ["content","button-prev",["loc",[null,[602,10],[602,25]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[605,16],[605,25]]]],"medias"],[],["loc",[null,[605,12],[605,35]]]]],[],18,null,["loc",[null,[605,6],[609,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[610,16],[610,25]]]],"masterdata"],[],["loc",[null,[610,12],[610,39]]]]],[],19,null,["loc",[null,[610,6],[614,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[615,20],[615,29]]]],"masterdata"],[],["loc",[null,[615,16],[615,43]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[615,48],[615,57]]]],"warning"],[],["loc",[null,[615,44],[615,68]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[615,73],[615,82]]]],"accounting"],[],["loc",[null,[615,69],[615,96]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[615,101],[615,110]]]],"reminder"],[],["loc",[null,[615,97],[615,122]]]]],[],["loc",[null,[615,12],[615,123]]]]],[],20,null,["loc",[null,[615,6],[619,13]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[623,37],[623,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[623,0],[623,76]]]],
        ["inline","customer-payout-deposit-panel",[],["actionReceiver",["subexpr","@mut",[["get","depositPanel",["loc",[null,[626,17],[626,29]]]]],[],[]],"totalDeposit",["subexpr","@mut",[["get","depositSum",["loc",[null,[627,15],[627,25]]]]],[],[]],"payout","payout","actionReceiver",["subexpr","@mut",[["get","customerPayoutDepositPanel",["loc",[null,[629,17],[629,43]]]]],[],[]]],["loc",[null,[625,0],[629,45]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[633,73],[633,98]]]]],[],[]]],["loc",[null,[633,0],[633,100]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[634,40],[634,60]]]]],[],[]]],["loc",[null,[634,0],[634,62]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[635,40],[635,60]]]]],[],[]]],["loc",[null,[635,0],[635,62]]]],
        ["inline","consent-upload-panel",[],["uploadDone","consentUploadDone","actionReceiver",["subexpr","@mut",[["get","consentUploadPanel",["loc",[null,[636,69],[636,87]]]]],[],[]]],["loc",[null,[636,0],[636,89]]]],
        ["inline","add-appointment-panel",[],["doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[638,32],[638,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[638,51],[638,61]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[638,72],[638,81]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[638,88],[638,93]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[638,103],[638,111]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[638,118],[638,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[638,166],[638,185]]]]],[],[]]],["loc",[null,[638,0],[638,187]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[640,36],[640,46]]]]],[],[]],"customer",["subexpr","@mut",[["get","model",["loc",[null,[640,56],[640,61]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[640,78],[640,93]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[640,0],[640,123]]]],
        ["block","if",[["subexpr","not",[["get","newMode",["loc",[null,[642,11],[642,18]]]]],[],["loc",[null,[642,6],[642,19]]]]],[],21,null,["loc",[null,[642,0],[644,7]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[646,41],[646,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[646,74],[646,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[646,134],[646,152]]]]],[],[]]],["loc",[null,[646,0],[646,154]]]],
        ["inline","merge-customer-panel",[],["gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","mergeCustomerPanel",["loc",[null,[647,66],[647,84]]]]],[],[]]],["loc",[null,[647,0],[647,86]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21]
    };
  }()));

});