define('jason-frontend/models/stable', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    ownerId: DS['default'].attr(''),
    owner: DS['default'].belongsTo('customer'),
    patients: DS['default'].attr(''),
    street: DS['default'].attr('string'),
    postalCode: DS['default'].attr('string'),
    town: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    phone: DS['default'].attr('string')
  });

});