define('jason-frontend/routes/invoices/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterCustomer = controller.get('filterCustomer'),
          paymentStatus = controller.get('filterPaymentStatus'),
          filterStatus = controller.get('filterStatus'),
          filterCode = controller.get('filterCode'),
          sortBy = controller.get('sortBy'),
          sortDir = controller.get('sortDir'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          page = controller.get('page');

      if (filterCustomer) {
        filterCustomer = filterCustomer.id;
      }

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      if (!paymentStatus) {
        paymentStatus = 'partpaid,notpaid,paid';
      }

      if (!filterStatus) {
        filterStatus = 'closed,to_be_verified,warning1,warning2,sepa,ready_for_money_collection,lawyer,no_warning,money_collection,canceled,closed,written_off';
      }

      controller.set('filterStatusFlat', filterStatus);
      controller.set('filterPaymentStatusFlat', paymentStatus);
      controller.set('filterCustomerIdFlat', filterCustomer);

      controller.set('showLoading', true);

      this.findPaged('invoice', {
        filterPaymentStatus: paymentStatus,
        filterStatus: filterStatus,
        sortDir: sortDir,
        filterCode: filterCode,
        filterCustomer: filterCustomer,
        filterFrom: from,
        filterTo: to,
        sortBy: sortBy,
        perPage: 10,
        page: page
      }).then(function (entries) {
        controller.set('model', entries);
        controller.set('showLoading', false);
      });
    },
    setupController: function setupController(controller, model, params) {
      controller.set('model', model);
      var queryCustomerId = params.queryParams.customerId;

      if (queryCustomerId > 0) {
        this.store.find('customer', queryCustomerId).then(function (customer) {
          controller.set('filterCustomer', customer);
        });
      } else {
        controller.set('filterCustomer', null);
      }

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      if (params.queryParams.resetParams) {
        controller.set('page', 1);
        controller.set('filterCustomer', null);
        controller.set('filterCode', '');
        controller.set('filterOrderStatus', null);
        controller.set('pickDate', moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"));
      }

      this.applyFilter();
    },
    actions: {
      checkForm: function checkForm(form) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#' + form + ' input[name=token]').val(data.responseText);
          $('#' + form).submit();
        });
      },
      downloadInvoice: function downloadInvoice(invoice) {
        var deferred = Ember['default'].$.ajax({
          url: "/api/invoices/" + invoice.id + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        }, function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      downloadWarningInvoice: function downloadWarningInvoice(invoice, number) {
        window.open('/api/customers/downloadWarning/' + invoice.id + "/" + number);
      },
      downloadWarningText: function downloadWarningText(invoice, number) {
        window.open('/api/customers/downloadWarning/' + invoice.id + "/" + number);
      },
      sortable: function sortable(attr) {
        if (this.get('controller').get('sortBy') === attr) {
          if (this.get('controller').get('sortDir') === 'asc') {
            this.get('controller').set('sortDir', 'desc');
          } else {
            this.get('controller').set('sortDir', 'asc');
          }
        } else {
          this.get('controller').set('sortBy', attr);
        }
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.store.find('customer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      reopen: function reopen(id) {
        var self = this;

        $.ajax({
          url: "/api/invoices/" + id + "/reopen",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'geändert in ausgestellt');
        }, function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'geändert in ausgestellt');
        });
      }
    }
  });

});