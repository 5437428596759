define('jason-frontend/templates/components/stable-select-customer-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 16
              },
              "end": {
                "line": 13,
                "column": 180
              }
            },
            "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["size",32,"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[13,72],[13,91]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[13,100],[13,124]]]]],[],[]],"evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[13,130],[13,142]]]]],[],[]],"content","","classNames","animal-icon"],["loc",[null,[13,40],[13,180]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 16
              },
              "end": {
                "line": 17,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-etiga-small",[],["content","","classNames","ml5 vertical-top inline-block","size","18","width",34],["loc",[null,[16,18],[16,113]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 16
              },
              "end": {
                "line": 18,
                "column": 167
              }
            },
            "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"class","button__icon__last");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-died",[],["size","16","useConfirm",false,"color","#3B6182","showStroke",false,"content","verstorben"],["loc",[null,[18,67],[18,163]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 12
            },
            "end": {
              "line": 23,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","part-button");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [9]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element0, 'title');
          morphs[1] = dom.createAttrMorph(element0, 'class');
          morphs[2] = dom.createAttrMorph(element0, 'data-category');
          morphs[3] = dom.createMorphAt(element0,1,1);
          morphs[4] = dom.createMorphAt(element0,3,3);
          morphs[5] = dom.createMorphAt(element0,5,5);
          morphs[6] = dom.createMorphAt(element0,7,7);
          morphs[7] = dom.createElementMorph(element1);
          morphs[8] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["attribute","title",["concat",["Besitzer:in ",["get","patient.customer.firstname",["loc",[null,[12,42],[12,68]]]]," ",["get","patient.customer.lastname",["loc",[null,[12,73],[12,98]]]]]]],
          ["attribute","class",["concat",["d-flex align-items-center tooltipstered mr5 selectable-item ",["subexpr","css-bool-evaluator",[["get","patient.category.id",["loc",[null,[12,190],[12,209]]]],"has-icon",""],[],["loc",[null,[12,169],[12,225]]]]," patient"]]],
          ["attribute","data-category",["concat",[["get","patient.category.id",["loc",[null,[12,252],[12,271]]]]]]],
          ["block","if",[["get","patient.category",["loc",[null,[13,22],[13,38]]]]],[],0,null,["loc",[null,[13,16],[13,187]]]],
          ["content","patient.name",["loc",[null,[14,16],[14,32]]]],
          ["block","if",[["get","patient.etigaActive",["loc",[null,[15,22],[15,41]]]]],[],1,null,["loc",[null,[15,16],[17,23]]]],
          ["block","if",[["get","patient.dead",["loc",[null,[18,22],[18,34]]]]],[],2,null,["loc",[null,[18,16],[18,174]]]],
          ["element","action",["removePatient",["get","stable.id",["loc",[null,[19,67],[19,76]]]],["get","patient.id",["loc",[null,[19,77],[19,87]]]]],["bubbles",false],["loc",[null,[19,42],[19,103]]]],
          ["inline","button-delete",[],["showStroke",false,"color","#fff","size",26,"content","Tier aus Stall entfernen"],["loc",[null,[20,18],[20,108]]]]
        ],
        locals: ["patient"],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 49,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-stable-select-customer-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-extremely-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("Stall: ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body");
        var el4 = dom.createTextNode("\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","mt0");
        var el5 = dom.createTextNode("Folgende Tiere sind derzeit eingestellt:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","d-flex align-items-center");
        dom.setAttribute(el4,"style","flex-wrap: wrap");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        var el5 = dom.createTextNode("Weitere Tiere hinzufügen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ml0 mb20 filter-box grid");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24@phone l-12/24 gutter searchInput");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","input search__button");
        dom.setAttribute(el6,"style","height: 42px !important;");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/search-icon-white.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24@phone l-12/24 gutter searchInput");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","input search__button");
        dom.setAttribute(el6,"style","height: 42px !important;");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/search-icon-white.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [7]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element4, [3]);
        var element8 = dom.childAt(element7, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        morphs[2] = dom.createMorphAt(element5,1,1);
        morphs[3] = dom.createElementMorph(element6);
        morphs[4] = dom.createMorphAt(element7,1,1);
        morphs[5] = dom.createElementMorph(element8);
        morphs[6] = dom.createMorphAt(element3,9,9);
        return morphs;
      },
      statements: [
        ["content","stable.name",["loc",[null,[4,47],[4,62]]]],
        ["block","each",[["get","stable.patients",["loc",[null,[11,20],[11,35]]]]],[],0,null,["loc",[null,[11,12],[23,21]]]],
        ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterName",["loc",[null,[30,50],[30,60]]]]],[],[]],"class","gui-input newStyle input input--search search__input","name","search-customer","placeholder","Kund:in suchen","autocomplete","off"],["loc",[null,[30,14],[31,42]]]],
        ["element","action",["triggerSearch"],[],["loc",[null,[32,84],[32,110]]]],
        ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[37,50],[37,63]]]]],[],[]],"class","gui-input newStyle input input--search search__input","name","search-patient","placeholder","Tiername suchen","autocomplete","off"],["loc",[null,[37,14],[38,42]]]],
        ["element","action",["triggerSearch"],[],["loc",[null,[39,84],[39,110]]]],
        ["inline","customer-searchform",[],["showCrud",false,"showQuickFilters",false,"hideContacts",true,"showFilter",false,"showSearch",false,"reference","stable","selectPatient","selectPatient","filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[45,182],[45,192]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[45,199],[45,204]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[45,213],[45,220]]]]],[],[]],"selectCustomer","selectCustomer","applyFilter","applyFilter"],["loc",[null,[45,10],[45,280]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});