define('jason-frontend/templates/cash/choose', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 6
            },
            "end": {
              "line": 45,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/choose.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[44,8],[44,29]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 56,
                        "column": 185
                      },
                      "end": {
                        "line": 56,
                        "column": 227
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/choose.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("(gelöscht)");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 57,
                        "column": 28
                      },
                      "end": {
                        "line": 58,
                        "column": 91
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/choose.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","small block");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","invoice.customer.company",["loc",[null,[58,56],[58,84]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 26
                    },
                    "end": {
                      "line": 59,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/choose.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                          ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
                  morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
                  morphs[4] = dom.createMorphAt(fragment,8,8,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["content","invoice.customer.title.name",["loc",[null,[56,92],[56,123]]]],
                  ["content","invoice.customer.firstname",["loc",[null,[56,124],[56,154]]]],
                  ["content","invoice.customer.lastname",["loc",[null,[56,155],[56,184]]]],
                  ["block","if",[["get","invoice.customer.deleted",["loc",[null,[56,191],[56,215]]]]],[],0,null,["loc",[null,[56,185],[56,234]]]],
                  ["block","if",[["get","invoice.customer.company",["loc",[null,[57,34],[57,58]]]]],[],1,null,["loc",[null,[57,28],[58,98]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 59,
                      "column": 26
                    },
                    "end": {
                      "line": 59,
                      "column": 62
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/choose.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","invoice.customer.company",["loc",[null,[59,34],[59,62]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 24
                  },
                  "end": {
                    "line": 59,
                    "column": 69
                  }
                },
                "moduleName": "jason-frontend/templates/cash/choose.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["get","invoice.customer.firstname",["loc",[null,[56,37],[56,63]]]],["get","invoice.customer.lastname",["loc",[null,[56,64],[56,89]]]]],[],["loc",[null,[56,32],[56,90]]]]],[],0,1,["loc",[null,[56,26],[59,69]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 16
                },
                "end": {
                  "line": 60,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","link-to",["customers.edit",["get","invoice.customer.id",["loc",[null,[55,52],[55,71]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[55,24],[59,81]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 16
                },
                "end": {
                  "line": 62,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  Barverkauf\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 59
                },
                "end": {
                  "line": 64,
                  "column": 91
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(",");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 72,
                    "column": 22
                  },
                  "end": {
                    "line": 72,
                    "column": 182
                  }
                },
                "moduleName": "jason-frontend/templates/cash/choose.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","button__icon");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[72,92],[72,113]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[72,124],[72,143]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[72,152],[72,176]]]]],[],[]]],["loc",[null,[72,70],[72,178]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 14
                },
                "end": {
                  "line": 77,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","patient-selector selected");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createMorphAt(element3,1,1);
              morphs[2] = dom.createMorphAt(element3,3,3);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["button ",["subexpr","value-bool-evaluator",[["get","patient.category",["loc",[null,[71,62],[71,78]]]],"button--has-icon",""],[],["loc",[null,[71,39],[71,102]]]]," button--toggle no-hover  large-confirm"]]],
              ["block","if",[["get","patient.category",["loc",[null,[72,28],[72,44]]]]],[],0,null,["loc",[null,[72,22],[72,189]]]],
              ["content","patient.name",["loc",[null,[73,22],[73,38]]]]
            ],
            locals: ["patient"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 14
                },
                "end": {
                  "line": 90,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Auswählen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(element1,1,1);
              morphs[2] = dom.createMorphAt(element2,1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element2, [2]),0,0);
              return morphs;
            },
            statements: [
              ["element","action",["selectInvoice",["get","invoice.id",["loc",[null,[84,44],[84,54]]]]],[],["loc",[null,[84,19],[84,56]]]],
              ["inline","button-select",[],["color","white","showStroke",false],["loc",[null,[85,18],[85,66]]]],
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"item",["subexpr","@mut",[["get","invoice",["loc",[null,[88,83],[88,90]]]]],[],[]],"confirmed","closeInvoice","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[88,18],[88,176]]]],
              ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","invoice",["loc",[null,[88,207],[88,214]]]]],[],[]],"confirmed","closeInvoice","textToShow","Löschen","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[88,182],[88,321]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 90,
                    "column": 16
                  },
                  "end": {
                    "line": 94,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/cash/choose.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["undeleteInvoice",["get","invoice.id",["loc",[null,[91,108],[91,118]]]]],[],["loc",[null,[91,81],[91,120]]]],
                ["inline","button-checked",[],["content","Gelöschten Datensatz wiederherstellen","color","#fff","showStroke",false],["loc",[null,[92,20],[92,116]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 90,
                  "column": 16
                },
                "end": {
                  "line": 94,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","quickFilter",["loc",[null,[90,30],[90,41]]]],"deleted"],[],["loc",[null,[90,26],[90,52]]]]],[],0,null,["loc",[null,[90,16],[94,16]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 97,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/choose.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center table__cell--name l-12/24 l-3/24@desk");
            dom.setAttribute(el2,"data-label","Datum");
            var el3 = dom.createTextNode("\n               ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n               ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Uhr");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-6/24@desk");
            dom.setAttribute(el2,"data-label","Rechnungsempfänger");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-7/24@desk");
            dom.setAttribute(el2,"data-label","Leistungsempfänger");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right@desk table__cell--name l-12/24 l-24/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Summe brutto");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-5/24@desk buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [1]);
            var element6 = dom.childAt(element4, [3]);
            var element7 = dom.childAt(element6, [3]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
            morphs[3] = dom.createMorphAt(element7,0,0);
            morphs[4] = dom.createMorphAt(element7,1,1);
            morphs[5] = dom.createMorphAt(element7,3,3);
            morphs[6] = dom.createMorphAt(element7,5,5);
            morphs[7] = dom.createMorphAt(dom.childAt(element4, [5]),1,1);
            morphs[8] = dom.createMorphAt(dom.childAt(element4, [7, 1]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(element4, [9]),1,1);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","invoice.created",["loc",[null,[49,35],[49,50]]]],"DD. MM. YYYY"],[],["loc",[null,[49,21],[49,67]]]],
            ["inline","time-format",[["get","invoice.created",["loc",[null,[50,47],[50,62]]]],"HH:mm"],[],["loc",[null,[50,33],[50,72]]]],
            ["block","if",[["get","invoice.customer",["loc",[null,[54,22],[54,38]]]]],[],0,1,["loc",[null,[54,16],[62,23]]]],
            ["content","invoice.customer.street",["loc",[null,[64,32],[64,59]]]],
            ["block","if",[["get","invoice.customer.street",["loc",[null,[64,65],[64,88]]]]],[],2,null,["loc",[null,[64,59],[64,98]]]],
            ["content","invoice.customer.postalCode",["loc",[null,[64,99],[64,130]]]],
            ["content","invoice.customer.town",["loc",[null,[64,131],[64,156]]]],
            ["block","each",[["get","invoice.selectedPatients",["loc",[null,[68,22],[68,46]]]]],[],3,null,["loc",[null,[68,14],[77,25]]]],
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","invoice.total",["loc",[null,[80,44],[80,57]]]]],[],[]]],["loc",[null,[80,20],[80,59]]]],
            ["block","if",[["subexpr","eq",[["get","quickFilter",["loc",[null,[83,24],[83,35]]]],"all"],[],["loc",[null,[83,20],[83,42]]]]],[],4,5,["loc",[null,[83,14],[94,23]]]]
          ],
          locals: ["invoice"],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 6
            },
            "end": {
              "line": 98,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/choose.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model",["loc",[null,[46,16],[46,21]]]]],[],0,null,["loc",[null,[46,8],[97,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 108,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/choose.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-16/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Neue Ausgangsrechnung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid with-context-bar small-dashboard");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col pl0 u-hide@phone l-12/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Anzahl offen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col pl0 u-hide@phone l-12/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Summe offen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Alle");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table box no-box@phone grid table--large");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-3/24 table__cell--center");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Datum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-6/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Rechnungsempfänger");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-7/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Leistungsempfänger");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--right l-3/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Summe brutto");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-5/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(element8, [3, 1]);
        var element10 = dom.childAt(fragment, [2]);
        var element11 = dom.childAt(fragment, [4, 1]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element12, [5]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element8, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element8, [1]),1,1);
        morphs[2] = dom.createElementMorph(element9);
        morphs[3] = dom.createMorphAt(dom.childAt(element10, [1, 1, 1]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element10, [3, 1, 1]),0,0);
        morphs[5] = dom.createAttrMorph(element13, 'class');
        morphs[6] = dom.createElementMorph(element13);
        morphs[7] = dom.createAttrMorph(element14, 'class');
        morphs[8] = dom.createElementMorph(element14);
        morphs[9] = dom.createMorphAt(dom.childAt(element11, [3, 3]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element11, [5]),1,1);
        morphs[11] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,50],[1,63]]]],"sidebar",""],[],["loc",[null,[1,29],[1,78]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[3,28],[3,38]]]]],[],[]],"placeHolder","Kunde suchen","search","applyFilter"],["loc",[null,[3,4],[3,88]]]],
        ["element","action",["createInvoice"],[],["loc",[null,[6,7],[6,34]]]],
        ["content","count",["loc",[null,[15,30],[15,39]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","sum",["loc",[null,[21,54],[21,57]]]]],[],[]],"class","inline"],["loc",[null,[21,30],[21,74]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[31,61],[31,72]]]],"all"],[],["loc",[null,[31,57],[31,79]]]],"selected",""],[],["loc",[null,[31,36],[31,96]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[31,98],[31,134]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[32,61],[32,72]]]],"deleted"],[],["loc",[null,[32,57],[32,83]]]],"selected",""],[],["loc",[null,[32,36],[32,100]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[32,102],[32,142]]]],
        ["block","if",[["get","showLoading",["loc",[null,[43,12],[43,23]]]]],[],0,1,["loc",[null,[43,6],[98,13]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[102,29],[102,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[102,6],[102,55]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[107,41],[107,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[107,74],[107,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[107,134],[107,152]]]]],[],[]]],["loc",[null,[107,0],[107,154]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});