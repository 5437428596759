define('jason-frontend/templates/components/search-inventory-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 12
              },
              "end": {
                "line": 13,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 300px");
            dom.setAttribute(el1,"class","media-object");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element19, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","get-host-path",[["get","selectedArticle.picture.path",["loc",[null,[12,87],[12,115]]]]],[],["loc",[null,[12,71],[12,117]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 12
              },
              "end": {
                "line": 16,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 300px;margin-top:30px");
            dom.setAttribute(el1,"class","media-object");
            dom.setAttribute(el1,"src","assets/images/icons/noimage.svg");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 10
              },
              "end": {
                "line": 20,
                "column": 122
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","title title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["content","selectedArticle.partnerCategory",["loc",[null,[20,75],[20,110]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 19
              },
              "end": {
                "line": 29,
                "column": 43
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Ja");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 43
              },
              "end": {
                "line": 29,
                "column": 55
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Nein");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 16
              },
              "end": {
                "line": 37,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Bestand in ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(element18,0,0);
            morphs[2] = dom.createMorphAt(element18,2,2);
            return morphs;
          },
          statements: [
            ["content","summary.unit",["loc",[null,[35,36],[35,52]]]],
            ["inline","format-number-german",[["get","summary.stock",["loc",[null,[36,44],[36,57]]]]],[],["loc",[null,[36,21],[36,59]]]],
            ["content","summary.unit",["loc",[null,[36,60],[36,76]]]]
          ],
          locals: ["summary"],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 14
                },
                "end": {
                  "line": 78,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk table__cell--center");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk");
              var el3 = dom.createElement("b");
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("/");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24 l-3/24@desk table__cell--center");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24 l-7/24@desk table__cell--center break-word");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk table__cell--center");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24 l-2/24@desk table__cell--action");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","icon-button list-action-square");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/icon-next.svg");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var element15 = dom.childAt(element14, [3]);
              var element16 = dom.childAt(element15, [0, 0]);
              var element17 = dom.childAt(element14, [11, 1]);
              var morphs = new Array(9);
              morphs[0] = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
              morphs[1] = dom.createAttrMorph(element16, 'class');
              morphs[2] = dom.createMorphAt(element16,0,0);
              morphs[3] = dom.createMorphAt(element16,2,2);
              morphs[4] = dom.createMorphAt(element15,2,2);
              morphs[5] = dom.createMorphAt(dom.childAt(element14, [5]),1,1);
              morphs[6] = dom.createMorphAt(dom.childAt(element14, [7]),1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element14, [9]),1,1);
              morphs[8] = dom.createElementMorph(element17);
              return morphs;
            },
            statements: [
              ["content","item.id",["loc",[null,[59,82],[59,93]]]],
              ["attribute","class",["concat",[["subexpr","get-stock-color",[["get","item.flatStock",["loc",[null,[61,45],[61,59]]]],["get","item.packageQuantity",["loc",[null,[61,60],[61,80]]]]],[],["loc",[null,[61,27],[61,82]]]]]]],
              ["inline","format-number-german",[["get","item.flatStock",["loc",[null,[62,20],[62,34]]]]],[],["loc",[null,[61,84],[62,36]]]],
              ["content","item.packageQuantity",["loc",[null,[62,37],[62,61]]]],
              ["content","item.unit.name",["loc",[null,[62,73],[62,91]]]],
              ["content","item.warehouseName",["loc",[null,[64,20],[64,42]]]],
              ["content","item.batchNumber",["loc",[null,[67,20],[67,40]]]],
              ["inline","time-format",[["get","item.expiryDate",["loc",[null,[70,34],[70,49]]]],"DD. MM. YYYY"],[],["loc",[null,[70,20],[70,66]]]],
              ["element","action",["selectStock",["get","item",["loc",[null,[73,85],[73,89]]]]],[],["loc",[null,[73,62],[73,91]]]]
            ],
            locals: ["item"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 8
              },
              "end": {
                "line": 81,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table mt30 grid table--large box no-box@phone");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__head");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24 table__cell--center");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Inventar-Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Verfügbar");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-3/24 table__cell--center");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Lager");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-7/24 table__cell--center");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Chargen-Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24 table__cell--center");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Ablaufdatum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-2/24");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","stockItems",["loc",[null,[57,22],[57,32]]]]],[],0,null,["loc",[null,[57,14],[78,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 8
              },
              "end": {
                "line": 83,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          Keine Lagerbestände\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 90,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-heading");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","panel-title");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-8/24");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","u-text--center pr30");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-16/24");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","dataList grid");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col l-12/24");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          var el7 = dom.createTextNode("Verpackungsmenge");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("p");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          var el7 = dom.createTextNode("Kategorie");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("p");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","");
          var el7 = dom.createTextNode("Suchtgiftmittel");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("p");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","");
          var el7 = dom.createTextNode("Wirkstoffe / Zusatzstoffe");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("p");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col l-12/24");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-footer text-right");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button icon-button--small");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [3]);
          var element21 = dom.childAt(element20, [3]);
          var element22 = dom.childAt(element21, [3, 1]);
          var element23 = dom.childAt(element22, [1]);
          var element24 = dom.childAt(element23, [3]);
          var element25 = dom.childAt(fragment, [5, 1]);
          var morphs = new Array(12);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element20, [1, 1]),1,1);
          morphs[2] = dom.createMorphAt(element21,1,1);
          morphs[3] = dom.createMorphAt(element24,0,0);
          morphs[4] = dom.createMorphAt(element24,2,2);
          morphs[5] = dom.createMorphAt(dom.childAt(element23, [7]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element23, [11]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element23, [15]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element22, [3]),1,1);
          morphs[9] = dom.createMorphAt(element20,5,5);
          morphs[10] = dom.createElementMorph(element25);
          morphs[11] = dom.createMorphAt(element25,1,1);
          return morphs;
        },
        statements: [
          ["content","selectedArticle.name",["loc",[null,[6,40],[6,64]]]],
          ["block","if",[["get","selectedArticle.picture",["loc",[null,[11,18],[11,41]]]]],[],0,1,["loc",[null,[11,12],[16,19]]]],
          ["block","if",[["get","selectedArticle.partnerCategory",["loc",[null,[20,16],[20,47]]]]],[],2,null,["loc",[null,[20,10],[20,129]]]],
          ["content","purchaseProduct.packageQuantity",["loc",[null,[25,19],[25,54]]]],
          ["content","model.unit.name",["loc",[null,[25,55],[25,74]]]],
          ["inline","get-value-or-empty-sign",[["get","model.category.name",["loc",[null,[27,45],[27,64]]]],"-"],[],["loc",[null,[27,19],[27,70]]]],
          ["block","if",[["get","model.narcotic",["loc",[null,[29,25],[29,39]]]]],[],3,4,["loc",[null,[29,19],[29,62]]]],
          ["inline","get-value-or-empty-sign",[["get","model.ingredients",["loc",[null,[31,45],[31,62]]]],["get","-",["loc",[null,[31,63],[31,64]]]]],[],["loc",[null,[31,19],[31,67]]]],
          ["block","each",[["get","stockSummary",["loc",[null,[34,24],[34,36]]]]],[],5,null,["loc",[null,[34,16],[37,25]]]],
          ["block","if",[["get","stockItems",["loc",[null,[43,14],[43,24]]]]],[],6,7,["loc",[null,[43,8],[83,15]]]],
          ["element","action",["back"],[],["loc",[null,[86,50],[86,67]]]],
          ["inline","button-prev",[],["size","40","showStroke",false,"content","Zurück"],["loc",[null,[87,10],[87,69]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 10
                },
                "end": {
                  "line": 109,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","loading-animation",["loc",[null,[108,12],[108,33]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 121,
                    "column": 16
                  },
                  "end": {
                    "line": 146,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24 l-2/24@desk");
                dom.setAttribute(el2,"data-label","");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createElement("img");
                dom.setAttribute(el4,"src","assets/images/icons/product-dark.svg");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--name l-20/24 l-8/24@desk");
                dom.setAttribute(el2,"data-label","Produkt");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24 l-3/24@desk table__cell--center text-center");
                dom.setAttribute(el2,"data-label","Lagerbestand");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24 l-3/24@desk text-center");
                dom.setAttribute(el2,"data-label","Lager");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24 text-center l-4/24@desk");
                dom.setAttribute(el2,"data-label","VPE");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-4/24@desk buttons-1");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var element9 = dom.childAt(element8, [9, 1]);
                var element10 = dom.childAt(element8, [11, 1]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(element8, [3, 1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element8, [5, 1]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element8, [7, 1]),0,0);
                morphs[3] = dom.createMorphAt(element9,0,0);
                morphs[4] = dom.createMorphAt(element9,2,2);
                morphs[5] = dom.createElementMorph(element10);
                morphs[6] = dom.createMorphAt(element10,1,1);
                return morphs;
              },
              statements: [
                ["content","item.productName",["loc",[null,[127,28],[127,48]]]],
                ["inline","stock-status",[],["stocks",["subexpr","@mut",[["get","item.stockLevels",["loc",[null,[131,50],[131,66]]]]],[],[]]],["loc",[null,[131,28],[131,68]]]],
                ["content","item.warehouseName",["loc",[null,[134,28],[134,50]]]],
                ["content","item.packageQuantity",["loc",[null,[137,28],[137,52]]]],
                ["content","item.unit.name",["loc",[null,[137,53],[137,71]]]],
                ["element","action",["selectProduct",["get","item",["loc",[null,[140,50],[140,54]]]]],[],["loc",[null,[140,25],[140,56]]]],
                ["inline","button-next",[],["content","Auswählen","size","32","color","#fff","showStroke",false],["loc",[null,[142,24],[142,99]]]]
              ],
              locals: ["item"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 109,
                  "column": 10
                },
                "end": {
                  "line": 149,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table grid table--large box no-box@phone");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__head");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-2/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-8/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Produkt");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-3/24 table__cell--center");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Lagerbestand");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-3/24 table__cell--center");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Lager");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-4/24 table__cell--center");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("VPE");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-4/24");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__body");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","items",["loc",[null,[121,24],[121,29]]]]],[],0,null,["loc",[null,[121,16],[146,25]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 4
              },
              "end": {
                "line": 156,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","panel-heading");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","panel-title");
            var el3 = dom.createTextNode("Artikel suchen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","panel-body");
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("form");
            dom.setAttribute(el2,"class","search-box-overlay");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","search");
            dom.setAttribute(el3,"class","search-box");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4,"class","input search__button");
            dom.setAttribute(el4,"type","submit");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("img");
            dom.setAttribute(el5,"src","assets/images/icons/search-icon-white.svg");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table-wrapper mt20");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [3]);
            var element12 = dom.childAt(element11, [1]);
            var element13 = dom.childAt(element11, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element12);
            morphs[1] = dom.createMorphAt(dom.childAt(element12, [1]),3,3);
            morphs[2] = dom.createMorphAt(element13,1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
            return morphs;
          },
          statements: [
            ["element","action",["search"],["on","submit"],["loc",[null,[96,41],[96,72]]]],
            ["inline","input",[],["name","search","value",["subexpr","@mut",[["get","filterName",["loc",[null,[101,40],[101,50]]]]],[],[]],"class","gui-input input input--search search__input","placeholder","Artikel, Inventar-Nr suchen","autocomplete","off"],["loc",[null,[101,12],[102,82]]]],
            ["block","if",[["get","showLoading",["loc",[null,[107,16],[107,27]]]]],[],0,1,["loc",[null,[107,10],[149,17]]]],
            ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[152,35],[152,40]]]]],[],[]],"numPagesToShow",7],["loc",[null,[152,12],[152,59]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 156,
                  "column": 4
                },
                "end": {
                  "line": 244,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("form");
              var el2 = dom.createTextNode("\n\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","panel-heading");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","panel-title");
              var el4 = dom.createTextNode("\n                Korrekturbuchung erfassen");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","panel-body p25");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","box");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-12/24");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field");
              var el6 = dom.createTextNode("\n                  ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","new-not-title");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("Artikel");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                  ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-12/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","new-not-title");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("Inventar-Nr");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-12/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","new-not-title");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("Lager");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-12/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","new-not-title");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("Lagerplatz");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-12/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","new-not-title");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("VPE");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-4/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field number-input");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","new-not-title");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("SOLL-Bestand");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("span");
              dom.setAttribute(el6,"class","is-highlighted");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode(" ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-4/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field number-input");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","shouldstand");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("IST-Bestand");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("span");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-4/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field number-input");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","shouldstand");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("Differenz");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("span");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-24/24");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","field");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.setAttribute(el6,"for","treatment-room");
              dom.setAttribute(el6,"class","field-label title");
              var el7 = dom.createTextNode("Korrektur-Typ\n                  ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-24/24");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n           ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-12/24@desk");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("label");
              dom.setAttribute(el5,"for","notice");
              dom.setAttribute(el5,"class","field-label title");
              var el6 = dom.createTextNode("Kommentar");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("\n        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","panel-footer text-right");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","icon-button icon-button--small");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"type","submit");
              dom.setAttribute(el3,"class","icon-button icon-button--success");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [3, 1]);
              var element2 = dom.childAt(element1, [9, 1]);
              var element3 = dom.childAt(element1, [11, 1, 3]);
              var element4 = dom.childAt(element1, [13, 1]);
              var element5 = dom.childAt(element1, [15, 1]);
              var element6 = dom.childAt(element0, [5]);
              var element7 = dom.childAt(element6, [1]);
              var morphs = new Array(18);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]),3,3);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 1]),3,3);
              morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 1]),3,3);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [7, 1]),3,3);
              morphs[5] = dom.createMorphAt(element2,3,3);
              morphs[6] = dom.createMorphAt(element2,5,5);
              morphs[7] = dom.createMorphAt(element3,0,0);
              morphs[8] = dom.createMorphAt(element3,2,2);
              morphs[9] = dom.createMorphAt(element4,3,3);
              morphs[10] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
              morphs[11] = dom.createMorphAt(element5,3,3);
              morphs[12] = dom.createMorphAt(dom.childAt(element5, [5]),0,0);
              morphs[13] = dom.createMorphAt(dom.childAt(element1, [17, 1, 1]),1,1);
              morphs[14] = dom.createMorphAt(dom.childAt(element1, [21]),3,3);
              morphs[15] = dom.createElementMorph(element7);
              morphs[16] = dom.createMorphAt(element7,1,1);
              morphs[17] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
              return morphs;
            },
            statements: [
              ["element","action",["confirmCorrection"],["on","submit"],["loc",[null,[157,14],[157,56]]]],
              ["content","selectedStock.product.name",["loc",[null,[169,18],[169,48]]]],
              ["content","selectedStock.id",["loc",[null,[175,16],[175,36]]]],
              ["content","selectedStock.warehouseName",["loc",[null,[181,16],[181,47]]]],
              ["content","selectedStock.locationInfo",["loc",[null,[187,16],[187,46]]]],
              ["inline","format-number-german",[["get","selectedStock.packageQuantity",["loc",[null,[193,39],[193,68]]]]],[],["loc",[null,[193,16],[193,70]]]],
              ["content","selectedStock.unit.name",["loc",[null,[193,71],[193,98]]]],
              ["inline","format-number-german",[["get","selectedStock.flatStock",["loc",[null,[199,68],[199,91]]]]],[],["loc",[null,[199,45],[199,93]]]],
              ["content","selectedStock.unit.name",["loc",[null,[199,94],[199,121]]]],
              ["inline","input",[],["value",["subexpr","@mut",[["get","selectedStock.shouldStand",["loc",[null,[205,30],[205,55]]]]],[],[]],"class","gui-input shouldstand","name","shouldstand"],["loc",[null,[205,16],[205,106]]]],
              ["content","selectedStock.unit.name",["loc",[null,[205,113],[205,140]]]],
              ["inline","format-number-german",[["subexpr","minus",[["get","selectedStock.shouldStand",["loc",[null,[211,46],[211,71]]]],["get","selectedStock.flatStock",["loc",[null,[211,72],[211,95]]]]],[],["loc",[null,[211,39],[211,96]]]]],[],["loc",[null,[211,16],[211,98]]]],
              ["content","selectedStock.unit.name",["loc",[null,[211,105],[211,132]]]],
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","correctionTypes",["loc",[null,[218,28],[218,43]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedStock.subType",["loc",[null,[219,26],[219,47]]]]],[],[]],"cssClass","mt10 input--dropdown","optionLabelPath","name","allowClear",false,"name","subType"],["loc",[null,[217,18],[224,20]]]],
              ["inline","textarea",[],["name","notice","value",["subexpr","@mut",[["get","selectedStock.notice",["loc",[null,[231,45],[231,65]]]]],[],[]],"class","gui-textarea"],["loc",[null,[231,14],[231,88]]]],
              ["element","action",["backToVpe"],[],["loc",[null,[236,54],[236,76]]]],
              ["inline","button-prev",[],["size","40","showStroke",false,"content","Zurück"],["loc",[null,[237,14],[237,73]]]],
              ["inline","button-checked",[],["size","52","showStroke",false,"color","#ffffff","content","Speichern"],["loc",[null,[240,14],[240,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 156,
                "column": 4
              },
              "end": {
                "line": 244,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","viewName",["loc",[null,[156,18],[156,26]]]],"correction"],[],["loc",[null,[156,14],[156,40]]]]],[],0,null,["loc",[null,[156,4],[244,4]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 90,
              "column": 4
            },
            "end": {
              "line": 244,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","viewName",["loc",[null,[90,18],[90,26]]]],"product"],[],["loc",[null,[90,14],[90,37]]]]],[],0,1,["loc",[null,[90,4],[244,4]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 248,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/search-inventory-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-search-inventory-panel");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","viewName",["loc",[null,[4,14],[4,22]]]],"vpe"],[],["loc",[null,[4,10],[4,29]]]]],[],0,1,["loc",[null,[4,4],[244,11]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});