define('jason-frontend/components/export-template-document-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    downloadType: 'archive',
    actions: {
      load: function load(ctx) {
        var self = this;
        $.ajax({
          url: "/api/templates",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('templates', data.template);
          self.set('selectedTemplateId', data.template[0].id);
        }, function () {});
      },
      download: function download() {
        var self = this;
        var dateRange = this.get('pickDate'),
            dateFromTo = dateRange.split('-');

        var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
            to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          $.magnificPopup.close();
        }, function (data) {
          window.open('/api/documents/downloadTemplates?token=' + data.responseText + '&downloadType=' + self.get('downloadType') + '&templateId=' + self.get('selectedTemplateId') + '&dateFrom=' + from + '&dateTo=' + to, '_blank');
          $.magnificPopup.close();
        });
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});