define('jason-frontend/components/copy-treatment-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/helpers/uses-stable-feature', 'jason-frontend/api'], function (exports, Ember, InboundActions, uses_stable_feature, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedMode: 'owner',
    currentView: 'options',
    actions: {
      confirm: function confirm() {
        var selectedInvoice = this.get('mode') === 'compositeStable' ? this.get('selectedStableOwnerInvoice') : this.get('selectedInvoice');
        this.sendAction('confirm', this.get('tpId'), this.get('selectedMode'), selectedInvoice);
        $.magnificPopup.close();
      },
      selectAnimal: function selectAnimal(patient) {
        Ember['default'].set(patient, 'selected', !patient.selected);
        var atLeastOneSelected = false;
        this.get('animals').forEach(function (animal) {
          if (animal.selected) {
            atLeastOneSelected = true;
          }
        });
        this.set('hasAnimalsSelected', atLeastOneSelected);
      },
      choose: function choose() {
        var selectedAnimals = [];
        this.get('animals').forEach(function (animal) {
          if (animal.selected) {
            selectedAnimals.push(animal.id);
          }
        });

        var data = {
          patientIds: selectedAnimals
        };

        $.ajax({
          url: "/api/treatmentPatients/" + this.get('tpId') + "/copyToPatients",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'Behandlung erfolgreich kopiert');
          $.magnificPopup.close();
        }, function () {
          jason.notifiction.notifiy('Erfolgreich', 'Behandlung erfolgreich kopiert');
          $.magnificPopup.close();
        });
      },
      next: function next() {
        var self = this;
        this.set('currentView', 'animals');
        this.set('hasAnimals', true);
        this.set('hasAnimalsSelected', false);
        if (this.get('selectedMode') === 'stable') {
          $.ajax({
            url: "/api/stables/" + this.get('copyData').stable.id + "?filterOutPatientId=" + this.get('copyData').patientId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('animals', data.stable.patients);
            self.set('hasAnimals', data.stable.patients && data.stable.patients.length > 0);
          }, function () {});
        } else if (this.get('selectedMode') === 'owner') {
          $.ajax({
            url: "/api/customers/" + this.get('copyData').owner.id + "?filterOutPatientId=" + this.get('copyData').patientId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('animals', data.customer.patients);
            self.set('hasAnimals', data.customer.patients && data.customer.patients.length > 0);
          }, function () {});
        }
      },
      back: function back() {
        this.set('currentView', 'options');
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      selectMode: function selectMode(mode) {
        this.set('selectedMode', mode);
      },
      load: function load(tpId, data) {
        var self = this;
        this.set('tpId', tpId);
        this.set('currentView', 'options');
        this.set('copyData', data);
        this.set('selectedMode', 'owner');
        if (data.stable) {
          this.set('selectedMode', 'stable');
        }
      }
    }
  });

});