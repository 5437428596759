define('jason-frontend/components/copy-resource-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'moment', 'jason-frontend/api'], function (exports, Ember, InboundActions, moment, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    weekNumberSource: moment['default']().week(),
    weekNumberTarget: moment['default']().week() + 1,
    yearSource: moment['default']().format("YYYY"),
    yearTarget: moment['default']().format("YYYY"),
    years: [{
      id: moment['default']().subtract(1, 'year').format('YYYY'), name: moment['default']().subtract(1, 'year').format('YYYY')
    }, {
      id: moment['default']().format('YYYY'), name: moment['default']().format('YYYY')
    }, {
      id: moment['default']().add(1, 'year').format('YYYY'), name: moment['default']().add(1, 'year').format('YYYY')
    }],
    save: function save() {
      $.magnificPopup.close();
    },
    actions: {
      init: function init(user, startWeek, startYear) {
        var all = new Array();
        for (var i = 1; i <= 52; i++) {
          all.push({
            id: i,
            name: 'KW ' + i
          });
        }
        this.set('weeks', all);
        this.set('user', user);

        this.set('weekNumberSource', startWeek);
        this.set('yearSource', startYear);
        this.set('weekNumberTarget', parseInt(startWeek) + 1);
        this.set('yearTarget', startYear);
        if (parseInt(startWeek) >= 52) {
          this.set('yearTarget', parseInt(startYear) + 1);
          this.set('weekNumberTarget', 1);
        }
      },

      save: function save() {
        this.save();
      },

      confirm: function confirm() {

        var userSuffix = '';
        if (this.get('user')) {
          userSuffix = "userId=" + this.user.id + "&";
        }

        $.ajax({
          url: "/api/resourceWeeks/copy?" + userSuffix + "yearSource=" + this.get('yearSource') + "&weekNumberSource=" + this.get('weekNumberSource') + "&yearTarget=" + this.get('yearTarget') + "&weekNumberTarget=" + this.get('weekNumberTarget'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'übertragen');
          $.magnificPopup.close();
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'übertragen');
          $.magnificPopup.close();
        });
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});