define('jason-frontend/routes/practicemgmt/treatment-journal-new', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api', 'jason-frontend/helpers/uses-stable-feature'], function (exports, Ember, RouteMixin, API, uses_stable_feature) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100 }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('patientCategories', this.store.find('patient-category'));

      if (uses_stable_feature.usesStableFeature()) {
        controller.set('stables', this.store.find('stable'));
      }

      var self = this;
      setTimeout(function () {
        controller.get('patientActivities').send('load', self, null);
      }, 500);
    },
    reload: function reload() {
      this.get('controller').get('patientActivities').send('reload');
    },
    actions: {
      saveSlopingInfo: function saveSlopingInfo(data, entryId) {
        var self = this,
            controller = this.get('controller'),
            deferred = $.ajax({
          url: "/api/treatmentOffers/" + data.offerId + "/changeSlopingInfo/" + entryId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reload();
        }, function (error) {
          self.reload();
        });
      },
      openSlopingInfos: function openSlopingInfos(entry) {
        var self = this;

        var articleId = undefined;
        var article = undefined;
        try {
          articleId = entry.get('article').id;
          article = entry.get('article');
        } catch (e) {
          articleId = entry.article.id;
          article = entry.article;
        }

        $.ajax({
          url: "/api/products/" + articleId + "/slopingInfos",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('slopingInfoPanel').send('load', data.productSlopingInfo, article, entry);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-sloping-info-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      download: function download() {
        this.get('controller').get('patientActivities').send('download');
      },
      openEditAppointment: function openEditAppointment(itemId) {
        var self = this;

        $.ajax({
          url: "/api/patientAppointments/" + itemId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addAppointmentPanel').send('loadEdit', data.patientAppointment, self.store.find('patient-appointment-category'));
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDocument: function openDocument(id, patientId) {
        this.transitionTo('patients.edit', patientId, { queryParams: { selectTab: 'documents' } });
      },
      openLab: function openLab(id, patientId) {
        this.transitionTo('patients.edit', patientId, { queryParams: { selectTab: 'lab' } });
      },
      editFullTreatment: function editFullTreatment(treatmentId) {
        this.transitionTo('practicemgmt.treatment-details', treatmentId, { queryParams: { entry: "true", selectTab: 'now' } });
      },
      reload: function reload() {
        this.reload();
      },
      editMemo: function editMemo(id, patientId) {
        var self = this;
        $.ajax({
          url: "/api/patientMemos/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-edit-patient-memo-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
          self.get('controller').get('editPatientMemoPanel').send('load', data.patientMemo, patientId);
        }, function (error) {});
      },
      selectSlopingBarcode: function selectSlopingBarcode(entryId, format) {
        window.open('/api/treatmentOffers/downloadSloping/' + entryId + '/_logo?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      createAppointment: function createAppointment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointments",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.reload();
        }, function () {});
      },
      gotoReport: function gotoReport(tpId, id) {
        this.transitionTo('editor.edit', tpId, 'treatmentreport', id);
      },
      gotoTemplate: function gotoTemplate(tpId, id, referer, patientId) {
        this.transitionTo('editor.edit', id, 'templateinstance', tpId, { queryParams: { context: referer, patientId: patientId } });
      },
      employeesSaved: function employeesSaved(data) {
        $.ajax({
          url: "/api/treatmentPatients/changeEmployees",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      roomSaved: function roomSaved(data) {
        $.ajax({
          url: "/api/treatmentPatients/updateRoom",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      deleteTreatment: function deleteTreatment(id) {
        var controller = this.get('controller'),
            self = this;

        var deferred = $.ajax({
          url: "/api/treatmentPatients/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Behandlung entfernt', '');
          self.applyFilter();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      saveTreatment: function saveTreatment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientTreatmentInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.applyFilter();
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden!');
        });
      },
      updatePartnerCategories: function updatePartnerCategories() {
        var controller = this.controller;
        var selectedSupplier = controller.get('filterSupplier');

        if (selectedSupplier) {
          $.ajax({
            url: "/api/purchaseProducts/partnerCategories/" + selectedSupplier.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('partnerCategories', data.partnerCategory);
          });
        }
      },
      queryProductsPurchase: function queryProductsPurchase(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryServices: function queryServices(query, deferred) {
        this.store.find('service', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      gotoProduct: function gotoProduct(id) {
        this.transitionTo('products.edit', id, { queryParams: { selectTab: "sloping" } });
      }
    }
  });

});