define('jason-frontend/helpers/show-treatment-tile', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.showTreatmentTile = showTreatmentTile;

  function showTreatmentTile(params) {
    var treatment = params[0],
        selectedUsers = params[1],
        filterName = params[2];

    var showUser = true;
    if (treatment.treatmentPatients) {
      $.each(treatment.treatmentPatients, function (index, value) {
        if (selectedUsers && selectedUsers.length) {
          var doctor = selectedUsers.some(function (u) {
            return value.doctor && parseInt(u) === parseInt(value.doctor.id);
          });
          var assistent = selectedUsers.some(function (u) {
            return value.assistant && parseInt(u) === parseInt(value.assistant.id);
          });
          var notAssigned = selectedUsers.some(function (u) {
            return parseInt(u) === 9998 && value.doctor === null && value.assistant === null;
          });
          showUser = assistent || doctor || notAssigned;
        }
        var showFiltered = true;
        if (filterName && filterName.length > 0 && value.patient && value.patientOwner) {
          showFiltered = value.patientOwner.firstname.toLowerCase().includes(filterName.toLowerCase()) || value.patientOwner.lastname.toLowerCase().includes(filterName.toLowerCase()) || value.patient.name.toLowerCase().includes(filterName.toLowerCase());
          showUser = showUser && showFiltered;
        }
      });
    }

    return showUser;
  }

  exports['default'] = Ember['default'].Helper.helper(showTreatmentTile);

});