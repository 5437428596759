define('jason-frontend/routes/cash/reciept', ['exports', 'ember', 'jason-frontend/api', 'moment'], function (exports, Ember, API, moment) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('c_payments')) {
        this.transitionTo('home');
      }

      this.reset(controller);

      this.store.find('cashRegister', { hideInactive: true }).then(function (data) {
        controller.set('cashRegisterList', data);
        controller.set('selectedCashRegister', data.get('firstObject'));

        if (API['default'].getPrimaryCashRegisterId() && API['default'].getPrimaryCashRegisterId() > 0) {
          data.forEach(function (item) {
            if (item.id === API['default'].getPrimaryCashRegisterId()) {
              controller.set('selectedCashRegister', item);
            }
          });
        }
      });

      controller.set('accounts', this.store.findAll('mandant-account'));
    },
    reset: function reset(controller) {
      controller.set('styleStepOne', '');
      controller.set('styleStepThree', 'hidden');
      controller.set('styleStepFour', 'hidden');
      controller.set('transactionDownloadUrl', null);
      controller.set('showBankTransferPanel', false);
      controller.set('transaction', null);
      controller.set('notice', null);
      controller.set('reason', null);
      controller.set('amount', null);
      controller.set('tax20', 0);
      controller.set('tax19', 0);
      controller.set('tax13', 0);
      controller.set('tax10', 0);
      controller.set('tax7', 0);
      controller.set('tax5', 0);
      controller.set('tax0', 0);
      controller.set('referenceNumber', null);
      controller.set('account', null);
      controller.set('recieptNumber', null);
      controller.set('recieptText', null);
      controller.set('recieptNotice', null);
      controller.set('supplier', null);
      controller.set('referenceText', null);
      controller.set('recipient', null);
      controller.set('purpose', null);
      controller.set('submitButtonEnabled', true);
      controller.set('documentDate', moment['default']().format("DD. MM. YYYY"));
      controller.set('selectedPaymentType', 'cash');
    },
    actions: {
      download: function download(url) {
        window.open(url);
      },
      print: function print(transactionGroup) {
        $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      showSlip: function showSlip() {
        this.get('controller').get('pdfPreview').send('show');
      },
      selectType: function selectType() {
        var controller = this.get('controller');
        controller.set('styleStepOne', 'hidden');
        controller.set('styleStepThree', '');
        controller.set('bankingAccountList', this.store.find('banking-account'));
      },
      selectAmount: function selectAmount() {
        var controller = this.get('controller');
        this.get('controller').set('styleStepOne', 'hidden');
        this.get('controller').set('styleStepThree', '');
        this.get('controller').set('styleStepFour', 'hidden');
      },
      toSelectType: function toSelectType() {
        var controller = this.get('controller');
        controller.set('styleStepOne', '');
        controller.set('styleStepThree', 'hidden');
        controller.set('styleStepFour', 'hidden');
      },
      reset: function reset() {
        this.reset(this.get('controller'));
      },
      doPayment: function doPayment() {
        var controller = this.get('controller'),
            paymentType = controller.get('selectedPaymentType'),
            reason = controller.get('reason'),
            amount = controller.get('amount'),
            documentDate = controller.get('documentDate'),
            invoiceId = controller.get('selectedInvoiceId'),
            supplier = controller.get('supplier'),
            recieptNumber = controller.get('recieptNumber'),
            recieptText = controller.get('recieptText'),
            notice = controller.get('notice'),
            account = controller.get('account'),
            recieptDate = controller.get('recieptDate'),
            recieptNotice = controller.get('recieptNotice'),
            selectedType = controller.get('selectedType'),
            referenceNumber = controller.get('referenceNumber'),
            referenceText = controller.get('referenceText'),
            recipient = controller.get('recipient'),
            bankingAccount = controller.get('bankingAccount'),
            purpose = controller.get('purpose'),
            selectedCashRegister = controller.get('selectedCashRegister');

        var self = this;

        controller.set('submitButtonEnabled', false);

        if (selectedCashRegister == null && API['default'].getMandant() != 10001 && API['default'].getMandant() != 10003) {
          jason.notifiction.error('Keine Kassa gefunden', 'Für die Buchung ist die Auswahl einer Kassa notwendig');
          return;
        }

        if (selectedCashRegister) {
          selectedCashRegister = selectedCashRegister.id;
        }

        var taxMap = Object();
        taxMap.tax20 = String(controller.get('tax20')).replace(',', '.');
        taxMap.tax19 = String(controller.get('tax19')).replace(',', '.');
        taxMap.tax13 = String(controller.get('tax13')).replace(',', '.');
        taxMap.tax10 = String(controller.get('tax10')).replace(',', '.');
        taxMap.tax7 = String(controller.get('tax7')).replace(',', '.');
        taxMap.tax5 = String(controller.get('tax5')).replace(',', '.');
        taxMap.tax0 = String(controller.get('tax0')).replace(',', '.');

        if ($('input[name="setPaymentType"]:checked').val() === 'banktransfer') {
          paymentType = 'banktransfer';
        }

        var payload = {
          paymentType: paymentType,
          amount: parseFloat(taxMap.tax20) + parseFloat(taxMap.tax19) + parseFloat(taxMap.tax13) + parseFloat(taxMap.tax10) + parseFloat(taxMap.tax7) + parseFloat(taxMap.tax0) + parseFloat(taxMap.tax5),
          taxInfos: taxMap,
          selectedCashRegister: selectedCashRegister,
          reason: reason,
          account: account,
          documentDate: moment['default'](documentDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          invoiceId: invoiceId,
          supplier: supplier,
          recieptNumber: recieptNumber,
          recieptText: recieptText,
          recieptDate: moment['default'](recieptDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          recieptNotice: recieptNotice,
          referenceNumber: referenceNumber,
          referenceText: referenceText,
          recipient: recipient,
          purpose: purpose,
          bankingAccount: bankingAccount,
          notice: notice,
          type: 'reciept'
        };

        if (payload.amount < 0.01) {
          jason.notifiction.error('Betrag fehlerhaft', 'Mindestbetrag von € 0,01 erforderlich');
          return;
        }

        var self = this;

        $.ajax({
          url: "/api/cashdesk/out",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('styleStepOne', 'hidden');
          controller.set('styleStepThree', 'hidden');
          controller.set('styleStepFour', '');

          controller.set('currentTransactionId', data);

          self.store.fetch('transaction-group', controller.get('currentTransactionId')).then(function (data2) {
            controller.set('transactionGroup', data2);
            controller.set('transactionDownloadUrl', '/api/salesSlip/' + data2.get('token'));
          });

          jason.notifiction.notifiy('Transaktion gebucht', 'Betrag: EUR ' + amount);
          controller.set('submitButtonEnabled', true);
          return data;
        }, function (error) {

          controller.set('styleStepOne', 'hidden');
          controller.set('styleStepThree', 'hidden');
          controller.set('styleStepFour', '');

          controller.set('transaction', self.store.find('transaction-group', data));
          controller.set('transactionDownloadUrl', '/api/salesSlip/' + data);
          controller.set('submitButtonEnabled', true);
          return { status: error.statusText, message: error.responseText };
        });
      }
    }
  });

});