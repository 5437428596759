define('jason-frontend/components/add-template-instance-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm() {
        this.sendAction('confirm', this.get('entry'), this.get('selectedTemplateId'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(entry) {
        var self = this;
        this.set('entry', entry);
        $.ajax({
          url: "/api/templates?per_page=50",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('templates', data.template);
          self.set('selectedTemplateId', data.template[0].id);
        }, function () {});
      }
    }
  });

});