define('jason-frontend/templates/components/patient-chip-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 16
                },
                "end": {
                  "line": 16,
                  "column": 86
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","small block mt5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","chipStatus.animalDataNotice",["loc",[null,[16,48],[16,79]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 14
              },
              "end": {
                "line": 17,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element12, 'class');
            morphs[1] = dom.createMorphAt(element12,0,0);
            morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["info-badge info-badge-small ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","chipStatus.animalDataStatus",["loc",[null,[14,83],[14,110]]]],"Registriert"],[],["loc",[null,[14,79],[14,125]]]],"label-success","label-default"],[],["loc",[null,[14,58],[14,160]]]]]]],
            ["content","chipStatus.animalDataStatus",["loc",[null,[14,162],[14,193]]]],
            ["block","if",[["get","chipStatus.animalDataNotice",["loc",[null,[15,22],[15,49]]]]],[],0,null,["loc",[null,[15,16],[16,93]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 14
              },
              "end": {
                "line": 19,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small label-default");
            var el2 = dom.createTextNode("Noch nicht registriert");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 16
                },
                "end": {
                  "line": 25,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","context button ml10");
              var el2 = dom.createTextNode("Chip registrieren");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element11);
              return morphs;
            },
            statements: [
              ["element","action",["registerAnimalData"],[],["loc",[null,[24,54],[24,85]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 16
                },
                "end": {
                  "line": 28,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","small is-highlighted");
              dom.setAttribute(el1,"target","_blank");
              var el2 = dom.createTextNode("Formular downloaden");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element10, 'href');
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",[["get","chipStatus.animalDataLink",["loc",[null,[27,57],[27,82]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 14
              },
              "end": {
                "line": 29,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["subexpr","eq",[["get","chipStatus.animalDataStatus",["loc",[null,[23,30],[23,57]]]],"Registriert"],[],["loc",[null,[23,26],[23,72]]]]],[],0,null,["loc",[null,[23,16],[25,27]]]],
            ["block","if",[["subexpr","and",[["subexpr","eq",[["get","chipStatus.animalDataStatus",["loc",[null,[26,31],[26,58]]]],"Registriert"],[],["loc",[null,[26,27],[26,73]]]],["get","chipStatus.animalDataLink",["loc",[null,[26,74],[26,99]]]]],[],["loc",[null,[26,22],[26,100]]]]],[],1,null,["loc",[null,[26,16],[28,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 14
              },
              "end": {
                "line": 31,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode("Bitte hinterlegen Sie Ihre Zugangsdaten unter Stammdaten / Meine Einstellungen / Praxis.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 16
                },
                "end": {
                  "line": 43,
                  "column": 83
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","small block mt5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","chipStatus.petCardNotice",["loc",[null,[43,48],[43,76]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 14
              },
              "end": {
                "line": 44,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element9, 'class');
            morphs[1] = dom.createMorphAt(element9,0,0);
            morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["info-badge info-badge-small ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","chipStatus.petCardStatus",["loc",[null,[41,83],[41,107]]]],"Registriert"],[],["loc",[null,[41,79],[41,122]]]],"label-success","label-default"],[],["loc",[null,[41,58],[41,157]]]]]]],
            ["content","chipStatus.petCardStatus",["loc",[null,[41,159],[41,187]]]],
            ["block","if",[["get","chipStatus.petCardNotice",["loc",[null,[42,22],[42,46]]]]],[],0,null,["loc",[null,[42,16],[43,90]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 14
              },
              "end": {
                "line": 46,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small label-default");
            var el2 = dom.createTextNode("Noch nicht registriert");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 16
                },
                "end": {
                  "line": 52,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","context button ml10");
              var el2 = dom.createTextNode("Chip registrieren");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element8);
              return morphs;
            },
            statements: [
              ["element","action",["registerPetCard"],[],["loc",[null,[51,54],[51,82]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 14
              },
              "end": {
                "line": 53,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["subexpr","eq",[["get","chipStatus.petCardStatus",["loc",[null,[50,30],[50,54]]]],"Registriert"],[],["loc",[null,[50,26],[50,69]]]]],[],0,null,["loc",[null,[50,16],[52,27]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 14
              },
              "end": {
                "line": 55,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode("Bitte hinterlegen Sie Ihre Zugangsdaten unter Stammdaten / Meine Einstellungen / Praxis.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 8
            },
            "end": {
              "line": 60,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","d-flex align-items-center");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-8/24");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/logos/animaldata.png");
          dom.setAttribute(el3,"class","mh-50");
          dom.setAttribute(el3,"style","height: 40px;margin-bottom: 5px;");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-8/24 text-center");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-8/24 text-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","d-flex align-items-center mt20");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-8/24");
          dom.setAttribute(el2,"style","padding-left: 40px;");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/logos/petcard.png");
          dom.setAttribute(el3,"class","mh-50");
          dom.setAttribute(el3,"style","height: 60px;margin-bottom: 5px;");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-8/24 text-center");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-8/24 text-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var element14 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element13, [5]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element14, [5]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","chipStatus.animalDataStatus",["loc",[null,[13,20],[13,47]]]]],[],0,1,["loc",[null,[13,14],[19,21]]]],
          ["block","if",[["get","chipStatus.animalDataAvailable",["loc",[null,[22,20],[22,50]]]]],[],2,3,["loc",[null,[22,14],[31,21]]]],
          ["block","if",[["get","chipStatus.petCardStatus",["loc",[null,[40,20],[40,44]]]]],[],4,5,["loc",[null,[40,14],[46,21]]]],
          ["block","if",[["get","chipStatus.petCardAvailable",["loc",[null,[49,20],[49,47]]]]],[],6,7,["loc",[null,[49,14],[55,21]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 8
            },
            "end": {
              "line": 261,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/logos/animaldata.png");
          dom.setAttribute(el1,"class","mh-100");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","mt20 mb10");
          var el2 = dom.createTextNode("Bitte überprüfen und vervollständigen Sie die nachfolgenden Daten");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mb20");
          var el2 = dom.createTextNode("Tierhalter:in");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","customerGender");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("Anrede");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","customerGender");
          dom.setAttribute(el4,"class","field select");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5,"class","arrow");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-title");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Titel");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-firstname");
          dom.setAttribute(el3,"class"," mt20 field-label title");
          var el4 = dom.createTextNode("Vorname");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-lastname");
          dom.setAttribute(el3,"class"," mt20 field-label title");
          var el4 = dom.createTextNode("Nachname");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mt20 l-24/24 mt10  gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-street");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Adresse");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24  mt10 gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-postal");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("PLZ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-town");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Stadt/Ort");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-customerBirthday");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Geburtsdatum");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","small pl5");
          var el4 = dom.createTextNode("Format: tt.mm.jjjj");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-phone1");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Telefon");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-phone2");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Telefon 2");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-email");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Email");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mb20");
          var el2 = dom.createTextNode("Tier");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientName");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Chip-Nr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","mb20");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientName");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Name");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientGender");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Geschlecht");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","arrow");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("Kastriert");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","header-toggle pt10");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","switchCastratedPatient");
          dom.setAttribute(el5,"class","switch");
          dom.setAttribute(el5,"style","height: 30px;margin-bottom:0");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalCategory");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Spezies");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalRace");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Rasse");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientColor");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Farbe");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalPass");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Heimtierausweis Nr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalBirthday");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Geburtstag/Alter");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","small pl5");
          var el4 = dom.createTextNode("Format: tt.mm.jjjj, mm.jjjj, jjjj");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalNotice");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Wichtiger Hinweis");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 mt20 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("Amtliche Registrierung ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","small");
          var el6 = dom.createTextNode("(Verpflichtend bei Hunden und Zuchtkatzen)");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","header-toggle pt10");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","officialOrder");
          dom.setAttribute(el5,"class","switch");
          dom.setAttribute(el5,"style","height: 30px;margin-bottom:0");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","ownerDocumentType");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Lichtbildausweis");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","ownerDocumentId");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Ausweis-Nr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalCountry");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Herkunftsland Tier");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","small pl5");
          var el4 = dom.createTextNode("AT, DE, CH, SK, CZ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalStartdate");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Haltungsbeginn Tier ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","small pl5");
          var el4 = dom.createTextNode("Format: tt.mm.jjjj");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mb20");
          var el2 = dom.createTextNode("Allgemeine Angaben");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","password");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Passwort (max. 10 Zeichen)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("PetCard Bestellung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","header-toggle pt10");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","orderCard");
          dom.setAttribute(el5,"class","switch");
          dom.setAttribute(el5,"style","height: 30px;margin-bottom:0");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [7]);
          var element5 = dom.childAt(fragment, [11]);
          var element6 = dom.childAt(fragment, [13]);
          var element7 = dom.childAt(fragment, [17]);
          var morphs = new Array(29);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [5]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element4, [7]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element4, [9]),3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [11]),3,3);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [13]),3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element4, [15]),3,3);
          morphs[8] = dom.createMorphAt(dom.childAt(element4, [17]),3,3);
          morphs[9] = dom.createMorphAt(dom.childAt(element4, [19]),3,3);
          morphs[10] = dom.createMorphAt(dom.childAt(element4, [21]),3,3);
          morphs[11] = dom.createMorphAt(dom.childAt(element5, [1, 3]),0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element5, [3]),3,3);
          morphs[13] = dom.createMorphAt(dom.childAt(element5, [5]),3,3);
          morphs[14] = dom.createMorphAt(dom.childAt(element5, [7, 1, 3]),1,1);
          morphs[15] = dom.createMorphAt(dom.childAt(element5, [9]),3,3);
          morphs[16] = dom.createMorphAt(dom.childAt(element5, [11]),3,3);
          morphs[17] = dom.createMorphAt(dom.childAt(element5, [13]),3,3);
          morphs[18] = dom.createMorphAt(dom.childAt(element5, [15]),3,3);
          morphs[19] = dom.createMorphAt(dom.childAt(element5, [17]),3,3);
          morphs[20] = dom.createMorphAt(dom.childAt(element5, [19]),3,3);
          morphs[21] = dom.createMorphAt(dom.childAt(element5, [21, 1, 3]),1,1);
          morphs[22] = dom.createAttrMorph(element6, 'class');
          morphs[23] = dom.createMorphAt(dom.childAt(element6, [1]),3,3);
          morphs[24] = dom.createMorphAt(dom.childAt(element6, [3]),3,3);
          morphs[25] = dom.createMorphAt(dom.childAt(element6, [5]),3,3);
          morphs[26] = dom.createMorphAt(dom.childAt(element6, [7]),3,3);
          morphs[27] = dom.createMorphAt(dom.childAt(element7, [1]),3,3);
          morphs[28] = dom.createMorphAt(dom.childAt(element7, [3, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","customerGenders",["loc",[null,[75,28],[75,43]]]]],[],[]],"value",["subexpr","@mut",[["get","model.customerGender",["loc",[null,[76,26],[76,46]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","optionValuePath","id","allowClear",false,"name","customerGender","placeholder","Bitte wählen"],["loc",[null,[74,18],[83,20]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerTitle",["loc",[null,[90,28],[90,47]]]]],[],[]],"class","gui-input","name","customerTitle","autocomplete","off"],["loc",[null,[90,14],[90,107]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerFirstname",["loc",[null,[94,28],[94,51]]]]],[],[]],"class","gui-input","name","firstname","autocomplete","off"],["loc",[null,[94,14],[94,107]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerLastname",["loc",[null,[98,28],[98,50]]]]],[],[]],"class","gui-input","name","lastname","autocomplete","off"],["loc",[null,[98,14],[98,105]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerStreet",["loc",[null,[102,28],[102,48]]]]],[],[]],"class","gui-input","name","street","autocomplete","off"],["loc",[null,[102,14],[102,101]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerPostalCode",["loc",[null,[106,28],[106,52]]]]],[],[]],"class","gui-input","name","postalCode","autocomplete","off"],["loc",[null,[106,14],[106,109]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerTown",["loc",[null,[110,28],[110,46]]]]],[],[]],"class","gui-input","name","town","autocomplete","off"],["loc",[null,[110,14],[110,97]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerBirthday",["loc",[null,[114,28],[114,50]]]]],[],[]],"class","gui-input","name","customerBirthday","autocomplete","off"],["loc",[null,[114,14],[114,113]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerPhone",["loc",[null,[119,28],[119,47]]]]],[],[]],"class","gui-input","name","phone1","autocomplete","off"],["loc",[null,[119,14],[119,100]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerPhone2",["loc",[null,[123,28],[123,48]]]]],[],[]],"class","gui-input","name","phone2","autocomplete","off"],["loc",[null,[123,14],[123,101]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerEmail",["loc",[null,[127,28],[127,47]]]]],[],[]],"class","gui-input","name","email","autocomplete","off"],["loc",[null,[127,14],[127,99]]]],
          ["content","patient.chipId",["loc",[null,[136,33],[136,51]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalName",["loc",[null,[140,28],[140,44]]]]],[],[]],"name","patientName","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[140,14],[141,38]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","genders",["loc",[null,[146,24],[146,31]]]]],[],[]],"value",["subexpr","@mut",[["get","model.animalGender",["loc",[null,[147,22],[147,40]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","allowClear",false,"name","patientGender","placeholder","wählen"],["loc",[null,[145,14],[153,16]]]],
          ["inline","input",[],["type","checkbox","id","switchCastratedPatient","classNames","switch","name","switchCastratedPatient","checked",["subexpr","@mut",[["get","model.animalCastrated",["loc",[null,[161,34],[161,55]]]]],[],[]]],["loc",[null,[160,18],[161,57]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalCategory",["loc",[null,[169,28],[169,48]]]]],[],[]],"name","animalCategory","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[169,14],[170,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalRace",["loc",[null,[174,28],[174,44]]]]],[],[]],"name","animalRace","required",true,"class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[174,14],[175,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalColor",["loc",[null,[179,28],[179,45]]]]],[],[]],"name","patientName","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[179,14],[180,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalPass",["loc",[null,[184,28],[184,44]]]]],[],[]],"name","animalPass","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[184,14],[185,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalBirthday",["loc",[null,[189,28],[189,48]]]]],[],[]],"name","animalBirthday","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[189,14],[190,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalNotice",["loc",[null,[196,28],[196,46]]]]],[],[]],"name","patientName","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[196,14],[197,38]]]],
          ["inline","input",[],["type","checkbox","id","officialOrder","classNames","switch","name","officialOrder","checked",["subexpr","@mut",[["get","model.officialOrder",["loc",[null,[205,34],[205,53]]]]],[],[]]],["loc",[null,[204,18],[205,55]]]],
          ["attribute","class",["concat",["grid ",["subexpr","css-bool-evaluator",[["get","model.officialOrder",["loc",[null,[212,48],[212,67]]]],"","hidden"],[],["loc",[null,[212,27],[212,81]]]]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.ownerDocumentType",["loc",[null,[215,28],[215,51]]]]],[],[]],"name","ownerDocumentType","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[215,14],[216,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.ownerDocumentId",["loc",[null,[220,28],[220,49]]]]],[],[]],"name","ownerDocumentId","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[220,14],[221,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalCountry",["loc",[null,[226,28],[226,47]]]]],[],[]],"name","animalCountry","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[226,14],[227,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalStartdate",["loc",[null,[232,28],[232,49]]]]],[],[]],"name","animalStartdate","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[232,14],[233,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.password",["loc",[null,[245,26],[245,40]]]]],[],[]],"name","password","maxlength",10,"class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[245,12],[246,36]]]],
          ["inline","input",[],["type","checkbox","id","orderCard","classNames","switch","name","orderCard","checked",["subexpr","@mut",[["get","model.orderCard",["loc",[null,[254,32],[254,47]]]]],[],[]]],["loc",[null,[253,16],[254,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 262,
              "column": 8
            },
            "end": {
              "line": 443,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/logos/petcard.png");
          dom.setAttribute(el1,"class","mh-100");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","mt20 mb10");
          var el2 = dom.createTextNode("Bitte überprüfen und vervollständigen Sie die nachfolgenden Daten");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mb20");
          var el2 = dom.createTextNode("Tierhalter:in");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","customerType");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("Tierhalter");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","customerGender");
          dom.setAttribute(el4,"class","field select");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5,"class","arrow");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt20 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","customerGender");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("Anrede");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","customerGender");
          dom.setAttribute(el4,"class","field select");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5,"class","arrow");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-title");
          dom.setAttribute(el3,"class"," mt20 field-label title");
          var el4 = dom.createTextNode("Titel");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-firstname");
          dom.setAttribute(el3,"class"," mt20 field-label title");
          var el4 = dom.createTextNode("Vorname");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-lastname");
          dom.setAttribute(el3,"class"," mt20 field-label title");
          var el4 = dom.createTextNode("Nachname");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mt20 l-24/24 mt10  gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-street");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Adresse");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24  mt10 gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-postal");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("PLZ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-town");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Stadt/Ort");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-phone1");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Telefon");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-phone2");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Telefon 2");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-email");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Email");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10  gutter mb10");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","form-email2");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Email 2");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mb20");
          var el2 = dom.createTextNode("Tier");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientName");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Chip-Nr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","mb20");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientName");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Name");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientGender");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Geschlecht");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","arrow");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("Kastriert");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","header-toggle pt10");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","switchCastratedPatient");
          dom.setAttribute(el5,"class","switch");
          dom.setAttribute(el5,"style","height: 30px;margin-bottom:0");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalCategory");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Spezies");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalRace");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Rasse");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patientColor");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Farbe");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalPass");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Heimtierausweis Nr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalBirthday");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Geburtstag/Alter");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","small pl5");
          var el4 = dom.createTextNode("Format: tt.mm.jjjj, mm.jjjj, jjjj");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","twiDate");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Tollwutimpfung Datum");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","small pl5");
          var el4 = dom.createTextNode("Format: tt.mm.jjjj");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","twiVaccination");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Tollwutimpfstoff");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 mt10 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","animalNotice");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Wichtiger Hinweis");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 mt10 gutter ");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","input--full-width");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title field-label");
          dom.setAttribute(el4,"style","color: #626262");
          var el5 = dom.createTextNode("Weiterleitung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","header-toggle pt10");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","forward");
          dom.setAttribute(el5,"class","switch");
          dom.setAttribute(el5,"style","height: 30px;margin-bottom:0");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [7]);
          var element3 = dom.childAt(fragment, [11]);
          var morphs = new Array(25);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 1, 3]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [7]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [9]),3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [11]),3,3);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [13]),3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element2, [15]),3,3);
          morphs[8] = dom.createMorphAt(dom.childAt(element2, [17]),3,3);
          morphs[9] = dom.createMorphAt(dom.childAt(element2, [19]),3,3);
          morphs[10] = dom.createMorphAt(dom.childAt(element2, [21]),3,3);
          morphs[11] = dom.createMorphAt(dom.childAt(element2, [23]),3,3);
          morphs[12] = dom.createMorphAt(dom.childAt(element3, [1, 3]),0,0);
          morphs[13] = dom.createMorphAt(dom.childAt(element3, [3]),3,3);
          morphs[14] = dom.createMorphAt(dom.childAt(element3, [5]),3,3);
          morphs[15] = dom.createMorphAt(dom.childAt(element3, [7, 1, 3]),1,1);
          morphs[16] = dom.createMorphAt(dom.childAt(element3, [9]),3,3);
          morphs[17] = dom.createMorphAt(dom.childAt(element3, [11]),3,3);
          morphs[18] = dom.createMorphAt(dom.childAt(element3, [13]),3,3);
          morphs[19] = dom.createMorphAt(dom.childAt(element3, [15]),3,3);
          morphs[20] = dom.createMorphAt(dom.childAt(element3, [17]),3,3);
          morphs[21] = dom.createMorphAt(dom.childAt(element3, [19]),3,3);
          morphs[22] = dom.createMorphAt(dom.childAt(element3, [21]),3,3);
          morphs[23] = dom.createMorphAt(dom.childAt(element3, [23]),3,3);
          morphs[24] = dom.createMorphAt(dom.childAt(element3, [25, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","petcardTypes",["loc",[null,[276,24],[276,36]]]]],[],[]],"value",["subexpr","@mut",[["get","model.customerType",["loc",[null,[277,22],[277,40]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","optionValuePath","id","allowClear",false,"name","customerType","placeholder","wählen"],["loc",[null,[275,14],[284,16]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","customerGenders",["loc",[null,[294,28],[294,43]]]]],[],[]],"value",["subexpr","@mut",[["get","model.customerGender",["loc",[null,[295,26],[295,46]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","optionValuePath","id","allowClear",false,"name","customerGender","placeholder","Bitte wählen"],["loc",[null,[293,18],[302,20]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerTitle",["loc",[null,[309,28],[309,47]]]]],[],[]],"class","gui-input","name","customerTitle","autocomplete","off"],["loc",[null,[309,14],[309,107]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerFirstname",["loc",[null,[313,28],[313,51]]]]],[],[]],"class","gui-input","name","firstname","autocomplete","off"],["loc",[null,[313,14],[313,107]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerLastname",["loc",[null,[317,28],[317,50]]]]],[],[]],"class","gui-input","name","lastname","autocomplete","off"],["loc",[null,[317,14],[317,105]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerStreet",["loc",[null,[321,28],[321,48]]]]],[],[]],"class","gui-input","name","street","autocomplete","off"],["loc",[null,[321,14],[321,101]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerPostalCode",["loc",[null,[325,28],[325,52]]]]],[],[]],"class","gui-input","name","postalCode","autocomplete","off"],["loc",[null,[325,14],[325,109]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerTown",["loc",[null,[329,28],[329,46]]]]],[],[]],"class","gui-input","name","town","autocomplete","off"],["loc",[null,[329,14],[329,97]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerPhone",["loc",[null,[334,28],[334,47]]]]],[],[]],"class","gui-input","name","phone1","autocomplete","off"],["loc",[null,[334,14],[334,100]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerPhone2",["loc",[null,[338,28],[338,48]]]]],[],[]],"class","gui-input","name","phone2","autocomplete","off"],["loc",[null,[338,14],[338,101]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerEmail",["loc",[null,[342,28],[342,47]]]]],[],[]],"class","gui-input","name","email","autocomplete","off"],["loc",[null,[342,14],[342,99]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.customerEmail2",["loc",[null,[346,28],[346,48]]]]],[],[]],"class","gui-input","name","email2","autocomplete","off"],["loc",[null,[346,14],[346,101]]]],
          ["content","patient.chipId",["loc",[null,[355,33],[355,51]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalName",["loc",[null,[359,28],[359,44]]]]],[],[]],"name","patientName","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[359,14],[360,38]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","genders",["loc",[null,[365,24],[365,31]]]]],[],[]],"value",["subexpr","@mut",[["get","model.animalGender",["loc",[null,[366,22],[366,40]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","allowClear",false,"name","patientGender","placeholder","wählen"],["loc",[null,[364,14],[372,16]]]],
          ["inline","input",[],["type","checkbox","id","switchCastratedPatient","classNames","switch","name","switchCastratedPatient","checked",["subexpr","@mut",[["get","model.animalCastrated",["loc",[null,[380,34],[380,55]]]]],[],[]]],["loc",[null,[379,18],[380,57]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalCategory",["loc",[null,[388,28],[388,48]]]]],[],[]],"name","animalCategory","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[388,14],[389,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalRace",["loc",[null,[393,28],[393,44]]]]],[],[]],"name","animalRace","required",true,"class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[393,14],[394,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalColor",["loc",[null,[398,28],[398,45]]]]],[],[]],"name","patientName","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[398,14],[399,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalPass",["loc",[null,[403,28],[403,44]]]]],[],[]],"name","animalPass","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[403,14],[404,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalBirthday",["loc",[null,[408,28],[408,48]]]]],[],[]],"name","animalBirthday","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[408,14],[409,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.twiDate",["loc",[null,[414,28],[414,41]]]]],[],[]],"name","twiDate","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[414,14],[415,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.twiVaccination",["loc",[null,[420,28],[420,48]]]]],[],[]],"name","twiVaccination","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[420,14],[421,38]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","model.animalNotice",["loc",[null,[426,28],[426,46]]]]],[],[]],"name","patientName","class","gui-input br-light light newStyle","placeholder",""],["loc",[null,[426,14],[427,38]]]],
          ["inline","input",[],["type","checkbox","id","forward","classNames","switch","name","forward","checked",["subexpr","@mut",[["get","model.forward",["loc",[null,[435,34],[435,47]]]]],[],[]]],["loc",[null,[434,18],[435,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 450,
              "column": 8
            },
            "end": {
              "line": 454,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["submitAnimalData"],[],["loc",[null,[451,18],[451,47]]]],
          ["inline","button-next",[],["color","#ffffff","size","50","content",""],["loc",[null,[452,12],[452,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 455,
              "column": 8
            },
            "end": {
              "line": 459,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["submitPetCardData"],[],["loc",[null,[456,18],[456,48]]]],
          ["inline","button-next",[],["color","#ffffff","size","50","content",""],["loc",[null,[457,12],[457,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 464,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/patient-chip-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-patient-chip-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("Chip-Registrierung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body p25");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-footer text-right");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","icon-button icon-button--small");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0, 1]);
        var element16 = dom.childAt(element15, [3]);
        var element17 = dom.childAt(element15, [5]);
        var element18 = dom.childAt(element17, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element16,1,1);
        morphs[1] = dom.createMorphAt(element16,2,2);
        morphs[2] = dom.createMorphAt(element16,3,3);
        morphs[3] = dom.createElementMorph(element18);
        morphs[4] = dom.createMorphAt(element18,1,1);
        morphs[5] = dom.createMorphAt(element17,3,3);
        morphs[6] = dom.createMorphAt(element17,4,4);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[7,18],[7,29]]]],"overview"],[],["loc",[null,[7,14],[7,41]]]]],[],0,null,["loc",[null,[7,8],[60,15]]]],
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[61,18],[61,29]]]],"registerAnimalData"],[],["loc",[null,[61,14],[61,51]]]]],[],1,null,["loc",[null,[61,8],[261,15]]]],
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[262,18],[262,29]]]],"registerPetCardData"],[],["loc",[null,[262,14],[262,52]]]]],[],2,null,["loc",[null,[262,8],[443,15]]]],
        ["element","action",["cancel"],[],["loc",[null,[447,50],[447,69]]]],
        ["content","button-prev",["loc",[null,[448,10],[448,25]]]],
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[450,18],[450,29]]]],"registerAnimalData"],[],["loc",[null,[450,14],[450,51]]]]],[],3,null,["loc",[null,[450,8],[454,15]]]],
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[455,18],[455,29]]]],"registerPetCardData"],[],["loc",[null,[455,14],[455,52]]]]],[],4,null,["loc",[null,[455,8],[459,15]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});