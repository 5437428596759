define('jason-frontend/templates/patients/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[4,30],[4,40]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[4,6],[4,96]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[5,31],[5,42]]]]],[],[]]],["loc",[null,[5,6],[5,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 10
              },
              "end": {
                "line": 25,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/chip.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Chip registrieren");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element104 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element104);
            return morphs;
          },
          statements: [
            ["element","action",["openChipPanel",["get","model",["loc",[null,[22,40],[22,45]]]]],[],["loc",[null,[22,15],[22,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 10
            },
            "end": {
              "line": 26,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/picture-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Tierbild ändern");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Notiz erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element105 = dom.childAt(fragment, [1]);
          var element106 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element105);
          morphs[1] = dom.createElementMorph(element106);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["showImagePanel"],[],["loc",[null,[15,13],[15,40]]]],
          ["element","action",["addNewMemo"],[],["loc",[null,[18,13],[18,36]]]],
          ["block","if",[["get","model.chipId",["loc",[null,[21,16],[21,28]]]]],[],0,null,["loc",[null,[21,10],[25,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 8
            },
            "end": {
              "line": 31,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/download-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Ordner herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element103 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element103);
          return morphs;
        },
        statements: [
          ["element","action",["downloadFolder"],[],["loc",[null,[28,13],[28,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 10
            },
            "end": {
              "line": 36,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/download-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Als CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element102 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element102);
          return morphs;
        },
        statements: [
          ["element","action",["downloadAppointmentCsv",["get","model.id",["loc",[null,[33,49],[33,57]]]]],[],["loc",[null,[33,15],[33,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 10
            },
            "end": {
              "line": 41,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/download-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Alle Laborwerte anzeigen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element101 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element101);
          return morphs;
        },
        statements: [
          ["element","action",["openAllLabParamsPanel",["get","model.id",["loc",[null,[38,49],[38,57]]]]],[],["loc",[null,[38,15],[38,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 10
            },
            "end": {
              "line": 52,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/notification-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erinnerung planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/appointment-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Termin planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/more-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Zukunft planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element98 = dom.childAt(fragment, [1]);
          var element99 = dom.childAt(fragment, [3]);
          var element100 = dom.childAt(fragment, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element98);
          morphs[1] = dom.createElementMorph(element99);
          morphs[2] = dom.createElementMorph(element100);
          return morphs;
        },
        statements: [
          ["element","action",["openReminderPanel"],[],["loc",[null,[43,15],[43,45]]]],
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[46,15],[46,48]]]],
          ["element","action",["addFutureAction",["get","model.id",["loc",[null,[49,42],[49,50]]]]],[],["loc",[null,[49,15],[49,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 6
            },
            "end": {
              "line": 63,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element97 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element97);
          morphs[1] = dom.createMorphAt(element97,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openDocumentUpload"],[],["loc",[null,[59,11],[59,42]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[60,10],[60,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 6
            },
            "end": {
              "line": 68,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Aufgabe");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element96 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element96);
          return morphs;
        },
        statements: [
          ["element","action",["openNew"],[],["loc",[null,[65,11],[65,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 6
            },
            "end": {
              "line": 72,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element95 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element95);
          return morphs;
        },
        statements: [
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[70,11],[70,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 73,
              "column": 6
            },
            "end": {
              "line": 76,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Historie");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element94 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element94);
          morphs[1] = dom.createMorphAt(element94,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["openLogbook"],[],["loc",[null,[74,11],[74,35]]]],
          ["inline","button-history",[],["color","#fff","size","30","showStroke",false,"content",""],["loc",[null,[74,69],[74,138]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 6
            },
            "end": {
              "line": 81,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element93 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element93);
          morphs[1] = dom.createMorphAt(element93,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["downloadHistoryCsv",["get","model.id",["loc",[null,[78,41],[78,49]]]]],[],["loc",[null,[78,11],[78,51]]]],
          ["inline","button-download2",[],["color","#fff","size","16","content",""],["loc",[null,[79,10],[79,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 6
            },
            "end": {
              "line": 86,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Nachricht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element92 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element92);
          morphs[1] = dom.createMorphAt(element92,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openDirectMessage"],[],["loc",[null,[83,11],[83,41]]]],
          ["inline","button-chat",[],["content","","color","#ffffff","size","32","showStroke",false],["loc",[null,[84,10],[84,79]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 101,
              "column": 14
            },
            "end": {
              "line": 105,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-activities");
          var el3 = dom.createTextNode("Journal");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element91 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element91, 'class');
          morphs[1] = dom.createElementMorph(element91);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[103,86],[103,95]]]],"activities"],[],["loc",[null,[103,82],[103,109]]]],"is-active",""],[],["loc",[null,[103,61],[103,126]]]]]]],
          ["element","action",["selectTab","activities"],[],["loc",[null,[103,151],[103,186]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 14
            },
            "end": {
              "line": 110,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-history");
          var el3 = dom.createTextNode("Historie");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element90 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element90, 'class');
          morphs[1] = dom.createElementMorph(element90);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[108,88],[108,97]]]],"history"],[],["loc",[null,[108,84],[108,108]]]],"is-active",""],[],["loc",[null,[108,63],[108,125]]]]]]],
          ["element","action",["selectTab","history"],[],["loc",[null,[108,147],[108,179]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 120,
              "column": 16
            },
            "end": {
              "line": 124,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-correspondance");
          var el3 = dom.createTextNode("Korrespondenz");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element89 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element89, 'class');
          morphs[1] = dom.createElementMorph(element89);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[122,90],[122,99]]]],"correspondance"],[],["loc",[null,[122,86],[122,117]]]],"is-active",""],[],["loc",[null,[122,65],[122,134]]]]]]],
          ["element","action",["selectTab","correspondance"],[],["loc",[null,[122,163],[122,202]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 128,
              "column": 14
            },
            "end": {
              "line": 132,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-todo");
          var el3 = dom.createTextNode("TO-DO");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element88 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element88, 'class');
          morphs[1] = dom.createElementMorph(element88);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[130,86],[130,95]]]],"todo"],[],["loc",[null,[130,82],[130,103]]]],"is-active",""],[],["loc",[null,[130,61],[130,120]]]]]]],
          ["element","action",["selectTab","todo"],[],["loc",[null,[130,139],[130,168]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 133,
              "column": 14
            },
            "end": {
              "line": 137,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-appointments");
          var el3 = dom.createTextNode("Termine");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element87 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element87, 'class');
          morphs[1] = dom.createElementMorph(element87);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[135,88],[135,97]]]],"appointments"],[],["loc",[null,[135,84],[135,113]]]],"is-active",""],[],["loc",[null,[135,63],[135,130]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[135,157],[135,194]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 147,
              "column": 20
            },
            "end": {
              "line": 150,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Pferd");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","patients-edit-additional-form",[],["etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[149,73],[149,89]]]]],[],[]],"edit",true,"openLogbook","openEtigaMasterdataLogbook","model",["subexpr","@mut",[["get","model",["loc",[null,[149,147],[149,152]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[149,162],[149,170]]]]],[],[]]],["loc",[null,[149,24],[149,172]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 154,
              "column": 16
            },
            "end": {
              "line": 156,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Gewichtsentwicklung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 264,
                "column": 20
              },
              "end": {
                "line": 264,
                "column": 240
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element86 = dom.childAt(fragment, [0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element86, 'class');
            morphs[1] = dom.createAttrMorph(element86, 'style');
            morphs[2] = dom.createAttrMorph(element86, 'data-id');
            morphs[3] = dom.createElementMorph(element86);
            morphs[4] = dom.createMorphAt(element86,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[264,96],[264,108]]]],"selected",""],[],["loc",[null,[264,75],[264,124]]]]]]],
            ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[264,153],[264,162]]]]]]],
            ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[264,177],[264,183]]]]]]],
            ["element","action",["selectSearchTag",["get","tag",["loc",[null,[264,214],[264,217]]]]],[],["loc",[null,[264,187],[264,219]]]],
            ["content","tag.name",["loc",[null,[264,220],[264,232]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 261,
              "column": 16
            },
            "end": {
              "line": 266,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter box");
          dom.setAttribute(el1,"id","tagFilters");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Tags");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","tags",["loc",[null,[264,28],[264,32]]]]],[],0,null,["loc",[null,[264,20],[264,249]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 317,
                "column": 22
              },
              "end": {
                "line": 319,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        Alle ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" um ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Uhr, ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Wiederholungen\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            return morphs;
          },
          statements: [
            ["content","item.dueTimeUnitInterval",["loc",[null,[318,29],[318,57]]]],
            ["content","item.dueTimeUnit.name",["loc",[null,[318,58],[318,83]]]],
            ["content","item.time",["loc",[null,[318,87],[318,100]]]],
            ["content","item.dueTimeUnitIterations",["loc",[null,[318,106],[318,136]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 319,
                "column": 22
              },
              "end": {
                "line": 321,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.dueDate",["loc",[null,[320,38],[320,50]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[320,24],[320,73]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 328,
                "column": 28
              },
              "end": {
                "line": 328,
                "column": 102
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge success");
            var el2 = dom.createTextNode("erledigt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 328,
                "column": 102
              },
              "end": {
                "line": 328,
                "column": 149
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge");
            var el2 = dom.createTextNode("offen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 313,
              "column": 20
            },
            "end": {
              "line": 340,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-5/24@desk");
          dom.setAttribute(el2,"data-label","Fälligkeit");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-10/24@desk");
          dom.setAttribute(el2,"data-label","Titel");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell buttons-2 table__cell--action l-6/24 l-5/24@desk buttons-2");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square exp");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("Bearbeiten");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element82 = dom.childAt(fragment, [1]);
          var element83 = dom.childAt(element82, [7]);
          var element84 = dom.childAt(element83, [1]);
          var element85 = dom.childAt(element83, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element82, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element82, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element82, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(element84,1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element84, [3]),0,0);
          morphs[5] = dom.createElementMorph(element85);
          morphs[6] = dom.createMorphAt(element85,1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","item.repeat",["loc",[null,[317,32],[317,43]]]],"repeat"],[],["loc",[null,[317,28],[317,53]]]]],[],0,1,["loc",[null,[317,22],[321,29]]]],
          ["content","item.name",["loc",[null,[325,32],[325,45]]]],
          ["block","if",[["get","item.checkedDate",["loc",[null,[328,34],[328,50]]]]],[],2,3,["loc",[null,[328,28],[328,156]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[332,106],[332,110]]]]],[],[]],"confirmed","deleteTodo","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[332,28],[332,186]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[333,59],[333,63]]]]],[],[]],"confirmed","deleteTodo","textToShow","Löschen","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[333,34],[333,160]]]],
          ["element","action",["editTodo",["get","item",["loc",[null,[335,49],[335,53]]]]],[],["loc",[null,[335,29],[335,55]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[336,28],[336,94]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 367,
              "column": 14
            },
            "end": {
              "line": 384,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","customer-etiga",[],["patient",["subexpr","@mut",[["get","model",["loc",[null,[368,39],[368,44]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[369,48],[369,64]]]]],[],[]],"activateEtiga","activateEtiga","uploadDeclaration","uploadDeclaration","removeShare","removeEtigaShare","sendEtigaSave","sendEtigaSave","downloadEtigaQr","downloadEtigaQr","hasEtigaPermissions",["subexpr","has-permission",["etiga"],[],["loc",[null,[375,51],[375,75]]]],"addEtigaShare","addEtigaShare","disconnectEtiga","disconnectEtiga","selectDocs","selectDocs","submitCredentials","sendCredentials","savePermission","saveEtigaPermission","editEtigaPermissions","editEtigaPermissions","classNames","etigaPermissionPanel patients","actionReceiver",["subexpr","@mut",[["get","etigaPanel",["loc",[null,[383,46],[383,56]]]]],[],[]]],["loc",[null,[368,14],[383,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child22 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 384,
              "column": 14
            },
            "end": {
              "line": 386,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                Derzeit in Arbeit\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child23 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 395,
              "column": 6
            },
            "end": {
              "line": 399,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element81 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element81);
          morphs[1] = dom.createMorphAt(element81,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[396,13],[396,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","80","content","Speichern"],["loc",[null,[397,12],[397,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 400,
              "column": 6
            },
            "end": {
              "line": 404,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element80 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element80);
          morphs[1] = dom.createMorphAt(element80,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["sendEtigaSave"],[],["loc",[null,[401,11],[401,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","80","content","Freigaben speichern"],["loc",[null,[402,10],[402,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 405,
              "column": 6
            },
            "end": {
              "line": 412,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element78 = dom.childAt(fragment, [1]);
          var element79 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element78);
          morphs[1] = dom.createMorphAt(element78,1,1);
          morphs[2] = dom.createElementMorph(element79);
          morphs[3] = dom.createMorphAt(element79,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["downloadWeightCsv",["get","model.id",["loc",[null,[406,40],[406,48]]]]],[],["loc",[null,[406,11],[406,50]]]],
          ["inline","button-download-csv",[],["color","#ffffff","size","40","content","Gewichtsverlauf als CSV herunterladen"],["loc",[null,[407,10],[407,107]]]],
          ["element","action",["openWeight",null,["get","model.id",["loc",[null,[409,38],[409,46]]]]],[],["loc",[null,[409,11],[409,48]]]],
          ["inline","button-add-entry",[],["content","Neues Gewicht eintragen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[410,10],[410,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 413,
              "column": 6
            },
            "end": {
              "line": 417,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element77 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element77);
          morphs[1] = dom.createMorphAt(element77,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[414,13],[414,40]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[415,12],[415,107]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 431,
                "column": 12
              },
              "end": {
                "line": 434,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["useFilter",false,"classNames","inline-block vertical-middle","color","#fff","inverted",["subexpr","eq",["female",["get","model.gender.id",["loc",[null,[432,121],[432,136]]]]],[],["loc",[null,[432,108],[432,137]]]],"animal-id",["subexpr","@mut",[["get","model.category.id",["loc",[null,[433,38],[433,55]]]]],[],[]],"race-id",["subexpr","@mut",[["get","model.digitalRace.icon",["loc",[null,[433,64],[433,86]]]]],[],[]],"size","60","content",""],["loc",[null,[432,14],[433,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 434,
                "column": 12
              },
              "end": {
                "line": 436,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
            dom.setAttribute(el1,"style","width: 55px;padding-top: 11px;padding-left: 6px;");
            dom.setAttribute(el1,"class","inline-block vertical-middle");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 440,
                "column": 12
              },
              "end": {
                "line": 440,
                "column": 95
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","dead-icon");
            dom.setAttribute(el1,"src","assets/images/icons/died-black.svg");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 441,
                "column": 55
              },
              "end": {
                "line": 441,
                "column": 108
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("- ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.digitalRace.name",["loc",[null,[441,82],[441,108]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 441,
                "column": 108
              },
              "end": {
                "line": 441,
                "column": 132
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("- ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.race",["loc",[null,[441,118],[441,132]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 442,
                "column": 8
              },
              "end": {
                "line": 442,
                "column": 71
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","subName small");
            var el2 = dom.createTextNode("Freigänger:in");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 445,
                  "column": 10
                },
                "end": {
                  "line": 447,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","cursor info-badge");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/sun.svg");
              dom.setAttribute(el2,"style","width: 20px");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 447,
                  "column": 10
                },
                "end": {
                  "line": 449,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","cursor info-badge");
              var el2 = dom.createTextNode("CAVE");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 443,
                "column": 8
              },
              "end": {
                "line": 451,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","caveLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["subexpr","get-current-mandant-id",[],[],["loc",[null,[445,20],[445,44]]]],"153"],[],["loc",[null,[445,16],[445,51]]]]],[],0,1,["loc",[null,[445,10],[449,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 454,
                "column": 10
              },
              "end": {
                "line": 458,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","insured");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","textBelow");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/insured.svg");
            dom.setAttribute(el3,"style","width: 20px");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Versicherung");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 458,
                "column": 10
              },
              "end": {
                "line": 462,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","not-insured");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","textBelow");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/no.svg");
            dom.setAttribute(el3,"style","width: 20px;padding-bottom: 3px");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Versicherung");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 467,
                  "column": 12
                },
                "end": {
                  "line": 469,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("               ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element18);
              return morphs;
            },
            statements: [
              ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[468,86],[468,91]]]]],[],["loc",[null,[468,55],[468,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 469,
                  "column": 12
                },
                "end": {
                  "line": 471,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","etigaicon-with-confirm",[],["small",true,"color","00AAC6","item",["subexpr","@mut",[["get","model",["loc",[null,[470,72],[470,77]]]]],[],[]],"confirmed","activateEtiga","textToShow","etiga","text","eltiga für Tier aktivieren?","classNames","inline-block"],["loc",[null,[470,16],[470,185]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 465,
                "column": 8
              },
              "end": {
                "line": 473,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","etigaLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.etigaActive",["loc",[null,[467,18],[467,35]]]]],[],0,1,["loc",[null,[467,12],[471,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 478,
                "column": 14
              },
              "end": {
                "line": 480,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","width: 27px;");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element17, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",["assets/images/icons/",["get","model.gender.id",["loc",[null,[479,69],[479,84]]]],".svg"]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 480,
                "column": 14
              },
              "end": {
                "line": 482,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 483,
                "column": 38
              },
              "end": {
                "line": 483,
                "column": 70
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Kastriert");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 483,
                "column": 70
              },
              "end": {
                "line": 483,
                "column": 88
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Geschlecht");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 488,
                "column": 14
              },
              "end": {
                "line": 490,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element16,0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element16, [2]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.ageShort",["loc",[null,[489,39],[489,57]]]],
            ["content","model.ageShortUnit",["loc",[null,[489,62],[489,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 490,
                "column": 14
              },
              "end": {
                "line": 492,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 493,
                "column": 38
              },
              "end": {
                "line": 493,
                "column": 105
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","model.birthday",["loc",[null,[493,74],[493,88]]]],"DD. MM. YYYY"],[],["loc",[null,[493,60],[493,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child17 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 493,
                "column": 105
              },
              "end": {
                "line": 493,
                "column": 118
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Alter");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child18 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 498,
                "column": 14
              },
              "end": {
                "line": 500,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("kg");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-number-german-3",[["get","model.currentWeight",["loc",[null,[499,64],[499,83]]]]],[],["loc",[null,[499,39],[499,85]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child19 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 500,
                "column": 14
              },
              "end": {
                "line": 502,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child20 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 526,
                  "column": 16
                },
                "end": {
                  "line": 531,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","btn tiny");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/eltiga-poe.png");
              dom.setAttribute(el2,"style","width: 17px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    eltiga\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element15);
              return morphs;
            },
            statements: [
              ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[527,69],[527,74]]]]],[],["loc",[null,[527,38],[527,76]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 531,
                  "column": 16
                },
                "end": {
                  "line": 534,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","activate-eltiga-with-confirm",[],["item",["subexpr","@mut",[["get","model",["loc",[null,[532,56],[532,61]]]]],[],[]],"confirmed","activateEtiga","textToShow","eltiga","text","eltiga für Tier aktivieren?","classNames","inline-block"],["loc",[null,[532,20],[533,102]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 525,
                "column": 14
              },
              "end": {
                "line": 535,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.etigaActive",["loc",[null,[526,22],[526,39]]]]],[],0,1,["loc",[null,[526,16],[534,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child21 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 545,
                "column": 14
              },
              "end": {
                "line": 550,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn tiny");
            dom.setAttribute(el1,"style","padding-left:2px");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/archive.svg");
            dom.setAttribute(el2,"style","width: 15px;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  Archivieren\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element14);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","dead"],[],["loc",[null,[546,61],[546,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child22 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 560,
                "column": 10
              },
              "end": {
                "line": 562,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"href","#tab-activity");
            var el2 = dom.createTextNode("Aktivitäten");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element13, 'class');
            morphs[1] = dom.createElementMorph(element13);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[561,67],[561,76]]]],"activity"],[],["loc",[null,[561,63],[561,88]]]],"health","deselected"],[],["loc",[null,[561,42],[561,112]]]]]]],
            ["element","action",["selectHealthTab","activity"],[],["loc",[null,[561,135],[561,174]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child23 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 568,
                    "column": 98
                  },
                  "end": {
                    "line": 568,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["inline","get-html",[["get","model.allergies",["loc",[null,[568,133],[568,148]]]]],[],["loc",[null,[568,121],[568,151]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 568,
                  "column": 35
                },
                "end": {
                  "line": 568,
                  "column": 213
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createUnsafeMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","model.allergies",["loc",[null,[568,104],[568,119]]]]],[],0,null,["loc",[null,[568,98],[568,163]]]],
              ["inline","get-html",[["get","model.medicalIncompatibilityNotice",["loc",[null,[568,176],[568,210]]]]],[],["loc",[null,[568,164],[568,213]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 568,
                  "column": 213
                },
                "end": {
                  "line": 568,
                  "column": 241
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Dosierungen einsehen");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 567,
                "column": 12
              },
              "end": {
                "line": 569,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[568,45],[568,79]]]],["get","model.allergies",["loc",[null,[568,80],[568,95]]]]],[],["loc",[null,[568,41],[568,96]]]]],[],0,1,["loc",[null,[568,35],[568,248]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child24 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 569,
                "column": 12
              },
              "end": {
                "line": 571,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createTextNode("Derzeit keine Erkrankungen / Dosierungen hinterlegt.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child25 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 594,
                "column": 10
              },
              "end": {
                "line": 596,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"href","#tab-documents");
            var el2 = dom.createTextNode("Docs");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element12, 'class');
            morphs[1] = dom.createElementMorph(element12);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[595,67],[595,77]]]],"documents"],[],["loc",[null,[595,63],[595,90]]]],"masterdata","deselected"],[],["loc",[null,[595,42],[595,118]]]]]]],
            ["element","action",["selectPatientTab","documents"],[],["loc",[null,[595,143],[595,184]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child26 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 605,
                "column": 16
              },
              "end": {
                "line": 608,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Farbe");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.color",["loc",[null,[607,21],[607,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child27 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 609,
                "column": 16
              },
              "end": {
                "line": 612,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Geburtstag");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","model.birthday",["loc",[null,[611,35],[611,49]]]],"DD. MM. YYYY"],[],["loc",[null,[611,21],[611,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child28 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 613,
                "column": 16
              },
              "end": {
                "line": 616,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Todestag");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element11);
            morphs[1] = dom.createMorphAt(element11,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","dead"],[],["loc",[null,[615,36],[615,68]]]],
            ["inline","time-format",[["get","model.deathday",["loc",[null,[615,83],[615,97]]]],"DD. MM. YYYY"],[],["loc",[null,[615,69],[615,114]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child29 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 617,
                "column": 16
              },
              "end": {
                "line": 620,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Archiviert");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element10);
            morphs[1] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","giveaway"],[],["loc",[null,[619,36],[619,72]]]],
            ["inline","time-format",[["get","model.soldDate",["loc",[null,[619,87],[619,101]]]],"DD. MM. YYYY"],[],["loc",[null,[619,73],[619,118]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child30 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 625,
                "column": 14
              },
              "end": {
                "line": 628,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Chip-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor flex-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","ml5");
            dom.setAttribute(el2,"src","assets/images/icons/external-link.svg");
            dom.setAttribute(el2,"style","max-width: 18px;");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(element9,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["openChipPanel",["get","model",["loc",[null,[627,46],[627,51]]]]],[],["loc",[null,[627,21],[627,53]]]],
            ["content","model.chipId",["loc",[null,[627,81],[627,97]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child31 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 629,
                "column": 14
              },
              "end": {
                "line": 632,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Heimtier-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.homeanimalNumber",["loc",[null,[631,19],[631,45]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child32 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 633,
                "column": 14
              },
              "end": {
                "line": 638,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Versicherung");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Versicherungs-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [7]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.insurer.name",["loc",[null,[635,28],[635,50]]]],
            ["content","model.insuranceNumber",["loc",[null,[637,28],[637,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child33 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 648,
                "column": 10
              },
              "end": {
                "line": 653,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"style","padding-top: 5px;padding-left: 4px;");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","gender-icon",[],["classNames","owner-gender","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","model.customer.gender.id",["loc",[null,[651,35],[651,59]]]]],[],[]],"content",""],["loc",[null,[650,14],[651,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child34 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 653,
                "column": 10
              },
              "end": {
                "line": 658,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/jason-poe-grey.svg");
            dom.setAttribute(el2,"style","width: 55px;padding-top: 11px;padding-left: 6px;");
            dom.setAttribute(el2,"class","inline-block vertical-middle");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child35 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 662,
                "column": 27
              },
              "end": {
                "line": 663,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          , ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["content","model.customer.street",["loc",[null,[662,56],[662,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child36 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 670,
                "column": 8
              },
              "end": {
                "line": 674,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","appLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n             ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/eltiga-poe.png");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child37 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 674,
                  "column": 8
                },
                "end": {
                  "line": 678,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"id","appLabel");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createTextNode("\n             ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/eltiga-poe-dark.png");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 674,
                "column": 8
              },
              "end": {
                "line": 678,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.customer.etigaCodeRequested",["loc",[null,[674,18],[674,51]]]]],[],0,null,["loc",[null,[674,8],[678,8]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child38 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 696,
                  "column": 16
                },
                "end": {
                  "line": 700,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/car.svg");
              dom.setAttribute(el1,"style","width: 22px;margin-top: -3px;margin-bottom: -3px;");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  Anfahrt\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 695,
                "column": 14
              },
              "end": {
                "line": 701,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","model.customer.id",["loc",[null,[696,44],[696,61]]]],["subexpr","query-params",[],["selectTab","accounting"],["loc",[null,[696,62],[696,99]]]]],["class","btn tiny"],0,null,["loc",[null,[696,16],[700,28]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child39 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 701,
                "column": 14
              },
              "end": {
                "line": 706,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn tiny");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/car.svg");
            dom.setAttribute(el2,"style","width: 22px;margin-top: -3px;margin-bottom: -3px;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  Anfahrt\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [
            ["element","action",["addDistance"],[],["loc",[null,[702,36],[702,60]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child40 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 728,
                    "column": 152
                  },
                  "end": {
                    "line": 728,
                    "column": 193
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[728,175],[728,192]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 727,
                  "column": 14
                },
                "end": {
                  "line": 729,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/phone.svg");
              dom.setAttribute(el2,"style","width: 15px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element7, 'href');
              morphs[1] = dom.createMorphAt(element7,0,0);
              morphs[2] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["tel:",["get","entry.value",["loc",[null,[728,122],[728,133]]]]]]],
              ["content","entry.value",["loc",[null,[728,137],[728,152]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[728,158],[728,171]]]]],[],0,null,["loc",[null,[728,152],[728,200]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 724,
                "column": 10
              },
              "end": {
                "line": 731,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Telefon");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.phones",["loc",[null,[727,22],[727,43]]]]],[],0,null,["loc",[null,[727,14],[729,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child41 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 736,
                    "column": 156
                  },
                  "end": {
                    "line": 736,
                    "column": 197
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[736,179],[736,196]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 735,
                  "column": 14
                },
                "end": {
                  "line": 737,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/email.svg");
              dom.setAttribute(el2,"style","width: 15px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element6, 'href');
              morphs[1] = dom.createMorphAt(element6,0,0);
              morphs[2] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["mailto:",["get","entry.value",["loc",[null,[736,126],[736,137]]]]]]],
              ["content","entry.value",["loc",[null,[736,141],[736,156]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[736,162],[736,175]]]]],[],0,null,["loc",[null,[736,156],[736,204]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 732,
                "column": 10
              },
              "end": {
                "line": 739,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Email");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.emails",["loc",[null,[735,22],[735,43]]]]],[],0,null,["loc",[null,[735,14],[737,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child42 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 740,
                "column": 10
              },
              "end": {
                "line": 743,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Guthaben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[742,39],[742,49]]]]],[],[]]],["loc",[null,[742,15],[742,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child43 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 759,
                    "column": 46
                  },
                  "end": {
                    "line": 759,
                    "column": 76
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("ganztägig");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 759,
                      "column": 89
                    },
                    "end": {
                      "line": 759,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" - ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.dateFrom",["loc",[null,[759,110],[759,127]]]],
                  ["content","item.dateTo",["loc",[null,[759,130],[759,145]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 759,
                    "column": 76
                  },
                  "end": {
                    "line": 759,
                    "column": 152
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.dateFrom",["loc",[null,[759,95],[759,108]]]]],[],0,null,["loc",[null,[759,89],[759,152]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 759,
                    "column": 159
                  },
                  "end": {
                    "line": 759,
                    "column": 245
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("bis ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.dateEnd",["loc",[null,[759,229],[759,245]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 756,
                  "column": 20
                },
                "end": {
                  "line": 765,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-8/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","Datum");
              var el3 = dom.createTextNode("\n                          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-16/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","TitelKategorie");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"class","cursor is-highlighted");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [1, 1]);
              var element4 = dom.childAt(element2, [3, 1]);
              var element5 = dom.childAt(element4, [0]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(element3,0,0);
              morphs[1] = dom.createMorphAt(element3,2,2);
              morphs[2] = dom.createMorphAt(element3,3,3);
              morphs[3] = dom.createElementMorph(element5);
              morphs[4] = dom.createMorphAt(element5,0,0);
              morphs[5] = dom.createMorphAt(element4,2,2);
              return morphs;
            },
            statements: [
              ["content","item.date",["loc",[null,[759,32],[759,45]]]],
              ["block","if",[["get","item.wholeDay",["loc",[null,[759,52],[759,65]]]]],[],0,1,["loc",[null,[759,46],[759,159]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.dateEnd",["loc",[null,[759,179],[759,191]]]],["get","item.date",["loc",[null,[759,192],[759,201]]]]],[],["loc",[null,[759,175],[759,202]]]]],[],["loc",[null,[759,170],[759,203]]]],["get","item.multiDay",["loc",[null,[759,204],[759,217]]]]],[],["loc",[null,[759,165],[759,218]]]]],[],2,null,["loc",[null,[759,159],[759,252]]]],
              ["element","action",["showAppointment",["get","item",["loc",[null,[762,90],[762,94]]]]],[],["loc",[null,[762,63],[762,96]]]],
              ["content","item.title",["loc",[null,[762,97],[762,111]]]],
              ["content","item.category.name",["loc",[null,[762,120],[762,142]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 748,
                "column": 12
              },
              "end": {
                "line": 769,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper table--small box no-box@phone mb0 pl0 pr0");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table no-box@phone");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createComment("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Datum");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Titel/Kategorie");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerAppointments",["loc",[null,[756,28],[756,48]]]]],[],0,null,["loc",[null,[756,20],[765,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child44 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 769,
                "column": 12
              },
              "end": {
                "line": 773,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ml10");
            var el2 = dom.createTextNode("\n                Derzeit keine Termine hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child45 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 794,
                      "column": 224
                    },
                    "end": {
                      "line": 794,
                      "column": 273
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.name",["loc",[null,[794,260],[794,273]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 791,
                    "column": 22
                  },
                  "end": {
                    "line": 803,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Name");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","is-highlighted");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Spezies");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Rasse");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element1,0,0);
                morphs[1] = dom.createMorphAt(element1,2,2);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["classNames","inline-block vertical-middle","evil",["subexpr","@mut",[["get","item.evil",["loc",[null,[794,118],[794,127]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","item.category.id",["loc",[null,[794,138],[794,154]]]]],[],[]],"race-id",["subexpr","@mut",[["get","item.digitalRace.icon",["loc",[null,[794,163],[794,184]]]]],[],[]],"size","22","content","","color","#4A4A4A"],["loc",[null,[794,57],[794,223]]]],
                ["block","link-to",["patients.edit",["get","item.id",["loc",[null,[794,251],[794,258]]]]],[],0,null,["loc",[null,[794,224],[794,285]]]],
                ["content","item.category.name",["loc",[null,[797,34],[797,56]]]],
                ["content","item.race",["loc",[null,[800,34],[800,47]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 790,
                  "column": 20
                },
                "end": {
                  "line": 804,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.dead",["loc",[null,[791,38],[791,47]]]]],[],["loc",[null,[791,33],[791,48]]]],["subexpr","not",[["get","item.sold",["loc",[null,[791,54],[791,63]]]]],[],["loc",[null,[791,49],[791,64]]]]],[],["loc",[null,[791,28],[791,65]]]]],[],0,null,["loc",[null,[791,22],[803,29]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 781,
                "column": 12
              },
              "end": {
                "line": 808,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper table--small box no-box@phone grid mt20 mb20");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table no-box@phone no-hover");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Name");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Spezies");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Rasse");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerPatients",["loc",[null,[790,28],[790,44]]]]],[],0,null,["loc",[null,[790,20],[804,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child46 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 808,
                "column": 12
              },
              "end": {
                "line": 812,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","mt20 ");
            var el2 = dom.createTextNode("\n                Derzeit keine Tiere hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 420,
              "column": 3
            },
            "end": {
              "line": 819,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet patientSidebar");
          dom.setAttribute(el1,"id","patientEditSidebar");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          var el3 = dom.createTextNode("X");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","picture");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("figure");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","summary");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","subName mb0");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"id","insuredLabel");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock mt20");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer middle");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer cursor");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createTextNode("Gewicht");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","width: 100%;padding-left:0");
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                  ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/assistant.svg");
          dom.setAttribute(el8,"style","width: 19px;margin-right: 5px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                  Digitaler\n                  Assistent\n                ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock clearfix");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","middle");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/barcode.svg");
          dom.setAttribute(el8,"style","width: 17px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Etiketten\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","health");
          dom.setAttribute(el3,"style","margin-top: 25px;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-health");
          var el6 = dom.createTextNode("Gesundheit");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-vacc");
          var el6 = dom.createTextNode("Impfpass");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-memos");
          var el6 = dom.createTextNode("Notizen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-health");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-vacc");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-activity");
          var el6 = dom.createTextNode("\n            In Entwicklung\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-memos");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Notiz erstellen");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","masterdata");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-patientmasterdata");
          var el6 = dom.createTextNode("Profil");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-lab");
          var el6 = dom.createTextNode("Labor");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-patientxray");
          var el6 = dom.createTextNode("Röntgen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-patientmasterdata");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-12/24");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","dataList");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("label");
          var el9 = dom.createTextNode("Nr");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("p");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("\n        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-12/24 mt10");
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","dataList");
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n          ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerSummary");
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","subName");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"id","saldoLabel");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","textBelow");
          var el7 = dom.createElement("b");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Saldo");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock clearfix");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group mt0");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/revenue.svg");
          dom.setAttribute(el8,"style","width: 15px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Umsatz\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","middle");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/discount.svg");
          dom.setAttribute(el8,"style","width: 15px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Rabatt\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerdata");
          dom.setAttribute(el3,"style","margin-bottom: 120px");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customermasterdata");
          var el6 = dom.createTextNode("Profil");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerapps");
          var el6 = dom.createTextNode("Termine");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerpatients");
          var el6 = dom.createTextNode("Tiere");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerdocs");
          var el6 = dom.createTextNode("Docs");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customermasterdata");
          var el6 = dom.createTextNode("\n\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","dataList ");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#customerapps");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Termine");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerdocs");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Dokumente");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerpatients");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Tiere");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [0]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element19, [3]);
          var element22 = dom.childAt(element21, [1, 1]);
          var element23 = dom.childAt(element21, [3]);
          var element24 = dom.childAt(element23, [1]);
          var element25 = dom.childAt(element23, [3]);
          var element26 = dom.childAt(element23, [5]);
          var element27 = dom.childAt(element23, [15]);
          var element28 = dom.childAt(element27, [1, 1]);
          var element29 = dom.childAt(element27, [3, 1]);
          var element30 = dom.childAt(element27, [5]);
          var element31 = dom.childAt(element23, [17, 1, 1, 1]);
          var element32 = dom.childAt(element23, [19, 1]);
          var element33 = dom.childAt(element32, [3, 1]);
          var element34 = dom.childAt(element21, [5]);
          var element35 = dom.childAt(element34, [1]);
          var element36 = dom.childAt(element35, [1]);
          var element37 = dom.childAt(element35, [3]);
          var element38 = dom.childAt(element35, [7]);
          var element39 = dom.childAt(element34, [3]);
          var element40 = dom.childAt(element39, [1]);
          var element41 = dom.childAt(element39, [3]);
          var element42 = dom.childAt(element39, [5]);
          var element43 = dom.childAt(element39, [7]);
          var element44 = dom.childAt(element43, [5]);
          var element45 = dom.childAt(element21, [7]);
          var element46 = dom.childAt(element45, [1]);
          var element47 = dom.childAt(element46, [1]);
          var element48 = dom.childAt(element46, [3]);
          var element49 = dom.childAt(element46, [5]);
          var element50 = dom.childAt(element45, [3, 1]);
          var element51 = dom.childAt(element50, [1, 1]);
          var element52 = dom.childAt(element50, [3, 1]);
          var element53 = dom.childAt(element21, [9]);
          var element54 = dom.childAt(element53, [1]);
          var element55 = dom.childAt(element53, [3]);
          var element56 = dom.childAt(element53, [5]);
          var element57 = dom.childAt(element53, [7, 1]);
          var element58 = dom.childAt(element53, [11, 1]);
          var element59 = dom.childAt(element58, [1, 1]);
          var element60 = dom.childAt(element58, [3, 1]);
          var element61 = dom.childAt(element21, [11]);
          var element62 = dom.childAt(element61, [1]);
          var element63 = dom.childAt(element62, [1]);
          var element64 = dom.childAt(element62, [3]);
          var element65 = dom.childAt(element62, [5]);
          var element66 = dom.childAt(element62, [7]);
          var element67 = dom.childAt(element61, [3]);
          var element68 = dom.childAt(element67, [1]);
          var element69 = dom.childAt(element68, [1]);
          var element70 = dom.childAt(element68, [3]);
          var element71 = dom.childAt(element67, [3]);
          var element72 = dom.childAt(element71, [3]);
          var element73 = dom.childAt(element67, [5]);
          var element74 = dom.childAt(element73, [3]);
          var element75 = dom.childAt(element67, [7]);
          var element76 = dom.childAt(element75, [3]);
          var morphs = new Array(93);
          morphs[0] = dom.createElementMorph(element20);
          morphs[1] = dom.createAttrMorph(element22, 'class');
          morphs[2] = dom.createAttrMorph(element22, 'style');
          morphs[3] = dom.createElementMorph(element22);
          morphs[4] = dom.createAttrMorph(element24, 'class');
          morphs[5] = dom.createMorphAt(dom.childAt(element24, [1]),1,1);
          morphs[6] = dom.createMorphAt(element25,0,0);
          morphs[7] = dom.createMorphAt(element25,2,2);
          morphs[8] = dom.createMorphAt(element26,0,0);
          morphs[9] = dom.createMorphAt(element26,2,2);
          morphs[10] = dom.createMorphAt(element23,7,7);
          morphs[11] = dom.createMorphAt(element23,9,9);
          morphs[12] = dom.createMorphAt(dom.childAt(element23, [11]),1,1);
          morphs[13] = dom.createMorphAt(element23,13,13);
          morphs[14] = dom.createMorphAt(element28,1,1);
          morphs[15] = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
          morphs[16] = dom.createMorphAt(element29,1,1);
          morphs[17] = dom.createMorphAt(dom.childAt(element29, [3]),0,0);
          morphs[18] = dom.createElementMorph(element30);
          morphs[19] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
          morphs[20] = dom.createElementMorph(element31);
          morphs[21] = dom.createMorphAt(dom.childAt(element32, [1]),1,1);
          morphs[22] = dom.createElementMorph(element33);
          morphs[23] = dom.createMorphAt(dom.childAt(element32, [5]),1,1);
          morphs[24] = dom.createAttrMorph(element36, 'class');
          morphs[25] = dom.createElementMorph(element36);
          morphs[26] = dom.createAttrMorph(element37, 'class');
          morphs[27] = dom.createElementMorph(element37);
          morphs[28] = dom.createMorphAt(element35,5,5);
          morphs[29] = dom.createAttrMorph(element38, 'class');
          morphs[30] = dom.createElementMorph(element38);
          morphs[31] = dom.createAttrMorph(element40, 'class');
          morphs[32] = dom.createElementMorph(element40);
          morphs[33] = dom.createMorphAt(element40,1,1);
          morphs[34] = dom.createAttrMorph(element41, 'class');
          morphs[35] = dom.createMorphAt(element41,1,1);
          morphs[36] = dom.createAttrMorph(element42, 'class');
          morphs[37] = dom.createAttrMorph(element43, 'class');
          morphs[38] = dom.createMorphAt(element43,1,1);
          morphs[39] = dom.createElementMorph(element44);
          morphs[40] = dom.createAttrMorph(element47, 'class');
          morphs[41] = dom.createElementMorph(element47);
          morphs[42] = dom.createAttrMorph(element48, 'class');
          morphs[43] = dom.createElementMorph(element48);
          morphs[44] = dom.createAttrMorph(element49, 'class');
          morphs[45] = dom.createElementMorph(element49);
          morphs[46] = dom.createMorphAt(element46,7,7);
          morphs[47] = dom.createAttrMorph(element50, 'class');
          morphs[48] = dom.createMorphAt(dom.childAt(element51, [3]),0,0);
          morphs[49] = dom.createMorphAt(element51,5,5);
          morphs[50] = dom.createMorphAt(element51,6,6);
          morphs[51] = dom.createMorphAt(element51,7,7);
          morphs[52] = dom.createMorphAt(element51,8,8);
          morphs[53] = dom.createMorphAt(element52,1,1);
          morphs[54] = dom.createMorphAt(element52,2,2);
          morphs[55] = dom.createMorphAt(element52,3,3);
          morphs[56] = dom.createElementMorph(element53);
          morphs[57] = dom.createAttrMorph(element54, 'class');
          morphs[58] = dom.createMorphAt(element54,1,1);
          morphs[59] = dom.createMorphAt(element55,0,0);
          morphs[60] = dom.createMorphAt(element55,2,2);
          morphs[61] = dom.createMorphAt(element55,4,4);
          morphs[62] = dom.createMorphAt(element56,0,0);
          morphs[63] = dom.createMorphAt(element56,1,1);
          morphs[64] = dom.createMorphAt(element56,3,3);
          morphs[65] = dom.createAttrMorph(element57, 'class');
          morphs[66] = dom.createMorphAt(dom.childAt(element57, [1, 0]),0,0);
          morphs[67] = dom.createMorphAt(element53,9,9);
          morphs[68] = dom.createElementMorph(element59);
          morphs[69] = dom.createElementMorph(element60);
          morphs[70] = dom.createMorphAt(dom.childAt(element58, [5]),1,1);
          morphs[71] = dom.createAttrMorph(element63, 'class');
          morphs[72] = dom.createElementMorph(element63);
          morphs[73] = dom.createAttrMorph(element64, 'class');
          morphs[74] = dom.createElementMorph(element64);
          morphs[75] = dom.createAttrMorph(element65, 'class');
          morphs[76] = dom.createElementMorph(element65);
          morphs[77] = dom.createAttrMorph(element66, 'class');
          morphs[78] = dom.createElementMorph(element66);
          morphs[79] = dom.createAttrMorph(element68, 'class');
          morphs[80] = dom.createMorphAt(element69,1,1);
          morphs[81] = dom.createMorphAt(element69,2,2);
          morphs[82] = dom.createMorphAt(element69,3,3);
          morphs[83] = dom.createElementMorph(element70);
          morphs[84] = dom.createAttrMorph(element71, 'class');
          morphs[85] = dom.createMorphAt(element71,1,1);
          morphs[86] = dom.createElementMorph(element72);
          morphs[87] = dom.createAttrMorph(element73, 'class');
          morphs[88] = dom.createMorphAt(element73,1,1);
          morphs[89] = dom.createElementMorph(element74);
          morphs[90] = dom.createAttrMorph(element75, 'class');
          morphs[91] = dom.createMorphAt(element75,1,1);
          morphs[92] = dom.createElementMorph(element76);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[421,42],[421,73]]]],
          ["attribute","class",["concat",["cursor frame ",["subexpr","css-bool-evaluator",[["subexpr","or",[["get","model.sold",["loc",[null,[424,61],[424,71]]]],["get","model.dead",["loc",[null,[424,72],[424,82]]]]],[],["loc",[null,[424,57],[424,83]]]],"dead",""],[],["loc",[null,[424,36],[424,95]]]]]]],
          ["attribute","style",["concat",["background-image: url(",["subexpr","get-avatar",[["get","model.picture",["loc",[null,[424,139],[424,152]]]]],[],["loc",[null,[424,126],[424,154]]]],");"]]],
          ["element","action",["gotoPatient",["get","model.id",["loc",[null,[424,181],[424,189]]]]],[],["loc",[null,[424,158],[424,191]]]],
          ["attribute","class",["concat",["avatar ",["get","model.gender.id",["loc",[null,[429,31],[429,46]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","model.category.id",["loc",[null,[431,32],[431,49]]]],"misc"],[],["loc",[null,[431,28],[431,57]]]]],[],["loc",[null,[431,23],[431,58]]]],["get","model.category",["loc",[null,[431,59],[431,73]]]]],[],["loc",[null,[431,18],[431,74]]]]],[],0,1,["loc",[null,[431,12],[436,19]]]],
          ["block","if",[["get","model.dead",["loc",[null,[440,18],[440,28]]]]],[],2,null,["loc",[null,[440,12],[440,102]]]],
          ["content","model.name",["loc",[null,[440,103],[440,117]]]],
          ["content","model.category.name",["loc",[null,[441,31],[441,54]]]],
          ["block","if",[["get","model.digitalRace",["loc",[null,[441,61],[441,78]]]]],[],3,4,["loc",[null,[441,55],[441,139]]]],
          ["block","if",[["get","model.outdoor",["loc",[null,[442,14],[442,27]]]]],[],5,null,["loc",[null,[442,8],[442,78]]]],
          ["block","if",[["get","model.evil",["loc",[null,[443,14],[443,24]]]]],[],6,null,["loc",[null,[443,8],[451,15]]]],
          ["block","if",[["get","model.insured",["loc",[null,[454,16],[454,29]]]]],[],7,8,["loc",[null,[454,10],[462,17]]]],
          ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[465,14],[465,31]]]]],[],9,null,["loc",[null,[465,8],[473,15]]]],
          ["block","if",[["get","model.gender",["loc",[null,[478,20],[478,32]]]]],[],10,11,["loc",[null,[478,14],[482,21]]]],
          ["block","if",[["get","model.castrated",["loc",[null,[483,44],[483,59]]]]],[],12,13,["loc",[null,[483,38],[483,95]]]],
          ["block","if",[["get","model.ageShort",["loc",[null,[488,20],[488,34]]]]],[],14,15,["loc",[null,[488,14],[492,21]]]],
          ["block","if",[["get","model.birthday",["loc",[null,[493,44],[493,58]]]]],[],16,17,["loc",[null,[493,38],[493,125]]]],
          ["element","action",["openWeightPanel"],[],["loc",[null,[496,44],[496,72]]]],
          ["block","if",[["get","model.currentWeight",["loc",[null,[498,20],[498,39]]]]],[],18,19,["loc",[null,[498,14],[502,21]]]],
          ["element","action",["openDigitalHelperPanel",["get","model.id",["loc",[null,[512,70],[512,78]]]]],[],["loc",[null,[512,36],[512,80]]]],
          ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[525,20],[525,37]]]]],[],20,null,["loc",[null,[525,14],[535,21]]]],
          ["element","action",["selectBarcode",["get","model",["loc",[null,[539,59],[539,64]]]]],[],["loc",[null,[539,34],[539,66]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","model.dead",["loc",[null,[545,30],[545,40]]]]],[],["loc",[null,[545,25],[545,41]]]],["subexpr","not",[["get","model.sold",["loc",[null,[545,47],[545,57]]]]],[],["loc",[null,[545,42],[545,58]]]]],[],["loc",[null,[545,20],[545,59]]]]],[],21,null,["loc",[null,[545,14],[550,21]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[558,65],[558,74]]]],"health"],[],["loc",[null,[558,61],[558,84]]]],"health","deselected"],[],["loc",[null,[558,40],[558,108]]]]]]],
          ["element","action",["selectHealthTab","health"],[],["loc",[null,[558,129],[558,166]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[559,65],[559,74]]]],"vacc"],[],["loc",[null,[559,61],[559,82]]]],"health","deselected"],[],["loc",[null,[559,40],[559,106]]]]]]],
          ["element","action",["selectHealthTab","vacc"],[],["loc",[null,[559,125],[559,160]]]],
          ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[560,16],[560,33]]]]],[],22,null,["loc",[null,[560,10],[562,17]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[563,65],[563,74]]]],"memos"],[],["loc",[null,[563,61],[563,83]]]],"health","deselected"],[],["loc",[null,[563,40],[563,107]]]]]]],
          ["element","action",["selectHealthTab","memos"],[],["loc",[null,[563,127],[563,163]]]],
          ["attribute","class",["concat",["pl0 pb0 pt10 pr0 tabs__content content cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[566,92],[566,101]]]],"health"],[],["loc",[null,[566,88],[566,111]]]],"is-active",""],[],["loc",[null,[566,67],[566,128]]]]]]],
          ["element","action",["openMedicalProblemsPanel",["get","model.id",["loc",[null,[566,189],[566,197]]]],"infinity"],[],["loc",[null,[566,153],[566,210]]]],
          ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[567,22],[567,56]]]],["get","model.allergies",["loc",[null,[567,57],[567,72]]]]],[],["loc",[null,[567,18],[567,73]]]]],[],23,24,["loc",[null,[567,12],[571,19]]]],
          ["attribute","class",["concat",["tabs__content content pl0 pr0 pb0  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[575,81],[575,90]]]],"vacc"],[],["loc",[null,[575,77],[575,98]]]],"is-active",""],[],["loc",[null,[575,56],[575,115]]]]]]],
          ["inline","patient-vaccinations",[],["gotoService","gotoService","gotoProduct","gotoProduct","items",["subexpr","@mut",[["get","vaccinations",["loc",[null,[576,93],[576,105]]]]],[],[]]],["loc",[null,[576,12],[576,107]]]],
          ["attribute","class",["concat",["tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[578,68],[578,77]]]],"activity"],[],["loc",[null,[578,64],[578,89]]]],"is-active",""],[],["loc",[null,[578,43],[578,106]]]]]]],
          ["attribute","class",["concat",["tabs__content content pl0 pr0 pb0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[581,80],[581,89]]]],"memos"],[],["loc",[null,[581,76],[581,98]]]],"is-active",""],[],["loc",[null,[581,55],[581,115]]]]]]],
          ["inline","patient-memos",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[582,38],[582,46]]]]],[],[]],"items",["subexpr","@mut",[["get","memos",["loc",[null,[582,53],[582,58]]]]],[],[]],"reload","reloadMemos","actionReceiver",["subexpr","@mut",[["get","patientMemoPanel",["loc",[null,[582,96],[582,112]]]]],[],[]]],["loc",[null,[582,12],[582,114]]]],
          ["element","action",["addNewMemo"],[],["loc",[null,[584,40],[584,63]]]],
          ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[591,76],[591,86]]]],"masterdata"],[],["loc",[null,[591,72],[591,100]]]],"masterdata","deselected"],[],["loc",[null,[591,51],[591,128]]]]]]],
          ["element","action",["selectPatientTab","masterdata"],[],["loc",[null,[591,160],[591,202]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[592,65],[592,75]]]],"lab"],[],["loc",[null,[592,61],[592,82]]]],"masterdata","deselected"],[],["loc",[null,[592,40],[592,110]]]]]]],
          ["element","action",["selectPatientTab","lab"],[],["loc",[null,[592,128],[592,163]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[593,65],[593,75]]]],"xray"],[],["loc",[null,[593,61],[593,83]]]],"masterdata","deselected"],[],["loc",[null,[593,40],[593,111]]]]]]],
          ["element","action",["selectPatientTab","xray"],[],["loc",[null,[593,137],[593,173]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","model.patient.dead",["loc",[null,[594,26],[594,44]]]]],[],["loc",[null,[594,21],[594,45]]]],["subexpr","not",[["get","model.patient.sold",["loc",[null,[594,51],[594,69]]]]],[],["loc",[null,[594,46],[594,70]]]]],[],["loc",[null,[594,16],[594,71]]]]],[],25,null,["loc",[null,[594,10],[596,17]]]],
          ["attribute","class",["concat",["pl0 pr0 pb0 tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[600,80],[600,90]]]],"masterdata"],[],["loc",[null,[600,76],[600,104]]]],"is-active",""],[],["loc",[null,[600,55],[600,121]]]]]]],
          ["content","model.id",["loc",[null,[604,19],[604,31]]]],
          ["block","if",[["get","model.color",["loc",[null,[605,22],[605,33]]]]],[],26,null,["loc",[null,[605,16],[608,23]]]],
          ["block","if",[["get","model.birthday",["loc",[null,[609,22],[609,36]]]]],[],27,null,["loc",[null,[609,16],[612,23]]]],
          ["block","if",[["get","model.dead",["loc",[null,[613,22],[613,32]]]]],[],28,null,["loc",[null,[613,16],[616,23]]]],
          ["block","if",[["get","model.sold",["loc",[null,[617,22],[617,32]]]]],[],29,null,["loc",[null,[617,16],[620,23]]]],
          ["block","if",[["get","model.chipId",["loc",[null,[625,20],[625,32]]]]],[],30,null,["loc",[null,[625,14],[628,21]]]],
          ["block","if",[["get","model.homeanimalNumber",["loc",[null,[629,20],[629,42]]]]],[],31,null,["loc",[null,[629,14],[632,21]]]],
          ["block","if",[["get","model.insurer",["loc",[null,[633,20],[633,33]]]]],[],32,null,["loc",[null,[633,14],[638,21]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[645,78],[645,95]]]],""],[],["loc",[null,[645,54],[645,100]]]],
          ["attribute","class",["concat",["avatar ",["get","model.customer.gender.id",["loc",[null,[647,29],[647,53]]]]]]],
          ["block","if",[["subexpr","and",[["get","model.customer.gender",["loc",[null,[648,21],[648,42]]]],["subexpr","not",[["subexpr","eq",[["get","model.customer.gender.id",["loc",[null,[648,52],[648,76]]]],"male/female/company"],[],["loc",[null,[648,48],[648,99]]]]],[],["loc",[null,[648,43],[648,100]]]]],[],["loc",[null,[648,16],[648,101]]]]],[],33,34,["loc",[null,[648,10],[658,17]]]],
          ["content","model.customer.title.name",["loc",[null,[661,12],[661,41]]]],
          ["content","model.customer.firstname",["loc",[null,[661,42],[661,70]]]],
          ["content","model.customer.lastname",["loc",[null,[661,71],[661,98]]]],
          ["block","if",[["get","model.customer.street",["loc",[null,[662,33],[662,54]]]]],[],35,null,["loc",[null,[662,27],[663,19]]]],
          ["content","model.customer.postalCode",["loc",[null,[663,19],[663,48]]]],
          ["content","model.customer.town",["loc",[null,[663,49],[663,72]]]],
          ["attribute","class",["concat",["insured ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[665,57],[665,67]]]],0],[],["loc",[null,[665,53],[665,70]]]],"is-negative",""],[],["loc",[null,[665,32],[665,89]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[666,65],[666,75]]]]],[],[]],"classNames","inline-block"],["loc",[null,[666,41],[666,103]]]],
          ["block","if",[["get","model.customer.etigaAppConnected",["loc",[null,[670,14],[670,46]]]]],[],36,37,["loc",[null,[670,8],[678,15]]]],
          ["element","action",["openRevenue",["get","model.customer.id",["loc",[null,[683,57],[683,74]]]]],["bubbles",false],["loc",[null,[683,34],[683,90]]]],
          ["element","action",["openCustomerReductions",["get","model.customer.id",["loc",[null,[689,68],[689,85]]]]],["bubbles",false],["loc",[null,[689,34],[689,101]]]],
          ["block","if",[["subexpr","not",[["get","model.customer.driveAddressAvailable",["loc",[null,[695,25],[695,61]]]]],[],["loc",[null,[695,20],[695,62]]]]],[],38,39,["loc",[null,[695,14],[706,21]]]],
          ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[714,76],[714,87]]]],"masterdata"],[],["loc",[null,[714,72],[714,101]]]],"customerdata","deselected"],[],["loc",[null,[714,51],[714,131]]]]]]],
          ["element","action",["selectCustomerTab","masterdata"],[],["loc",[null,[714,164],[714,207]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[715,65],[715,76]]]],"appointments"],[],["loc",[null,[715,61],[715,92]]]],"customerdata","deselected"],[],["loc",[null,[715,40],[715,122]]]]]]],
          ["element","action",["selectCustomerTab","appointments"],[],["loc",[null,[715,149],[715,194]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[716,65],[716,76]]]],"patients"],[],["loc",[null,[716,61],[716,88]]]],"customerdata","deselected"],[],["loc",[null,[716,40],[716,118]]]]]]],
          ["element","action",["selectCustomerTab","patients"],[],["loc",[null,[716,149],[716,190]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[717,65],[717,76]]]],"documents"],[],["loc",[null,[717,61],[717,89]]]],"customerdata","deselected"],[],["loc",[null,[717,40],[717,119]]]]]]],
          ["element","action",["selectCustomerTab","documents"],[],["loc",[null,[717,147],[717,189]]]],
          ["attribute","class",["concat",["tabs__content pl0 pr0 pb0 content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[721,80],[721,91]]]],"masterdata"],[],["loc",[null,[721,76],[721,105]]]],"is-active",""],[],["loc",[null,[721,55],[721,122]]]]]]],
          ["block","if",[["get","model.customer.phones",["loc",[null,[724,16],[724,37]]]]],[],40,null,["loc",[null,[724,10],[731,17]]]],
          ["block","if",[["get","model.customer.emails",["loc",[null,[732,16],[732,37]]]]],[],41,null,["loc",[null,[732,10],[739,17]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[740,20],[740,30]]]],0],[],["loc",[null,[740,16],[740,33]]]]],[],42,null,["loc",[null,[740,10],[743,17]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[745,60],[745,77]]]],""],[],["loc",[null,[745,36],[745,82]]]],
          ["attribute","class",["concat",["tabs__content pl0 pt0 pb0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[747,76],[747,87]]]],"appointments"],[],["loc",[null,[747,72],[747,103]]]],"is-active",""],[],["loc",[null,[747,51],[747,120]]]]]]],
          ["block","if",[["get","customerAppointments",["loc",[null,[748,18],[748,38]]]]],[],43,44,["loc",[null,[748,12],[773,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[774,64],[774,81]]]],"appointments"],[],["loc",[null,[774,40],[774,98]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[776,76],[776,87]]]],"documents"],[],["loc",[null,[776,72],[776,100]]]],"is-active",""],[],["loc",[null,[776,51],[776,117]]]]]]],
          ["inline","documents-panel",[],["prefix","cdocs","loadPanels",false,"smallView",true,"showSearch",false,"useHover",false,"showBreadcrumbs",false,"actionReceiver",["subexpr","@mut",[["get","customerDocumentsPanel",["loc",[null,[777,146],[777,168]]]]],[],[]]],["loc",[null,[777,12],[777,170]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[778,64],[778,81]]]],"documents"],[],["loc",[null,[778,40],[778,95]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[780,76],[780,87]]]],"patients"],[],["loc",[null,[780,72],[780,99]]]],"is-active",""],[],["loc",[null,[780,51],[780,116]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","loading",["loc",[null,[781,28],[781,35]]]]],[],["loc",[null,[781,23],[781,36]]]],["get","customerPatients",["loc",[null,[781,37],[781,53]]]]],[],["loc",[null,[781,18],[781,54]]]]],[],45,46,["loc",[null,[781,12],[812,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[813,64],[813,81]]]],"patients"],[],["loc",[null,[813,40],[813,94]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42, child43, child44, child45, child46]
      };
    }());
    var child28 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 819,
              "column": 0
            },
            "end": {
              "line": 823,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[821,61],[821,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 984,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/patients/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","dropdown");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button context mr10 dropdown d-flex align-items-center");
        dom.setAttribute(el4,"data-toggle","dropdown");
        dom.setAttribute(el4,"aria-haspopup","true");
        dom.setAttribute(el4,"aria-expanded","false");
        dom.setAttribute(el4,"style","line-height: 20px");
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Weitere Aktionen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dropdown-menu larger");
        dom.setAttribute(el4,"aria-labelledby","dropdownMenuButton");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","d-flex align-items-center dropdown-item");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/merge-primary.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Tier zusammenführen\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Check-In");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","patientEditPage");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-master-data");
        var el7 = dom.createTextNode("Stammdaten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-future");
        var el7 = dom.createTextNode("Zukunft");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-lab");
        var el7 = dom.createTextNode("Labor & Röntgen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-documents");
        var el7 = dom.createTextNode("Dokumente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-tasks");
        var el7 = dom.createTextNode("Aufgaben");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-etiga");
        var el7 = dom.createTextNode("eltiga");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","patientEdit");
        dom.setAttribute(el4,"class","mb0 admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-weight");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Gewicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-activities");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-history");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mb30 mt30");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter standalone col l-12/24");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Nur eingeblendete");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter col l-12/24");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Tags");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Ernährung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Leistungen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Produkte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Labor");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-future");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-lab");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-medias");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Dokumente");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-documents");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-correspondance");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-tasks");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-todo");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mt30 mb30 col l-6/24@desk");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","u-mb0 field select full-width");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Status");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table-wrapper box no-box@phone");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table grid table--large box no-box@phone");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Fällig");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-10/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Titel");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Status");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","pagination-wrapper is-fixed");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-appointments");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-6/24@desk mt30 mb30");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","filterUser");
        dom.setAttribute(el6,"class","input-element");
        dom.setAttribute(el6,"style","width: 100%");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Kategorie");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-etiga");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $( window ).resize(function() {\n          $('aside.side-bar ').css('min-height', $(window).height()-375);\n          $('aside.side-bar div.scrollable').css('max-height', $(window).height()-375);\n        });\n        $('aside.side-bar').css('min-height', $(window).height()-375);\n        $('aside.side-bar div.scrollable').css('max-height', $(window).height()-375);\n        $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {\n            $(this).val(picker.startDate.format('DD. MM. YYYY'));\n            $(this).focus();\n        });\n        $('.datetimepicker').daterangepicker({\n            singleDatePicker: true,\n            autoUpdateInput: false,\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element107 = dom.childAt(fragment, [1]);
        var element108 = dom.childAt(element107, [3]);
        var element109 = dom.childAt(element108, [1, 3]);
        var element110 = dom.childAt(element109, [7]);
        var element111 = dom.childAt(element108, [10]);
        var element112 = dom.childAt(fragment, [3]);
        var element113 = dom.childAt(element112, [1]);
        var element114 = dom.childAt(element113, [1, 1]);
        var element115 = dom.childAt(element114, [1, 1]);
        var element116 = dom.childAt(element114, [6, 1]);
        var element117 = dom.childAt(element114, [8, 1]);
        var element118 = dom.childAt(element114, [10, 1]);
        var element119 = dom.childAt(element114, [14, 1]);
        var element120 = dom.childAt(element114, [19, 1]);
        var element121 = dom.childAt(element113, [3]);
        var element122 = dom.childAt(element121, [1]);
        var element123 = dom.childAt(element122, [1]);
        var element124 = dom.childAt(element121, [3]);
        var element125 = dom.childAt(element121, [5]);
        var element126 = dom.childAt(element121, [7]);
        var element127 = dom.childAt(element126, [1]);
        var element128 = dom.childAt(element127, [1]);
        var element129 = dom.childAt(element128, [3]);
        var element130 = dom.childAt(element128, [5]);
        var element131 = dom.childAt(element128, [7]);
        var element132 = dom.childAt(element127, [3]);
        var element133 = dom.childAt(element132, [3]);
        var element134 = dom.childAt(element132, [5]);
        var element135 = dom.childAt(element132, [7]);
        var element136 = dom.childAt(element132, [9]);
        var element137 = dom.childAt(element121, [9]);
        var element138 = dom.childAt(element121, [11]);
        var element139 = dom.childAt(element121, [13]);
        var element140 = dom.childAt(element121, [15]);
        var element141 = dom.childAt(element121, [17]);
        var element142 = dom.childAt(element121, [19]);
        var element143 = dom.childAt(element121, [21]);
        var element144 = dom.childAt(element143, [3]);
        var element145 = dom.childAt(element121, [23]);
        var element146 = dom.childAt(element121, [25]);
        var element147 = dom.childAt(element112, [3]);
        var element148 = dom.childAt(element147, [1]);
        var morphs = new Array(131);
        morphs[0] = dom.createAttrMorph(element107, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element107, [1]),1,1);
        morphs[2] = dom.createAttrMorph(element108, 'class');
        morphs[3] = dom.createMorphAt(element109,1,1);
        morphs[4] = dom.createMorphAt(element109,2,2);
        morphs[5] = dom.createMorphAt(element109,3,3);
        morphs[6] = dom.createMorphAt(element109,4,4);
        morphs[7] = dom.createMorphAt(element109,5,5);
        morphs[8] = dom.createElementMorph(element110);
        morphs[9] = dom.createMorphAt(element108,3,3);
        morphs[10] = dom.createMorphAt(element108,4,4);
        morphs[11] = dom.createMorphAt(element108,5,5);
        morphs[12] = dom.createMorphAt(element108,6,6);
        morphs[13] = dom.createMorphAt(element108,7,7);
        morphs[14] = dom.createMorphAt(element108,8,8);
        morphs[15] = dom.createElementMorph(element111);
        morphs[16] = dom.createMorphAt(element111,1,1);
        morphs[17] = dom.createAttrMorph(element112, 'class');
        morphs[18] = dom.createAttrMorph(element115, 'class');
        morphs[19] = dom.createElementMorph(element115);
        morphs[20] = dom.createMorphAt(element114,3,3);
        morphs[21] = dom.createMorphAt(element114,4,4);
        morphs[22] = dom.createAttrMorph(element116, 'class');
        morphs[23] = dom.createElementMorph(element116);
        morphs[24] = dom.createAttrMorph(element117, 'class');
        morphs[25] = dom.createElementMorph(element117);
        morphs[26] = dom.createAttrMorph(element118, 'class');
        morphs[27] = dom.createElementMorph(element118);
        morphs[28] = dom.createMorphAt(element114,12,12);
        morphs[29] = dom.createAttrMorph(element119, 'class');
        morphs[30] = dom.createElementMorph(element119);
        morphs[31] = dom.createMorphAt(element114,16,16);
        morphs[32] = dom.createMorphAt(element114,17,17);
        morphs[33] = dom.createAttrMorph(element120, 'class');
        morphs[34] = dom.createElementMorph(element120);
        morphs[35] = dom.createElementMorph(element122);
        morphs[36] = dom.createAttrMorph(element123, 'class');
        morphs[37] = dom.createMorphAt(element123,1,1);
        morphs[38] = dom.createMorphAt(element123,3,3);
        morphs[39] = dom.createAttrMorph(element124, 'class');
        morphs[40] = dom.createMorphAt(element124,1,1);
        morphs[41] = dom.createMorphAt(element124,3,3);
        morphs[42] = dom.createMorphAt(element124,7,7);
        morphs[43] = dom.createAttrMorph(element125, 'class');
        morphs[44] = dom.createMorphAt(element125,1,1);
        morphs[45] = dom.createAttrMorph(element126, 'class');
        morphs[46] = dom.createAttrMorph(element129, 'class');
        morphs[47] = dom.createElementMorph(element129);
        morphs[48] = dom.createAttrMorph(element130, 'class');
        morphs[49] = dom.createElementMorph(element130);
        morphs[50] = dom.createAttrMorph(element131, 'class');
        morphs[51] = dom.createElementMorph(element131);
        morphs[52] = dom.createAttrMorph(element133, 'class');
        morphs[53] = dom.createElementMorph(element133);
        morphs[54] = dom.createAttrMorph(element134, 'class');
        morphs[55] = dom.createElementMorph(element134);
        morphs[56] = dom.createAttrMorph(element135, 'class');
        morphs[57] = dom.createElementMorph(element135);
        morphs[58] = dom.createAttrMorph(element136, 'class');
        morphs[59] = dom.createElementMorph(element136);
        morphs[60] = dom.createMorphAt(element126,3,3);
        morphs[61] = dom.createAttrMorph(element137, 'class');
        morphs[62] = dom.createMorphAt(element137,1,1);
        morphs[63] = dom.createAttrMorph(element138, 'class');
        morphs[64] = dom.createMorphAt(element138,1,1);
        morphs[65] = dom.createAttrMorph(element139, 'class');
        morphs[66] = dom.createMorphAt(element139,3,3);
        morphs[67] = dom.createAttrMorph(element140, 'class');
        morphs[68] = dom.createMorphAt(element140,1,1);
        morphs[69] = dom.createMorphAt(element140,3,3);
        morphs[70] = dom.createAttrMorph(element141, 'class');
        morphs[71] = dom.createMorphAt(element141,1,1);
        morphs[72] = dom.createAttrMorph(element142, 'class');
        morphs[73] = dom.createMorphAt(element142,1,1);
        morphs[74] = dom.createAttrMorph(element143, 'class');
        morphs[75] = dom.createMorphAt(dom.childAt(element143, [1, 1]),3,3);
        morphs[76] = dom.createMorphAt(dom.childAt(element144, [1, 3]),1,1);
        morphs[77] = dom.createMorphAt(dom.childAt(element144, [3]),1,1);
        morphs[78] = dom.createAttrMorph(element145, 'class');
        morphs[79] = dom.createMorphAt(dom.childAt(element145, [1, 1]),3,3);
        morphs[80] = dom.createMorphAt(element145,3,3);
        morphs[81] = dom.createAttrMorph(element146, 'class');
        morphs[82] = dom.createMorphAt(element146,1,1);
        morphs[83] = dom.createElementMorph(element148);
        morphs[84] = dom.createMorphAt(element148,1,1);
        morphs[85] = dom.createMorphAt(element147,3,3);
        morphs[86] = dom.createMorphAt(element147,4,4);
        morphs[87] = dom.createMorphAt(element147,5,5);
        morphs[88] = dom.createMorphAt(element147,6,6);
        morphs[89] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[90] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[91] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[92] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[93] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[94] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[95] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[96] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[97] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[98] = dom.createMorphAt(fragment,25,25,contextualElement);
        morphs[99] = dom.createMorphAt(fragment,27,27,contextualElement);
        morphs[100] = dom.createMorphAt(fragment,29,29,contextualElement);
        morphs[101] = dom.createMorphAt(fragment,31,31,contextualElement);
        morphs[102] = dom.createMorphAt(fragment,33,33,contextualElement);
        morphs[103] = dom.createMorphAt(fragment,35,35,contextualElement);
        morphs[104] = dom.createMorphAt(fragment,37,37,contextualElement);
        morphs[105] = dom.createMorphAt(fragment,39,39,contextualElement);
        morphs[106] = dom.createMorphAt(fragment,41,41,contextualElement);
        morphs[107] = dom.createMorphAt(fragment,43,43,contextualElement);
        morphs[108] = dom.createMorphAt(fragment,45,45,contextualElement);
        morphs[109] = dom.createMorphAt(fragment,47,47,contextualElement);
        morphs[110] = dom.createMorphAt(fragment,49,49,contextualElement);
        morphs[111] = dom.createMorphAt(fragment,51,51,contextualElement);
        morphs[112] = dom.createMorphAt(fragment,53,53,contextualElement);
        morphs[113] = dom.createMorphAt(fragment,55,55,contextualElement);
        morphs[114] = dom.createMorphAt(fragment,57,57,contextualElement);
        morphs[115] = dom.createMorphAt(fragment,59,59,contextualElement);
        morphs[116] = dom.createMorphAt(fragment,61,61,contextualElement);
        morphs[117] = dom.createMorphAt(fragment,63,63,contextualElement);
        morphs[118] = dom.createMorphAt(fragment,65,65,contextualElement);
        morphs[119] = dom.createMorphAt(fragment,67,67,contextualElement);
        morphs[120] = dom.createMorphAt(fragment,69,69,contextualElement);
        morphs[121] = dom.createMorphAt(fragment,71,71,contextualElement);
        morphs[122] = dom.createMorphAt(fragment,73,73,contextualElement);
        morphs[123] = dom.createMorphAt(fragment,75,75,contextualElement);
        morphs[124] = dom.createMorphAt(fragment,77,77,contextualElement);
        morphs[125] = dom.createMorphAt(fragment,79,79,contextualElement);
        morphs[126] = dom.createMorphAt(fragment,81,81,contextualElement);
        morphs[127] = dom.createMorphAt(fragment,83,83,contextualElement);
        morphs[128] = dom.createMorphAt(fragment,85,85,contextualElement);
        morphs[129] = dom.createMorphAt(fragment,87,87,contextualElement);
        morphs[130] = dom.createMorphAt(fragment,89,89,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,52],[1,65]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,31],[1,96]]]]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[3,14],[3,23]]]],"documents"],[],["loc",[null,[3,10],[3,36]]]]],[],0,null,["loc",[null,[3,4],[6,11]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[8,55],[8,68]]]],"10","16"],[],["loc",[null,[8,34],[8,80]]]],"/24"]]],
        ["block","if",[["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[14,48],[14,64]]]],["get","model",["loc",[null,[14,65],[14,70]]]]],[],["loc",[null,[14,16],[14,71]]]]],[],1,null,["loc",[null,[14,10],[26,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[27,18],[27,27]]]],"documents"],[],["loc",[null,[27,14],[27,40]]]]],[],2,null,["loc",[null,[27,8],[31,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,20],[32,29]]]],"appointments"],[],["loc",[null,[32,16],[32,45]]]]],[],3,null,["loc",[null,[32,10],[36,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[37,20],[37,29]]]],"lab"],[],["loc",[null,[37,16],[37,36]]]]],[],4,null,["loc",[null,[37,10],[41,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[42,20],[42,29]]]],"future"],[],["loc",[null,[42,16],[42,39]]]]],[],5,null,["loc",[null,[42,10],[52,17]]]],
        ["element","action",["openMergePatient"],[],["loc",[null,[53,13],[53,42]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[58,16],[58,25]]]],"documents"],[],["loc",[null,[58,12],[58,38]]]]],[],6,null,["loc",[null,[58,6],[63,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[64,16],[64,25]]]],"tasks"],[],["loc",[null,[64,12],[64,34]]]]],[],7,null,["loc",[null,[64,6],[68,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[69,16],[69,25]]]],"appointments"],[],["loc",[null,[69,12],[69,41]]]]],[],8,null,["loc",[null,[69,6],[72,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[73,16],[73,25]]]],"etiga"],[],["loc",[null,[73,12],[73,34]]]]],[],9,null,["loc",[null,[73,6],[76,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[77,16],[77,25]]]],"history"],[],["loc",[null,[77,12],[77,36]]]]],[],10,null,["loc",[null,[77,6],[81,13]]]],
        ["block","if",[["get","model.etigaActive",["loc",[null,[82,12],[82,29]]]]],[],11,null,["loc",[null,[82,6],[86,13]]]],
        ["element","action",["admissionPatient",["get","model.customerId",["loc",[null,[87,37],[87,53]]]],["get","model.id",["loc",[null,[87,54],[87,62]]]]],[],["loc",[null,[87,9],[87,65]]]],
        ["inline","button-admission",[],["color","#ffffff","size","32","showStroke",false,"content","Patient aufnehmen"],["loc",[null,[88,8],[88,99]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[93,48],[93,61]]]]],[],["loc",[null,[93,43],[93,62]]]],"24","18"],[],["loc",[null,[93,22],[93,74]]]],"/24@desk with-context-bar l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[93,150],[93,163]]]],"sidebar-active",""],[],["loc",[null,[93,129],[93,185]]]]," card site-content fadeIn"]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[99,88],[99,97]]]],"masterdata"],[],["loc",[null,[99,84],[99,111]]]],"is-active",""],[],["loc",[null,[99,63],[99,128]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[99,154],[99,189]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-journal-enabled",[],[],["loc",[null,[101,24],[101,44]]]],["subexpr","is-debug-user",[],[],["loc",[null,[101,45],[101,60]]]]],[],["loc",[null,[101,20],[101,61]]]]],[],12,null,["loc",[null,[101,14],[105,21]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[106,24],[106,41]]]],["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[106,47],[106,67]]]]],[],["loc",[null,[106,42],[106,68]]]],["subexpr","is-debug-user",[],[],["loc",[null,[106,69],[106,84]]]]],[],["loc",[null,[106,20],[106,85]]]]],[],13,null,["loc",[null,[106,14],[110,21]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[112,88],[112,97]]]],"future"],[],["loc",[null,[112,84],[112,107]]]],"is-active",""],[],["loc",[null,[112,63],[112,124]]]]]]],
        ["element","action",["selectTab","future"],[],["loc",[null,[112,145],[112,176]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[115,88],[115,97]]]],"lab"],[],["loc",[null,[115,84],[115,104]]]],"is-active",""],[],["loc",[null,[115,63],[115,121]]]]]]],
        ["element","action",["selectTab","lab"],[],["loc",[null,[115,139],[115,167]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[118,88],[118,97]]]],"documents"],[],["loc",[null,[118,84],[118,110]]]],"is-active",""],[],["loc",[null,[118,63],[118,127]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[118,151],[118,185]]]],
        ["block","if",[["subexpr","or",[["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[120,31],[120,51]]]]],[],["loc",[null,[120,26],[120,52]]]],["subexpr","is-debug-user",[],[],["loc",[null,[120,53],[120,68]]]]],[],["loc",[null,[120,22],[120,69]]]]],[],14,null,["loc",[null,[120,16],[124,23]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[126,88],[126,97]]]],"tasks"],[],["loc",[null,[126,84],[126,106]]]],"is-active",""],[],["loc",[null,[126,63],[126,123]]]]]]],
        ["element","action",["selectTab","tasks"],[],["loc",[null,[126,143],[126,173]]]],
        ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[128,20],[128,37]]]]],[],15,null,["loc",[null,[128,14],[132,21]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[133,20],[133,43]]]]],[],16,null,["loc",[null,[133,14],[137,21]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[139,88],[139,97]]]],"etiga"],[],["loc",[null,[139,84],[139,106]]]],"is-active",""],[],["loc",[null,[139,63],[139,123]]]]]]],
        ["element","action",["selectTab","etiga"],[],["loc",[null,[139,143],[139,173]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[144,58],[144,87]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[145,66],[145,75]]]],"masterdata"],[],["loc",[null,[145,62],[145,89]]]],"is-active",""],[],["loc",[null,[145,41],[145,106]]]]," mt30"]]],
        ["inline","patients-edit-form",[],["edit",true,"digitalRaceEnabled",true,"customerOverride",["subexpr","@mut",[["get","customerOverride",["loc",[null,[146,92],[146,108]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[146,115],[146,120]]]]],[],[]],"openChipPanel","openChipPanel","openLogbook","openEtigaMasterdataLogbook","changeUser","changeUser","etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[146,233],[146,249]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[146,258],[146,265]]]]],[],[]],"castrationOptions",["subexpr","@mut",[["get","castrationOptions",["loc",[null,[146,284],[146,301]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[146,313],[146,323]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[146,333],[146,341]]]]],[],[]],"insurers",["subexpr","@mut",[["get","insurers",["loc",[null,[146,351],[146,359]]]]],[],[]],"patientImage",["subexpr","@mut",[["get","patientImage",["loc",[null,[146,373],[146,385]]]]],[],[]],"imageChanged","changeImage","actionReceiver",["subexpr","@mut",[["get","patientEditForm",["loc",[null,[146,428],[146,443]]]]],[],[]]],["loc",[null,[146,20],[146,445]]]],
        ["block","if",[["subexpr","eq",[["get","model.category.id",["loc",[null,[147,30],[147,47]]]],"pferd"],[],["loc",[null,[147,26],[147,56]]]]],[],17,null,["loc",[null,[147,20],[150,27]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[153,62],[153,71]]]],"weight"],[],["loc",[null,[153,58],[153,81]]]],"is-active",""],[],["loc",[null,[153,37],[153,98]]]]]]],
        ["block","if",[["get","weightInfos",["loc",[null,[154,22],[154,33]]]]],[],18,null,["loc",[null,[154,16],[156,23]]]],
        ["inline","patient-weight-chart",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[157,49],[157,57]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[157,73],[157,84]]]]],[],[]]],["loc",[null,[157,16],[157,86]]]],
        ["inline","patient-weight",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[159,45],[159,56]]]]],[],[]],"edit","openWeight","delete","deleteWeight","editable",["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[159,137],[159,153]]]],["get","model",["loc",[null,[159,154],[159,159]]]]],[],["loc",[null,[159,106],[159,160]]]],"patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[159,171],[159,179]]]]],[],[]]],["loc",[null,[159,16],[159,181]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[161,60],[161,69]]]],"activities"],[],["loc",[null,[161,56],[161,83]]]],"is-active",""],[],["loc",[null,[161,35],[161,100]]]]]]],
        ["inline","patient-activities",[],["editNote","editMemo","editTreatment","editFullTreatment","openReportChooser","openReportChooser","openSlopingInfos","openSlopingInfos","openDocument","openDocument","gotoReport","gotoReport","gotoTemplate","gotoTemplate","openLab","openLab","openApp","openEditAppointment","doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[172,24],[172,31]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[173,27],[173,37]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[174,22],[174,27]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientActivities",["loc",[null,[175,31],[175,48]]]]],[],[]]],["loc",[null,[162,14],[175,50]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[177,60],[177,69]]]],"history"],[],["loc",[null,[177,56],[177,80]]]],"is-active",""],[],["loc",[null,[177,35],[177,97]]]]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[181,73],[181,84]]]],"all"],[],["loc",[null,[181,69],[181,91]]]],"selected",""],[],["loc",[null,[181,48],[181,108]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[181,110],[181,146]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[182,73],[182,84]]]],"visible"],[],["loc",[null,[182,69],[182,95]]]],"selected",""],[],["loc",[null,[182,48],[182,112]]]]]]],
        ["element","action",["toggleQuickFilter","visible"],[],["loc",[null,[182,114],[182,154]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[183,73],[183,84]]]],"deleted"],[],["loc",[null,[183,69],[183,95]]]],"selected",""],[],["loc",[null,[183,48],[183,112]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[183,114],[183,154]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterFood",["loc",[null,[187,69],[187,79]]]],"selected",""],[],["loc",[null,[187,48],[187,95]]]]]]],
        ["element","action",["toggleTabFilter","filterFood"],[],["loc",[null,[187,97],[187,138]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterServices",["loc",[null,[188,69],[188,83]]]],"selected",""],[],["loc",[null,[188,48],[188,99]]]]]]],
        ["element","action",["toggleTabFilter","filterServices"],[],["loc",[null,[188,101],[188,146]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterProducts",["loc",[null,[189,69],[189,83]]]],"selected",""],[],["loc",[null,[189,48],[189,99]]]]]]],
        ["element","action",["toggleTabFilter","filterProducts"],[],["loc",[null,[189,101],[189,146]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterLab",["loc",[null,[190,69],[190,78]]]],"selected",""],[],["loc",[null,[190,48],[190,94]]]]]]],
        ["element","action",["toggleTabFilter","filterLab"],[],["loc",[null,[190,96],[190,136]]]],
        ["inline","treatment-history",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[195,22],[195,27]]]]],[],[]],"showCopy",false,"showEdit",true,"showHide",true,"showHideApp",true,"showFilter",false,"users",["subexpr","@mut",[["get","users",["loc",[null,[201,22],[201,27]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[202,33],[202,49]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[203,24],[203,31]]]]],[],[]],"reload","reloadHistory","gotoReport","gotoReport","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[206,22],[206,27]]]]],[],[]],"showPager",true,"reload","reloadHistory","assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[209,27],[209,37]]]]],[],[]],"openDigitalForm","openDigitalForm","employeesSaved","employeesSaved","openSlopingInfos","openSlopingInfos","roomSaved","roomSaved","downloadSlopingBarcode","selectSlopingBarcodeForInvoice","deleteTreatment","deleteTreatment","editTreatmentPanel","editTreatmentPanel","treatmentSaved","saveTreatment","openTemplateEdit","openTemplateEdit","forwardToBilling","forwardToBilling","openLaboklinChoosePanel","openLaboklinChoosePanel","items",["subexpr","@mut",[["get","historicTreatments",["loc",[null,[221,22],[221,40]]]]],[],[]]],["loc",[null,[194,14],[221,42]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[223,62],[223,71]]]],"future"],[],["loc",[null,[223,58],[223,81]]]],"is-active",""],[],["loc",[null,[223,37],[223,98]]]]]]],
        ["inline","treatment-future",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[225,22],[225,27]]]]],[],[]],"items",["subexpr","@mut",[["get","futureItems",["loc",[null,[226,22],[226,33]]]]],[],[]],"crud",false,"editFuture","editFuture","reload","reloadFuture","moveFuture","moveFuture","addDirectNotification","openAddDirectNotification","addDirectAppNotification","openAddAppDirectNotification","addFutureAction","addFutureAction","deleteFutureEntry","deleteFuture","actionReceiver",["subexpr","@mut",[["get","treatmentFuturePanel",["loc",[null,[235,29],[235,49]]]]],[],[]]],["loc",[null,[224,14],[235,51]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[237,62],[237,71]]]],"lab"],[],["loc",[null,[237,58],[237,78]]]],"is-active",""],[],["loc",[null,[237,37],[237,95]]]]]]],
        ["inline","laboklin-historic-forms",[],["actionReceiver",["subexpr","@mut",[["get","historicItems",["loc",[null,[238,57],[238,70]]]]],[],[]],"downloadBarcode","selectLaboklinBarcode","editable",["subexpr","is-etiga-editable",["addLabor",["get","etigaPermissions",["loc",[null,[240,81],[240,97]]]],["get","model",["loc",[null,[240,98],[240,103]]]]],[],["loc",[null,[240,51],[240,104]]]],"showSendAll",false,"showEdit",true,"size",40,"downloadMedia","downloadMedia","editLaboklinForm","editLaboklinForm","downloadVetconnectResult","downloadVetconnectResult","openResult","openResult","downloadResult","downloadResult","downloadPdf","downloadPdf","forwardEmail","openEmailForwardPanel","deleteReport","deleteReport","submitLaboklin","submitLaboklin","reload","reloadLaboklin","labItems",["subexpr","@mut",[["get","labItems",["loc",[null,[254,51],[254,59]]]]],[],[]]],["loc",[null,[238,16],[254,61]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[256,62],[256,71]]]],"medias"],[],["loc",[null,[256,58],[256,81]]]],"is-active",""],[],["loc",[null,[256,37],[256,98]]]]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[258,39],[258,45]]]]],[],[]],"deleteMedia","deleteMedia","editable",["subexpr","is-etiga-editable",["addDocs",["get","etigaPermissions",["loc",[null,[258,110],[258,126]]]],["get","model",["loc",[null,[258,127],[258,132]]]]],[],["loc",[null,[258,81],[258,133]]]],"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[258,16],[258,204]]]],
        ["attribute","class",["concat",["pl0 pr0 tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[260,70],[260,79]]]],"documents"],[],["loc",[null,[260,66],[260,92]]]],"is-active",""],[],["loc",[null,[260,45],[260,109]]]]]]],
        ["block","if",[["get","showFilters",["loc",[null,[261,22],[261,33]]]]],[],19,null,["loc",[null,[261,16],[266,23]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[272,31],[272,45]]]]],[],[]]],["loc",[null,[267,14],[272,47]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[274,62],[274,71]]]],"correspondance"],[],["loc",[null,[274,58],[274,89]]]],"is-active",""],[],["loc",[null,[274,37],[274,106]]]]," mt30"]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[275,48],[275,62]]]]],[],[]]],["loc",[null,[275,16],[275,64]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[277,62],[277,71]]]],"tasks"],[],["loc",[null,[277,58],[277,80]]]],"is-active",""],[],["loc",[null,[277,37],[277,97]]]]," mt30"]]],
        ["inline","patient-tasks",[],["items",["subexpr","@mut",[["get","tasks",["loc",[null,[278,36],[278,41]]]]],[],[]],"startTask","startTask","createNotification","addMessage","showNotification","showNotification","finishTask","finishTask","showTask","showTask","startTask","startTask","changeOwner","changeOwner","setSortDir","setSortDir"],["loc",[null,[278,14],[286,55]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[288,60],[288,69]]]],"todo"],[],["loc",[null,[288,56],[288,77]]]],"is-active",""],[],["loc",[null,[288,35],[288,94]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","todoStatus",["loc",[null,[293,34],[293,44]]]]],[],[]],"value",["subexpr","@mut",[["get","filterTodoStatus",["loc",[null,[294,32],[294,48]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",true,"placeholder","Alle","cssClass","input--dropdown"],["loc",[null,[292,18],[300,20]]]],
        ["block","each",[["get","todos",["loc",[null,[313,28],[313,33]]]]],[],20,null,["loc",[null,[313,20],[340,29]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","todos",["loc",[null,[344,41],[344,46]]]]],[],[]],"numPagesToShow",7],["loc",[null,[344,18],[344,65]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[348,62],[348,71]]]],"appointments"],[],["loc",[null,[348,58],[348,87]]]],"is-active",""],[],["loc",[null,[348,37],[348,104]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[353,28],[353,56]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[354,26],[354,40]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle"],["loc",[null,[352,18],[360,20]]]],
        ["inline","patient-appointments",[],["startTreatment","admissionPatient","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[364,202],[364,214]]]]],[],[]]],["loc",[null,[364,14],[364,216]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[366,62],[366,71]]]],"etiga"],[],["loc",[null,[366,58],[366,80]]]],"is-active",""],[],["loc",[null,[366,37],[366,97]]]]]]],
        ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[367,20],[367,37]]]]],[],21,22,["loc",[null,[367,14],[386,21]]]],
        ["element","action",["back"],[],["loc",[null,[392,48],[392,65]]]],
        ["content","button-prev",["loc",[null,[393,8],[393,23]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","selectTab",["loc",[null,[395,21],[395,30]]]],"masterdata"],[],["loc",[null,[395,17],[395,44]]]],["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[395,77],[395,93]]]],["get","model",["loc",[null,[395,94],[395,99]]]]],[],["loc",[null,[395,45],[395,100]]]]],[],["loc",[null,[395,12],[395,101]]]]],[],23,null,["loc",[null,[395,6],[399,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[400,16],[400,25]]]],"etiga"],[],["loc",[null,[400,12],[400,34]]]]],[],24,null,["loc",[null,[400,6],[404,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[405,48],[405,64]]]],["get","model",["loc",[null,[405,65],[405,70]]]]],[],["loc",[null,[405,17],[405,71]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[405,76],[405,85]]]],"weight"],[],["loc",[null,[405,72],[405,95]]]]],[],["loc",[null,[405,12],[405,96]]]]],[],25,null,["loc",[null,[405,6],[412,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[413,16],[413,25]]]],"medias"],[],["loc",[null,[413,12],[413,35]]]]],[],26,null,["loc",[null,[413,6],[417,13]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[420,9],[420,22]]]]],[],27,28,["loc",[null,[420,3],[823,7]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[825,40],[825,60]]]]],[],[]]],["loc",[null,[825,0],[825,62]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[826,36],[826,46]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[826,55],[826,60]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[826,77],[826,92]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[826,0],[826,122]]]],
        ["inline","customer-reduction-panel",[],["actionReceiver",["subexpr","@mut",[["get","setCustomer",["loc",[null,[827,42],[827,53]]]]],[],[]]],["loc",[null,[827,0],[827,55]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[830,37],[830,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[830,0],[830,76]]]],
        ["inline","finish-task-panel",[],["confirm","finishTaskSend","actionReceiver",["subexpr","@mut",[["get","finishTaskPanel",["loc",[null,[832,60],[832,75]]]]],[],[]]],["loc",[null,[832,0],[832,77]]]],
        ["inline","change-task-owner-panel",[],["confirm","changedTaskOwner","users",["subexpr","@mut",[["get","users",["loc",[null,[833,59],[833,64]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","changeTaskOwnerPanel",["loc",[null,[833,80],[833,100]]]]],[],[]]],["loc",[null,[833,0],[833,102]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[837,34],[837,39]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[837,48],[837,55]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[837,67],[837,77]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[837,88],[837,97]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[837,104],[837,109]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[837,119],[837,127]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[837,170],[837,189]]]]],[],[]]],["loc",[null,[837,4],[837,191]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[839,23],[839,28]]]]],[],[]],"create","create","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[839,92],[839,104]]]]],[],[]]],["loc",[null,[839,0],[839,106]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[840,33],[840,46]]]]],[],[]],"linkTo","linkTo","goto","goto","create","createNotification","addReply","addReply","startTask","startTask","finishTask","finishTask","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[840,0],[840,220]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[841,40],[841,60]]]]],[],[]]],["loc",[null,[841,0],[841,62]]]],
        ["inline","show-notification-panel",[],["actionReceiver",["subexpr","@mut",[["get","showNotificationPanel",["loc",[null,[843,41],[843,62]]]]],[],[]]],["loc",[null,[843,0],[843,64]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[845,31],[845,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[845,46],[845,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[845,63],[845,70]]]]],[],[]],"create","createNotification","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[845,136],[845,156]]]]],[],[]]],["loc",[null,[845,0],[845,158]]]],
        ["inline","etiga-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaLogbookPanel",["loc",[null,[846,37],[846,54]]]]],[],[]]],["loc",[null,[846,0],[846,56]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadPatientBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[848,80],[848,105]]]]],[],[]]],["loc",[null,[848,0],[848,107]]]],
        ["inline","laboklin-select-barcode-panel",[],["download","downloadLaboklinBarcode","actionReceiver",["subexpr","@mut",[["get","laboklinSelectBarcodePanel",["loc",[null,[849,82],[849,108]]]]],[],[]]],["loc",[null,[849,0],[849,110]]]],
        ["inline","choose-existing-etiga-panel",[],["createNew","createNew","select","connectToExistingEtigaCustomer","actionReceiver",["subexpr","@mut",[["get","existingEtigaPanel",["loc",[null,[850,107],[850,125]]]]],[],[]]],["loc",[null,[850,0],[850,127]]]],
        ["inline","sloping-info-panel",[],["save","saveSlopingInfo","gotoProduct","gotoProduct","gotoService","gotoService","selectBarcode","downloadSlopingBarcode","actionReceiver",["subexpr","@mut",[["get","slopingInfoPanel",["loc",[null,[851,150],[851,166]]]]],[],[]]],["loc",[null,[851,0],[851,168]]]],
        ["inline","edit-medical-problems-panel",[],["confirm","saveMedicalProblemsInfo","openEditDosing","openEditDosing","refresh","refreshPastDosings","actionReceiver",["subexpr","@mut",[["get","medicalProblemsPanel",["loc",[null,[852,140],[852,160]]]]],[],[]]],["loc",[null,[852,0],[852,162]]]],
        ["inline","edit-past-actions-panel",[],["save","savePastAction","actionReceiver",["subexpr","@mut",[["get","editPastActionsPanel",["loc",[null,[853,63],[853,83]]]]],[],[]]],["loc",[null,[853,0],[853,85]]]],
        ["inline","edit-lab-date-panel",[],["confirm","changeLabDate","actionReceiver",["subexpr","@mut",[["get","changeLabDatePanel",["loc",[null,[855,61],[855,79]]]]],[],[]]],["loc",[null,[855,0],[855,81]]]],
        ["inline","digital-form-panel",[],["confirm","updateDigitalForm","actionReceiver",["subexpr","@mut",[["get","digitalFormPanel",["loc",[null,[859,25],[859,41]]]]],[],[]]],["loc",[null,[857,0],[859,43]]]],
        ["inline","add-etiga-share",[],["etigaShareInfos",["subexpr","@mut",[["get","etigaShareInfos",["loc",[null,[862,18],[862,33]]]]],[],[]],"select","addEtigaPermisison","remove","removeEtigaShare","actionReceiver",["subexpr","@mut",[["get","addEtigaShare",["loc",[null,[865,17],[865,30]]]]],[],[]]],["loc",[null,[861,0],[865,32]]]],
        ["inline","digital-helper-qr-panel",[],["actionReceiver",["subexpr","@mut",[["get","digitalHelperPanel",["loc",[null,[867,41],[867,59]]]]],[],[]]],["loc",[null,[867,0],[867,61]]]],
        ["inline","laboklin-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","laboklinTreatmentPanel",["loc",[null,[880,17],[880,39]]]]],[],[]]],["loc",[null,[869,0],[880,41]]]],
        ["inline","private-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","downloadResult","downloadResult","openLabResult","openLabResult","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","privateTreatmentPanel",["loc",[null,[895,17],[895,38]]]]],[],[]]],["loc",[null,[882,0],[895,40]]]],
        ["inline","lab-result-panel",[],["changeParamValue","changeParamValue","downloadResult","downloadResult","openChart","openChart","selectBarcode","selectBarcode","dateChanged","openChangeDate","save","saveLabResult","actionReceiver",["subexpr","@mut",[["get","labResultPanel",["loc",[null,[903,17],[903,31]]]]],[],[]]],["loc",[null,[896,0],[903,33]]]],
        ["inline","all-lab-result-panel",[],["openChart","openChart","actionReceiver",["subexpr","@mut",[["get","allLabResultPanel",["loc",[null,[906,17],[906,34]]]]],[],[]]],["loc",[null,[904,0],[906,36]]]],
        ["inline","lab-result-chart-panel",[],["back","openResult","actionReceiver",["subexpr","@mut",[["get","labResultChartPanel",["loc",[null,[909,17],[909,36]]]]],[],[]]],["loc",[null,[907,0],[909,38]]]],
        ["inline","etiga-masterdata-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaMasterdataLogbookPanel",["loc",[null,[911,17],[911,44]]]]],[],[]]],["loc",[null,[910,0],[911,46]]]],
        ["inline","patient-chip-panel",[],["genders",["subexpr","@mut",[["get","genders",["loc",[null,[914,12],[914,19]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientChipPanel",["loc",[null,[915,19],[915,35]]]]],[],[]]],["loc",[null,[913,2],[915,37]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[921,8],[921,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[922,10],[922,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[923,13],[923,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[924,17],[924,36]]]]],[],[]]],["loc",[null,[917,2],[924,38]]]],
        ["inline","set-death-day-panel",[],["confirm","updateDeathDate","remove","removeDeathDate","actionReceiver",["subexpr","@mut",[["get","deathDayPanel",["loc",[null,[926,88],[926,101]]]]],[],[]]],["loc",[null,[926,0],[926,103]]]],
        ["inline","treatment-weight-panel",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[927,37],[927,48]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[927,57],[927,62]]]]],[],[]],"reloadWeightData","reloadWeightData","reloadPatient","reloadPatient","reopen","openWeightPanel","actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[927,169],[927,180]]]]],[],[]]],["loc",[null,[927,0],[927,182]]]],
        ["inline","add-direct-app-notification-panel",[],["showTitle",false,"reload","reload","create","addDirectAppNotification","actionReceiver",["subexpr","@mut",[["get","addDirectAppNotification",["loc",[null,[928,117],[928,141]]]]],[],[]]],["loc",[null,[928,0],[928,143]]]],
        ["inline","add-direct-notification-panel",[],["reload","reload","create","addDirectNotification","users",["subexpr","@mut",[["get","users",["loc",[null,[929,85],[929,90]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[929,100],[929,108]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[929,117],[929,124]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","addDirectNotification",["loc",[null,[929,140],[929,161]]]]],[],[]]],["loc",[null,[929,0],[929,163]]]],
        ["inline","treatment-info-form-clone",[],["treatmentSaved","saveTreatment","actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[930,74],[930,94]]]]],[],[]]],["loc",[null,[930,0],[930,96]]]],
        ["inline","template-edit-panel",[],["download","downloadTemplate","reloadTemplate","reloadTemplate","actionReceiver",["subexpr","@mut",[["get","templateEditPanel",["loc",[null,[931,97],[931,114]]]]],[],[]]],["loc",[null,[931,0],[931,116]]]],
        ["inline","select-customer-panel",[],["select","changePatientOwner","actionReceiver",["subexpr","@mut",[["get","selectCustomerPanel",["loc",[null,[932,67],[932,86]]]]],[],[]]],["loc",[null,[932,0],[932,88]]]],
        ["inline","edit-todo-panel",[],["confirm","saveTodoInstance","actionReceiver",["subexpr","@mut",[["get","editTodoPanel",["loc",[null,[933,60],[933,73]]]]],[],[]]],["loc",[null,[933,0],[933,75]]]],
        ["inline","email-etiga-panel",[],["confirm","sendEtigaMail","openAddAppDirectNotification","openDirectMessage","qrDownload","downloadEtigaQr","actionReceiver",["subexpr","@mut",[["get","emailEtigaPanel",["loc",[null,[935,137],[935,152]]]]],[],[]]],["loc",[null,[935,0],[935,154]]]],
        ["inline","merge-patient-panel",[],["gotoPatient","gotoPatient","actionReceiver",["subexpr","@mut",[["get","mergePatientPanel",["loc",[null,[937,63],[937,80]]]]],[],[]]],["loc",[null,[937,0],[937,82]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28]
    };
  }()));

});