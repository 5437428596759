define('jason-frontend/helpers/show-treatment-patient-tile', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.showTreatmentPatientTile = showTreatmentPatientTile;

  function showTreatmentPatientTile(params) {
    var patientInfo = params[0],
        selectedUsers = params[1],
        filterName = params[2];

    var userShow = true;
    if (selectedUsers && selectedUsers.length) {
      var doctor = selectedUsers.some(function (u) {
        return patientInfo.doctor && parseInt(u) === parseInt(patientInfo.doctor.id);
      });
      var assistent = selectedUsers.some(function (u) {
        return patientInfo.assistant && parseInt(u) === parseInt(patientInfo.assistant.id);
      });
      var notAssigned = selectedUsers.some(function (u) {
        return parseInt(u) === 9998 && patientInfo.doctor === null && patientInfo.assistant === null;
      });
      userShow = assistent || doctor || notAssigned;
    }

    var showFiltered = true;
    if (filterName && filterName.length > 0 && patientInfo.patient && patientInfo.patientOwner) {
      showFiltered = patientInfo.patientOwner.firstname.toLowerCase().includes(filterName.toLowerCase()) || patientInfo.patientOwner.lastname.toLowerCase().includes(filterName.toLowerCase()) || patientInfo.patient.name.toLowerCase().includes(filterName.toLowerCase());
    }
    return userShow && showFiltered;
  }

  exports['default'] = Ember['default'].Helper.helper(showTreatmentPatientTile);

});