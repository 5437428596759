define('jason-frontend/components/edit-invoice-status-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    invoiceStatusList: [{ name: "Ausgestellt", id: "to_be_verified" }, { name: "Bereit für Inkasso", id: "ready_for_money_collection" }, { name: "An Inkasso übergeben", id: "money_collection" }, { name: "An Rechtsanwalt übergeben", id: "lawyer" }, { name: "Keine Mahnung", id: "no_warning" }, { name: "SEPA", id: "sepa" }],
    actions: {
      confirm: function confirm(invoice) {
        var nextChange = moment(invoice.get('nextStatusChangeInput'), 'DD. MM. YYYY');

        var desiredState = invoice.get('nextStatusInput').id;

        if (desiredState === 'to_be_verified' && nextChange.isBefore(moment())) {
          jason.notifiction.error('Fehler', 'Das Fälligkeitsdatum darf nicht in der Vergangenheit liegen');
          return;
        }

        var data = {
          id: invoice.id,
          nextChange: nextChange.toDate(),
          statusId: desiredState
        };

        this.sendAction('confirm', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(invoice) {
        this.set('invoice', invoice);

        if (invoice.get('nextStatusChange')) {
          invoice.set('nextStatusChangeInput', moment(invoice.get('nextStatusChange')).format('DD. MM. YYYY'));
        }
      }
    }
  });

});