define('jason-frontend/routes/welcome/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('doctors', this.store.find('user', { role: 'doctor' }));
      controller.set('assistants', this.store.find('user', { role: 'assistant' }));

      $.ajax({
        url: "/api/statistics/dashboard/practice",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('liveData', data);
      });
      $.ajax({
        url: "/api/statistics/dashboard/treatments",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('treatmentData', data);
      });
      $.ajax({
        url: "/api/statistics/dashboard/appointments",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('appointmentData', data);
      });

      $.ajax({
        url: "/api/statistics/dashboard/revenue",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('revenueData', data);
      });
      this.loadPracticeData();
      this.loadInvoiceData();

      var self = this;
      setTimeout(function () {
        self.loadCustomersChart();
        self.loadRevenueChart();
        self.loadReminderData();
      }, 500);
    },
    loadCustomersChart: function loadCustomersChart() {

      var charts = $('div#customerBarChart');
      var controller = this.get('controller');

      $.ajax({
        url: "/api/statistics/dashboard/customers/history",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        if (data.entries && data.entries.length > 0) {
          var csv, label;

          var _ret = (function () {

            controller.set('hideCustomerChart', false);

            var labels = [];
            var customers = [];
            var treatments = [];

            var dataString = "Categories,Behandlungen,Kunden\n";

            data.entries.forEach(function (entry) {
              labels.push(entry.date);
              customers.push(entry.customers);
              treatments.push(entry.treatments);
              dataString += entry.date + "," + entry.treatments + "," + entry.customers + "\n";
            });

            csv = dataString;
            label = null;

            if (!csv) {
              return {
                v: undefined
              };
            }

            charts.show();

            Highcharts.setOptions({
              lang: {
                numericSymbols: null,
                decimalPoint: ',',
                thousandsSep: '.',
                months: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
              }
            });

            charts.highcharts({
              colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
              chart: {
                type: 'column'
              },
              data: {
                csv: csv
              },
              title: {
                text: ''
              },
              labels: {
                items: [{
                  html: label,
                  style: {
                    color: '#ADCCD4',
                    top: '3px',
                    left: '200px',
                    fontSize: '18px'
                  }
                }]
              },
              yAxis: {
                title: {
                  text: ''
                }
              }
            });
          })();

          if (typeof _ret === 'object') return _ret.v;
        } else {
          controller.set('hideCustomerChart', true);
        }
      });
    },
    loadRevenueChart: function loadRevenueChart() {

      var charts = $('div#revenueBarChart');
      var controller = this.get('controller');
      $.ajax({
        url: "/api/statistics/dashboard/revenue/history",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        if (data.entries && data.entries.length > 0) {
          var csv, label;

          var _ret2 = (function () {

            controller.set('hideRevenueChart', false);

            var labels = [];
            var values = [];

            var dataString = "Categories,Umsatz Netto\n";
            // "Monat,Umsatz 20% netto,Umsatz 13% netto,Umsatz 10% netto,Umsatz 0% netto,Umsatz gesamt netto\n1704070800000,483,49,0,0,533\n1701392400000,0,0,0,0,0\n1698800400000,1982,341,23,21,2367\n1696118400000,970,293,20,0,1283\n1693526400000,451,17,0,35,504\n1690848000000,769,18,400,20,1207\n"

            data.entries.forEach(function (entry) {
              labels.push(entry.date);
              values.push(entry.value);
              dataString += entry.date + "," + entry.value + "\n";
            });

            csv = dataString;
            label = null;

            if (!csv) {
              return {
                v: undefined
              };
            }

            charts.show();

            Highcharts.setOptions({
              lang: {
                numericSymbols: null,
                decimalPoint: ',',
                thousandsSep: '.',
                months: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
              }
            });

            charts.highcharts({
              colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
              // chart: {
              //   type: 'column'
              // },
              data: {
                csv: csv
              },
              title: {
                text: ''
              },
              labels: {
                items: [{
                  html: label,
                  style: {
                    color: '#ADCCD4',
                    top: '3px',
                    left: '200px',
                    fontSize: '18px'
                  }
                }]
              },
              yAxis: {
                title: {
                  text: ''
                }
              },
              tooltip: {
                formatter: function formatter() {
                  return 'Umsatz: € ' + this.y.toFixed(2) + "".replace('.', ',');
                }
              }
            });
          })();

          if (typeof _ret2 === 'object') return _ret2.v;
        } else {
          controller.set('hideRevenueChart', true);
        }
      });
    },
    loadPracticeData: function loadPracticeData() {

      var controller = this.get('controller');

      $.ajax({
        url: "/api/treatments/state/" + controller.get('selectedTabPractice'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('practiceItems', data.treatment);
      }, function (error) {});
    },
    loadReminderData: function loadReminderData() {
      var controller = this.get('controller');
      var tab = controller.get('selectedTabReminders'),
          self = this;
      // var from = moment(dateFromTo[0], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      //   to = moment(dateFromTo[1], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      var from = moment().startOf('day').format('YYYY-MM-DD');
      var to = moment().startOf('day').add('month', 1).format('YYYY-MM-DD');

      if (tab === 'past') {
        to = moment().format('YYYY-MM-DD');
        from = moment().startOf('day').subtract('month', 1).format('YYYY-MM-DD');
      }

      this.findPaged('reminder', {
        filterFrom: from,
        filterTo: to,
        type: tab,
        per_page: 5
      }).then(function (data) {
        var meta = data.meta;
        controller.set('reminderItems', data);
      });
    },
    loadInvoiceData: function loadInvoiceData() {

      var controller = this.get('controller');
      var tab = controller.get('selectedTabInvoices');

      if (tab === 'open') {
        $.ajax({
          url: "/api/invoices/open",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('invoiceItems', data.invoice);
        }, function (error) {});
      } else if (tab === 'recent') {
        //last week

        var filterTo = moment().format('YYYY-MM-DD');
        var filterFrom = moment().subtract('month', 1).format('YYYY-MM-DD');

        $.ajax({
          url: "/api/invoices?per_page=10&page=1&sortBy=created&sortDir=desc&filterFrom=" + filterFrom + "&filterTo=" + filterTo + "&filterCode=&filterCustomer=&filterPaymentStatus=partpaid,notpaid,paid&filterStatus=closed,to_be_verified,warning1,warning2,sepa,ready_for_money_collection,lawyer,no_warning,money_collection,canceled,closed,written_off",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('invoiceItems', data.invoice);
        }, function (error) {});
      }
    },
    actions: {
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
          } else {
            self.transitionTo('practicemgmt.admission');
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      admissionPatient: function admissionPatient(customerId, patientId, app) {

        var self = this;
        var patients = [patientId];
        var controller = this.get('controller');

        controller.get('startTreatmentPanel').send('load', customerId, patients, app);

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      gotoCustomer: function gotoCustomer(id) {
        this.transitionTo('customers.edit', id);
      },
      gotoPatient: function gotoPatient(id) {
        this.transitionTo('patients.edit', id);
      },
      gotoTreatmentJournal: function gotoTreatmentJournal() {
        this.transitionTo('practicemgmt.treatment-journal');
      },
      gotoSalesDashboard: function gotoSalesDashboard() {
        this.transitionTo('sale.dashboard');
      },
      gotoCalendar: function gotoCalendar() {
        this.transitionTo('appointments.calendar');
      },
      selectTabPractice: function selectTabPractice(tab) {
        this.get('controller').set('selectedTabPractice', tab);
        this.loadPracticeData();
      },
      selectTabInvoices: function selectTabInvoices(tab) {
        this.get('controller').set('selectedTabInvoices', tab);
        this.loadInvoiceData();
      },
      selectTabReminders: function selectTabReminders(tab) {
        this.get('controller').set('selectedTabReminders', tab);
        this.loadReminderData();
      }
    }
  });

  // var csv = data.saleBar.data,

});