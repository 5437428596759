define('jason-frontend/templates/practicemgmt/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 10
                },
                "end": {
                  "line": 45,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element52 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element52, 'title');
              morphs[1] = dom.createAttrMorph(element52, 'class');
              morphs[2] = dom.createAttrMorph(element52, 'style');
              morphs[3] = dom.createElementMorph(element52);
              morphs[4] = dom.createMorphAt(dom.childAt(element52, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[38,26],[38,40]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[39,59],[39,72]]]],"selected",""],[],["loc",[null,[39,38],[40,63]]]]," symbol symbol-circle symbol-40px"]]],
              ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[41,44],[41,54]]]]," !important;"]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[38,70],[38,74]]]]],[],["loc",[null,[38,44],[38,76]]]],
              ["content","user.capitals",["loc",[null,[42,40],[42,57]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 14
                  },
                  "end": {
                    "line": 53,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 14
                  },
                  "end": {
                    "line": 56,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","user.capitals",["loc",[null,[54,42],[54,59]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 10
                },
                "end": {
                  "line": 58,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element51 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element51, 'title');
              morphs[1] = dom.createAttrMorph(element51, 'class');
              morphs[2] = dom.createElementMorph(element51);
              morphs[3] = dom.createMorphAt(element51,1,1);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[46,26],[46,40]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[47,59],[47,72]]]],"selected",""],[],["loc",[null,[47,38],[48,63]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[48,99],[48,109]]]]]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[46,70],[46,74]]]]],[],["loc",[null,[46,44],[46,76]]]],
              ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[49,24],[49,31]]]],"9998"],[],["loc",[null,[49,20],[49,39]]]]],[],0,1,["loc",[null,[49,14],[56,21]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 8
              },
              "end": {
                "line": 59,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[37,16],[37,29]]]]],[],0,1,["loc",[null,[37,10],[58,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 6
            },
            "end": {
              "line": 60,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.active",["loc",[null,[36,14],[36,25]]]]],[],0,null,["loc",[null,[36,8],[59,15]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 9
            },
            "end": {
              "line": 65,
              "column": 116
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Entwürf(e)");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["content","invoiceMeta.total_records",["loc",[null,[65,69],[65,98]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 91,
                  "column": 24
                },
                "end": {
                  "line": 92,
                  "column": 118
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[92,64],[92,91]]]],
              ["content","item.customer.lastname",["loc",[null,[92,92],[92,118]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 24
                  },
                  "end": {
                    "line": 95,
                    "column": 256
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","animal-icon");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[95,115],[95,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[95,155],[95,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[95,200],[95,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[95,83],[95,252]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 96,
                    "column": 22
                  },
                  "end": {
                    "line": 96,
                    "column": 149
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[96,92],[96,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[96,125],[96,147]]]]],[],[]]],["loc",[null,[96,73],[96,149]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 22
                  },
                  "end": {
                    "line": 97,
                    "column": 190
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[97,61],[97,190]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 103,
                        "column": 28
                      },
                      "end": {
                        "line": 108,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element39 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element39, 'title');
                    morphs[1] = dom.createAttrMorph(element39, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[104,44],[104,72]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[104,162],[104,186]]]]," !important;"]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[105,60],[105,91]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 28
                      },
                      "end": {
                        "line": 113,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element38 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element38, 'title');
                    morphs[1] = dom.createAttrMorph(element38, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[109,44],[109,72]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[109,122],[109,149]]]],"selected",""],[],["loc",[null,[109,101],[109,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[109,201],[109,225]]]]]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[110,60],[110,91]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 102,
                      "column": 26
                    },
                    "end": {
                      "line": 114,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[103,34],[103,61]]]]],[],0,1,["loc",[null,[103,28],[113,35]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 116,
                        "column": 28
                      },
                      "end": {
                        "line": 121,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element37 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element37, 'title');
                    morphs[1] = dom.createAttrMorph(element37, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[117,44],[117,75]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[117,165],[117,192]]]]," !important;"]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[118,62],[118,96]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 121,
                        "column": 28
                      },
                      "end": {
                        "line": 126,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element36 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element36, 'title');
                    morphs[1] = dom.createAttrMorph(element36, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[122,44],[122,75]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[122,125],[122,155]]]],"selected",""],[],["loc",[null,[122,104],[122,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[122,207],[122,234]]]]]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[123,62],[123,96]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 115,
                      "column": 26
                    },
                    "end": {
                      "line": 127,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[116,34],[116,64]]]]],[],0,1,["loc",[null,[116,28],[126,35]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 100,
                    "column": 24
                  },
                  "end": {
                    "line": 128,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.doctor",["loc",[null,[102,32],[102,50]]]]],[],0,null,["loc",[null,[102,26],[114,33]]]],
                ["block","if",[["get","patientInfo.assistant",["loc",[null,[115,32],[115,53]]]]],[],1,null,["loc",[null,[115,26],[127,33]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 93,
                  "column": 18
                },
                "end": {
                  "line": 135,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","flex-center mb5 mt5");
              dom.setAttribute(el1,"style","flex-direction: row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-group");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element40 = dom.childAt(fragment, [1]);
              var element41 = dom.childAt(element40, [9]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(element40,1,1);
              morphs[1] = dom.createMorphAt(element40,3,3);
              morphs[2] = dom.createMorphAt(element40,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element40, [7]),1,1);
              morphs[4] = dom.createElementMorph(element41);
              morphs[5] = dom.createMorphAt(element41,1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","patientInfo.patient.category",["loc",[null,[95,30],[95,58]]]]],[],0,null,["loc",[null,[95,24],[95,263]]]],
              ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[96,49],[96,71]]]]],[],1,null,["loc",[null,[96,22],[96,161]]]],
              ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[97,28],[97,59]]]]],[],2,null,["loc",[null,[97,22],[97,197]]]],
              ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[100,34],[100,55]]]],["get","patientInfo.doctor",["loc",[null,[100,56],[100,74]]]]],[],["loc",[null,[100,30],[100,75]]]]],[],3,null,["loc",[null,[100,24],[128,31]]]],
              ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[131,133],[131,149]]]],["get","patientInfo.patient.id",["loc",[null,[131,150],[131,172]]]],["get","item.appointmentId",["loc",[null,[131,173],[131,191]]]]],[],["loc",[null,[131,105],[131,193]]]],
              ["inline","button-admission",[],["size","32","color","#fff","showStroke",false,"content","Check-In"],["loc",[null,[132,24],[132,103]]]]
            ],
            locals: ["patientInfo"],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 84,
                "column": 12
              },
              "end": {
                "line": 138,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
            dom.setAttribute(el2,"data-label","Termin");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Uhr");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-16/24");
            dom.setAttribute(el2,"data-label","Kund:in");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element42 = dom.childAt(fragment, [1]);
            var element43 = dom.childAt(element42, [1]);
            var element44 = dom.childAt(element42, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element43, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element43, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element44, [1]),0,0);
            morphs[3] = dom.createMorphAt(element44,3,3);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.created",["loc",[null,[87,97],[87,109]]]],"DD. MM. YYYY"],[],["loc",[null,[87,83],[87,126]]]],
            ["inline","time-format",[["get","item.created",["loc",[null,[88,50],[88,62]]]],"HH:mm"],[],["loc",[null,[88,36],[88,72]]]],
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[91,52],[91,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[91,24],[92,130]]]],
            ["block","each",[["get","item.treatmentPatients",["loc",[null,[93,26],[93,48]]]]],[],1,null,["loc",[null,[93,18],[135,27]]]]
          ],
          locals: ["item"],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 161,
                    "column": 22
                  },
                  "end": {
                    "line": 168,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","sub block mt5");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","sub");
                dom.setAttribute(el2,"style","display: inline");
                var el3 = dom.createTextNode("Termin: ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2,"for","time");
                dom.setAttribute(el2,"class","input-element");
                var el3 = dom.createTextNode("\n                             ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("input");
                dom.setAttribute(el3,"style","font-size: 14px");
                dom.setAttribute(el3,"autocomplete","false");
                dom.setAttribute(el3,"type","text");
                dom.setAttribute(el3,"placeholder","");
                dom.setAttribute(el3,"class","input date-picker__input time time-small");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element32 = dom.childAt(fragment, [1, 3, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element32, 'id');
                morphs[1] = dom.createAttrMorph(element32, 'value');
                morphs[2] = dom.createAttrMorph(element32, 'onclick');
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[165,108],[165,115]]]]]]],
                ["attribute","value",["concat",[["get","item.time",["loc",[null,[165,143],[165,152]]]]]]],
                ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[165,233],[165,237]]]]],[],["loc",[null,[165,213],[165,239]]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 171,
                    "column": 28
                  },
                  "end": {
                    "line": 172,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[172,64],[172,91]]]],
                ["content","item.customer.lastname",["loc",[null,[172,92],[172,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 177,
                          "column": 24
                        },
                        "end": {
                          "line": 181,
                          "column": 85
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[178,81],[178,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[179,71],[179,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[180,71],[180,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[178,49],[181,81]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 182,
                          "column": 28
                        },
                        "end": {
                          "line": 183,
                          "column": 92
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[183,35],[183,59]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[183,68],[183,90]]]]],[],[]]],["loc",[null,[182,79],[183,92]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 184,
                          "column": 28
                        },
                        "end": {
                          "line": 186,
                          "column": 63
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[184,67],[186,63]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 191,
                              "column": 28
                            },
                            "end": {
                              "line": 196,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element29 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element29, 'title');
                          morphs[1] = dom.createAttrMorph(element29, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[192,44],[192,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[192,162],[192,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[193,60],[193,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 196,
                              "column": 28
                            },
                            "end": {
                              "line": 201,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element28 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element28, 'title');
                          morphs[1] = dom.createAttrMorph(element28, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[197,44],[197,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[197,122],[197,149]]]],"selected",""],[],["loc",[null,[197,101],[197,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[197,201],[197,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[198,60],[198,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 190,
                            "column": 26
                          },
                          "end": {
                            "line": 202,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[191,34],[191,61]]]]],[],0,1,["loc",[null,[191,28],[201,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 204,
                              "column": 34
                            },
                            "end": {
                              "line": 209,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element27 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element27, 'title');
                          morphs[1] = dom.createAttrMorph(element27, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[205,50],[205,81]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[205,171],[205,198]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[206,62],[206,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 209,
                              "column": 34
                            },
                            "end": {
                              "line": 214,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element26 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element26, 'title');
                          morphs[1] = dom.createAttrMorph(element26, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[210,50],[210,81]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[210,131],[210,161]]]],"selected",""],[],["loc",[null,[210,110],[210,177]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[210,213],[210,240]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[211,62],[211,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 203,
                            "column": 32
                          },
                          "end": {
                            "line": 215,
                            "column": 32
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[204,40],[204,70]]]]],[],0,1,["loc",[null,[204,34],[214,41]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 188,
                          "column": 28
                        },
                        "end": {
                          "line": 216,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[190,32],[190,50]]]]],[],0,null,["loc",[null,[190,26],[202,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[203,38],[203,59]]]]],[],1,null,["loc",[null,[203,32],[215,39]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 175,
                        "column": 24
                      },
                      "end": {
                        "line": 223,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                    var el3 = dom.createTextNode("\n                              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element30 = dom.childAt(fragment, [1]);
                    var element31 = dom.childAt(element30, [9]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createMorphAt(element30,1,1);
                    morphs[1] = dom.createMorphAt(element30,3,3);
                    morphs[2] = dom.createMorphAt(element30,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element30, [7]),1,1);
                    morphs[4] = dom.createElementMorph(element31);
                    morphs[5] = dom.createMorphAt(element31,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[177,30],[177,58]]]]],[],0,null,["loc",[null,[177,24],[181,92]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[182,55],[182,77]]]]],[],1,null,["loc",[null,[182,28],[183,104]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[184,34],[184,65]]]]],[],2,null,["loc",[null,[184,28],[186,70]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[188,38],[188,59]]]],["get","patientInfo.doctor",["loc",[null,[188,60],[188,78]]]]],[],["loc",[null,[188,34],[188,79]]]]],[],3,null,["loc",[null,[188,28],[216,35]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[219,149],[219,163]]]]],[],["loc",[null,[219,111],[219,165]]]],
                    ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandeln"],["loc",[null,[220,30],[220,113]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 174,
                      "column": 24
                    },
                    "end": {
                      "line": 224,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[175,59],[175,70]]]],["get","selectedUsers",["loc",[null,[175,71],[175,84]]]],["get","filterName",["loc",[null,[175,85],[175,95]]]],["get","changeTrigger",["loc",[null,[175,96],[175,109]]]]],[],["loc",[null,[175,30],[175,110]]]]],[],0,null,["loc",[null,[175,24],[223,31]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 173,
                    "column": 22
                  },
                  "end": {
                    "line": 225,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[174,34],[174,54]]]],"in_waitingroom"],[],["loc",[null,[174,30],[174,72]]]]],[],0,null,["loc",[null,[174,24],[224,31]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 156,
                  "column": 14
                },
                "end": {
                  "line": 229,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element33 = dom.childAt(fragment, [1]);
              var element34 = dom.childAt(element33, [1]);
              var element35 = dom.childAt(element33, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element34, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element34, [3]),0,0);
              morphs[2] = dom.createMorphAt(element34,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element35, [1]),0,0);
              morphs[4] = dom.createMorphAt(element35,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[159,101],[159,113]]]],"DD. MM. YYYY"],[],["loc",[null,[159,87],[159,130]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[160,54],[160,66]]]],"HH:mm"],[],["loc",[null,[160,40],[160,76]]]],
              ["block","if",[["get","item.time",["loc",[null,[161,28],[161,37]]]]],[],0,null,["loc",[null,[161,22],[168,29]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[171,56],[171,72]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[171,28],[172,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[173,30],[173,52]]]]],[],2,null,["loc",[null,[173,22],[225,31]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 155,
                "column": 12
              },
              "end": {
                "line": 230,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[156,41],[156,45]]]],["get","selectedUsers",["loc",[null,[156,46],[156,59]]]],["get","filterName",["loc",[null,[156,60],[156,70]]]],["get","changeTrigger",["loc",[null,[156,71],[156,84]]]]],[],["loc",[null,[156,20],[156,85]]]]],[],0,null,["loc",[null,[156,14],[229,21]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 253,
                    "column": 18
                  },
                  "end": {
                    "line": 260,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","sub block mt5");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","sub");
                dom.setAttribute(el2,"style","display: inline");
                var el3 = dom.createTextNode("Termin: ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2,"for","time");
                dom.setAttribute(el2,"class","input-element");
                var el3 = dom.createTextNode("\n                             ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("input");
                dom.setAttribute(el3,"style","font-size: 14px");
                dom.setAttribute(el3,"autocomplete","false");
                dom.setAttribute(el3,"type","text");
                dom.setAttribute(el3,"placeholder","");
                dom.setAttribute(el3,"class","input date-picker__input time time-small");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element22 = dom.childAt(fragment, [1, 3, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element22, 'id');
                morphs[1] = dom.createAttrMorph(element22, 'value');
                morphs[2] = dom.createAttrMorph(element22, 'onclick');
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[257,108],[257,115]]]]]]],
                ["attribute","value",["concat",[["get","item.time",["loc",[null,[257,143],[257,152]]]]]]],
                ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[257,233],[257,237]]]]],[],["loc",[null,[257,213],[257,239]]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 263,
                    "column": 24
                  },
                  "end": {
                    "line": 264,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[264,64],[264,91]]]],
                ["content","item.customer.lastname",["loc",[null,[264,92],[264,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 269,
                          "column": 24
                        },
                        "end": {
                          "line": 269,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[269,115],[269,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[269,155],[269,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[269,200],[269,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[269,83],[269,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 270,
                          "column": 24
                        },
                        "end": {
                          "line": 270,
                          "column": 151
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[270,94],[270,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[270,127],[270,149]]]]],[],[]]],["loc",[null,[270,75],[270,151]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 271,
                          "column": 24
                        },
                        "end": {
                          "line": 271,
                          "column": 192
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[271,63],[271,192]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 276,
                              "column": 30
                            },
                            "end": {
                              "line": 281,
                              "column": 44
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element20 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element20, 'title');
                          morphs[1] = dom.createAttrMorph(element20, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[277,46],[277,74]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[277,164],[277,188]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[278,60],[278,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 281,
                              "column": 44
                            },
                            "end": {
                              "line": 286,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element19 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element19, 'title');
                          morphs[1] = dom.createAttrMorph(element19, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[282,60],[282,88]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[282,138],[282,165]]]],"selected",""],[],["loc",[null,[282,117],[282,181]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[282,217],[282,241]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[283,60],[283,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 275,
                            "column": 26
                          },
                          "end": {
                            "line": 287,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[276,36],[276,63]]]]],[],0,1,["loc",[null,[276,30],[286,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 289,
                              "column": 30
                            },
                            "end": {
                              "line": 294,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element18 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element18, 'title');
                          morphs[1] = dom.createAttrMorph(element18, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[290,46],[290,77]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[290,167],[290,194]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[291,62],[291,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 294,
                              "column": 30
                            },
                            "end": {
                              "line": 299,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element17 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element17, 'title');
                          morphs[1] = dom.createAttrMorph(element17, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[295,46],[295,77]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[295,127],[295,157]]]],"selected",""],[],["loc",[null,[295,106],[295,173]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[295,209],[295,236]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[296,62],[296,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 288,
                            "column": 28
                          },
                          "end": {
                            "line": 300,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[289,36],[289,66]]]]],[],0,1,["loc",[null,[289,30],[299,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 274,
                          "column": 24
                        },
                        "end": {
                          "line": 301,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[275,32],[275,50]]]]],[],0,null,["loc",[null,[275,26],[287,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[288,34],[288,55]]]]],[],1,null,["loc",[null,[288,28],[300,35]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child4 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 304,
                          "column": 24
                        },
                        "end": {
                          "line": 306,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[305,26],[305,117]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 267,
                        "column": 22
                      },
                      "end": {
                        "line": 308,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element21 = dom.childAt(fragment, [1]);
                    var morphs = new Array(5);
                    morphs[0] = dom.createMorphAt(element21,1,1);
                    morphs[1] = dom.createMorphAt(element21,3,3);
                    morphs[2] = dom.createMorphAt(element21,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element21, [7]),1,1);
                    morphs[4] = dom.createMorphAt(element21,9,9);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[269,30],[269,58]]]]],[],0,null,["loc",[null,[269,24],[269,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[270,51],[270,73]]]]],[],1,null,["loc",[null,[270,24],[270,163]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[271,30],[271,61]]]]],[],2,null,["loc",[null,[271,24],[271,199]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[274,34],[274,55]]]],["get","patientInfo.doctor",["loc",[null,[274,56],[274,74]]]]],[],["loc",[null,[274,30],[274,75]]]]],[],3,null,["loc",[null,[274,24],[301,31]]]],
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[304,68],[304,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[304,83],[304,110]]]]],["class","mr0 ml10 icon-button icon-button--default list-action-square"],4,null,["loc",[null,[304,24],[306,36]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 266,
                      "column": 22
                    },
                    "end": {
                      "line": 309,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[267,57],[267,68]]]],["get","selectedUsers",["loc",[null,[267,69],[267,82]]]],["get","filterName",["loc",[null,[267,83],[267,93]]]],["get","changeTrigger",["loc",[null,[267,94],[267,107]]]]],[],["loc",[null,[267,28],[267,108]]]]],[],0,null,["loc",[null,[267,22],[308,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 265,
                    "column": 20
                  },
                  "end": {
                    "line": 310,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[266,32],[266,52]]]],"in_treatment"],[],["loc",[null,[266,28],[266,68]]]]],[],0,null,["loc",[null,[266,22],[309,29]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 248,
                  "column": 14
                },
                "end": {
                  "line": 313,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element23 = dom.childAt(fragment, [1]);
              var element24 = dom.childAt(element23, [1]);
              var element25 = dom.childAt(element23, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element24, [3]),0,0);
              morphs[2] = dom.createMorphAt(element24,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
              morphs[4] = dom.createMorphAt(element25,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[251,97],[251,109]]]],"DD. MM. YYYY"],[],["loc",[null,[251,83],[251,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[252,50],[252,62]]]],"HH:mm"],[],["loc",[null,[252,36],[252,72]]]],
              ["block","if",[["get","item.time",["loc",[null,[253,24],[253,33]]]]],[],0,null,["loc",[null,[253,18],[260,25]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[263,52],[263,68]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[263,24],[264,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[265,28],[265,50]]]]],[],2,null,["loc",[null,[265,20],[310,29]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 247,
                "column": 12
              },
              "end": {
                "line": 314,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[248,41],[248,45]]]],["get","selectedUsers",["loc",[null,[248,46],[248,59]]]],["get","filterName",["loc",[null,[248,60],[248,70]]]],["get","changeTrigger",["loc",[null,[248,71],[248,84]]]]],[],["loc",[null,[248,20],[248,85]]]]],[],0,null,["loc",[null,[248,14],[313,21]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 337,
                    "column": 24
                  },
                  "end": {
                    "line": 338,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[338,64],[338,91]]]],
                ["content","item.customer.lastname",["loc",[null,[338,92],[338,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 343,
                          "column": 24
                        },
                        "end": {
                          "line": 343,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[343,115],[343,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[343,155],[343,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[343,200],[343,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[343,83],[343,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 345,
                          "column": 0
                        },
                        "end": {
                          "line": 345,
                          "column": 127
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[345,70],[345,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[345,103],[345,125]]]]],[],[]]],["loc",[null,[345,51],[345,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 346,
                          "column": 24
                        },
                        "end": {
                          "line": 348,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","block small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","patientInfo.room.name",["loc",[null,[347,52],[347,77]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 350,
                          "column": 22
                        },
                        "end": {
                          "line": 350,
                          "column": 190
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[350,61],[350,190]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child4 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 355,
                              "column": 32
                            },
                            "end": {
                              "line": 360,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element10 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element10, 'title');
                          morphs[1] = dom.createAttrMorph(element10, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[356,48],[356,76]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[356,166],[356,190]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[357,64],[357,95]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 360,
                              "column": 32
                            },
                            "end": {
                              "line": 365,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element9 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element9, 'title');
                          morphs[1] = dom.createAttrMorph(element9, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[361,48],[361,76]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[361,126],[361,153]]]],"selected",""],[],["loc",[null,[361,105],[361,169]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[361,205],[361,229]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[362,64],[362,95]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 354,
                            "column": 30
                          },
                          "end": {
                            "line": 366,
                            "column": 30
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[355,38],[355,65]]]]],[],0,1,["loc",[null,[355,32],[365,39]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 368,
                              "column": 32
                            },
                            "end": {
                              "line": 373,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element8 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element8, 'title');
                          morphs[1] = dom.createAttrMorph(element8, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[369,48],[369,79]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[369,169],[369,196]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[370,66],[370,100]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 373,
                              "column": 32
                            },
                            "end": {
                              "line": 378,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element7 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element7, 'title');
                          morphs[1] = dom.createAttrMorph(element7, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[374,48],[374,79]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[374,129],[374,159]]]],"selected",""],[],["loc",[null,[374,108],[374,175]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[374,211],[374,238]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[375,66],[375,100]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 367,
                            "column": 30
                          },
                          "end": {
                            "line": 379,
                            "column": 30
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[368,38],[368,68]]]]],[],0,1,["loc",[null,[368,32],[378,39]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 352,
                          "column": 26
                        },
                        "end": {
                          "line": 380,
                          "column": 26
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[354,36],[354,54]]]]],[],0,null,["loc",[null,[354,30],[366,37]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[367,36],[367,57]]]]],[],1,null,["loc",[null,[367,30],[379,37]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 341,
                        "column": 20
                      },
                      "end": {
                        "line": 386,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ml5 exp icon-button icon-button--default list-action-square");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    var el4 = dom.createTextNode("Behandeln");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element11 = dom.childAt(fragment, [1]);
                    var element12 = dom.childAt(element11, [3]);
                    var element13 = dom.childAt(element11, [9]);
                    var morphs = new Array(7);
                    morphs[0] = dom.createMorphAt(element11,1,1);
                    morphs[1] = dom.createMorphAt(element12,1,1);
                    morphs[2] = dom.createMorphAt(element12,3,3);
                    morphs[3] = dom.createMorphAt(element11,5,5);
                    morphs[4] = dom.createMorphAt(dom.childAt(element11, [7]),1,1);
                    morphs[5] = dom.createElementMorph(element13);
                    morphs[6] = dom.createMorphAt(element13,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[343,30],[343,58]]]]],[],0,null,["loc",[null,[343,24],[343,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[345,27],[345,49]]]]],[],1,null,["loc",[null,[345,0],[345,139]]]],
                    ["block","if",[["get","patientInfo.room",["loc",[null,[346,30],[346,46]]]]],[],2,null,["loc",[null,[346,24],[348,31]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[350,28],[350,59]]]]],[],3,null,["loc",[null,[350,22],[350,197]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[352,36],[352,57]]]],["get","patientInfo.doctor",["loc",[null,[352,58],[352,76]]]]],[],["loc",[null,[352,32],[352,77]]]]],[],4,null,["loc",[null,[352,26],[380,33]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[382,131],[382,145]]]]],[],["loc",[null,[382,93],[382,147]]]],
                    ["inline","button-in-treatment",[],["size","40","color","#fff","showStroke",false,"content","In eine Behandlungs-Lokation setzen"],["loc",[null,[383,26],[383,135]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 340,
                      "column": 20
                    },
                    "end": {
                      "line": 387,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[341,55],[341,66]]]],["get","selectedUsers",["loc",[null,[341,67],[341,80]]]],["get","filterName",["loc",[null,[341,81],[341,91]]]],["get","changeTrigger",["loc",[null,[341,92],[341,105]]]]],[],["loc",[null,[341,26],[341,106]]]]],[],0,null,["loc",[null,[341,20],[386,27]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 339,
                    "column": 18
                  },
                  "end": {
                    "line": 388,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[340,30],[340,50]]]],"in_station"],[],["loc",[null,[340,26],[340,64]]]]],[],0,null,["loc",[null,[340,20],[387,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 330,
                  "column": 14
                },
                "end": {
                  "line": 391,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var element15 = dom.childAt(element14, [1]);
              var element16 = dom.childAt(element14, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
              morphs[3] = dom.createMorphAt(element16,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[333,97],[333,109]]]],"DD. MM. YYYY"],[],["loc",[null,[333,83],[333,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[334,50],[334,62]]]],"HH:mm"],[],["loc",[null,[334,36],[334,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[337,52],[337,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[337,24],[338,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[339,26],[339,48]]]]],[],1,null,["loc",[null,[339,18],[388,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 329,
                "column": 12
              },
              "end": {
                "line": 392,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[330,41],[330,45]]]],["get","selectedUsers",["loc",[null,[330,46],[330,59]]]],["get","filterName",["loc",[null,[330,60],[330,70]]]],["get","changeTrigger",["loc",[null,[330,71],[330,84]]]]],[],["loc",[null,[330,20],[330,85]]]]],[],0,null,["loc",[null,[330,14],[391,21]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 418,
                    "column": 24
                  },
                  "end": {
                    "line": 419,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[419,64],[419,91]]]],
                ["content","item.customer.lastname",["loc",[null,[419,92],[419,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 425,
                          "column": 26
                        },
                        "end": {
                          "line": 429,
                          "column": 87
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[426,83],[426,114]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[427,73],[427,109]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[428,73],[428,106]]]]],[],[]],"classNames","mr5"],["loc",[null,[426,51],[429,83]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 430,
                          "column": 24
                        },
                        "end": {
                          "line": 431,
                          "column": 121
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[430,94],[430,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[431,97],[431,119]]]]],[],[]]],["loc",[null,[430,75],[431,121]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 432,
                          "column": 24
                        },
                        "end": {
                          "line": 434,
                          "column": 109
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[432,63],[434,109]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 436,
                          "column": 22
                        },
                        "end": {
                          "line": 441,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square mr0 ml0 ");
                      dom.setAttribute(el1,"style","margin-left: auto !important;");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[438,26],[439,89]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child4 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 442,
                            "column": 24
                          },
                          "end": {
                            "line": 444,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[443,26],[443,117]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 441,
                          "column": 22
                        },
                        "end": {
                          "line": 451,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 ml5");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element1 = dom.childAt(fragment, [2]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      morphs[1] = dom.createElementMorph(element1);
                      morphs[2] = dom.createMorphAt(element1,1,1);
                      dom.insertBoundary(fragment, 0);
                      return morphs;
                    },
                    statements: [
                      ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[442,68],[442,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[442,83],[442,110]]]]],["class","mr0 icon-button icon-button--default list-action-square flex-extend"],0,null,["loc",[null,[442,24],[444,36]]]],
                      ["element","action",["openExportInvoicePanel",["get","patientInfo.id",["loc",[null,[446,120],[446,134]]]],["get","patientInfo.info.invoiceId",["loc",[null,[447,120],[447,146]]]]],[],["loc",[null,[445,106],[447,148]]]],
                      ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[448,26],[449,89]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 422,
                        "column": 22
                      },
                      "end": {
                        "line": 453,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","flex-center");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var element3 = dom.childAt(element2, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element3,1,1);
                    morphs[1] = dom.createMorphAt(element3,3,3);
                    morphs[2] = dom.createMorphAt(element3,5,5);
                    morphs[3] = dom.createMorphAt(element2,3,3);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[425,32],[425,60]]]]],[],0,null,["loc",[null,[425,26],[429,94]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[430,51],[430,73]]]]],[],1,null,["loc",[null,[430,24],[431,133]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[432,30],[432,61]]]]],[],2,null,["loc",[null,[432,24],[434,116]]]],
                    ["block","if",[["get","inProgress",["loc",[null,[436,28],[436,38]]]]],[],3,4,["loc",[null,[436,22],[451,29]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 421,
                      "column": 20
                    },
                    "end": {
                      "line": 454,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[422,57],[422,68]]]],["get","selectedUsers",["loc",[null,[422,69],[422,82]]]],["get","filterName",["loc",[null,[422,83],[422,93]]]],["get","changeTrigger",["loc",[null,[422,94],[422,107]]]]],[],["loc",[null,[422,28],[422,108]]]]],[],0,null,["loc",[null,[422,22],[453,27]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 420,
                    "column": 18
                  },
                  "end": {
                    "line": 455,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[421,30],[421,50]]]],"ready_for_payment"],[],["loc",[null,[421,26],[421,71]]]]],[],0,null,["loc",[null,[421,20],[454,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 410,
                  "column": 14
                },
                "end": {
                  "line": 459,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [1]);
              var element6 = dom.childAt(element4, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
              morphs[3] = dom.createMorphAt(element6,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[413,97],[413,109]]]],"DD. MM. YYYY"],[],["loc",[null,[413,83],[414,113]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[415,50],[415,62]]]],"HH:mm"],[],["loc",[null,[415,36],[415,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[418,52],[418,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[418,24],[419,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[420,26],[420,48]]]]],[],1,null,["loc",[null,[420,18],[455,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 409,
                "column": 12
              },
              "end": {
                "line": 460,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[410,41],[410,45]]]],["get","selectedUsers",["loc",[null,[410,46],[410,59]]]],["get","filterName",["loc",[null,[410,60],[410,70]]]],["get","changeTrigger",["loc",[null,[410,71],[410,84]]]]],[],["loc",[null,[410,20],[410,85]]]]],[],0,null,["loc",[null,[410,14],[459,23]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 0
            },
            "end": {
              "line": 465,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
          dom.setAttribute(el4,"class","ml5 is-highlighted");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Demnächst");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table grid table--small");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Termin");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
          dom.setAttribute(el4,"class","ml5 is-highlighted");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Wartezimmer");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table grid table--small");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
          dom.setAttribute(el4,"class","ml5 is-highlighted");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("In Behandlung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table table--small grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          dom.setAttribute(el2,"id","stationsCard");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
          dom.setAttribute(el4,"class","ml5 is-highlighted");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Auf Station");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table table--small grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
          dom.setAttribute(el4,"class","ml5 is-highlighted");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Zur Abrechnung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table table--small grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element45 = dom.childAt(fragment, [1, 1]);
          var element46 = dom.childAt(fragment, [3, 1]);
          var element47 = dom.childAt(fragment, [5]);
          var element48 = dom.childAt(element47, [1]);
          var element49 = dom.childAt(element47, [3]);
          var element50 = dom.childAt(fragment, [7, 1]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element45, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element45, [3, 3]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element46, [1, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element46, [3, 3]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element48, [1, 1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element48, [3, 3]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element49, [1, 1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element49, [3, 3]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element50, [1, 1]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element50, [3, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","get-size",[["get","upcomingItems",["loc",[null,[75,92],[75,105]]]]],[],["loc",[null,[75,81],[75,107]]]],
          ["block","each",[["get","upcomingItems",["loc",[null,[84,20],[84,33]]]]],[],0,null,["loc",[null,[84,12],[138,21]]]],
          ["inline","get-size",[["get","waitingroomItems",["loc",[null,[146,92],[146,108]]]]],[],["loc",[null,[146,81],[146,110]]]],
          ["block","each",[["get","waitingroomItems",["loc",[null,[155,20],[155,36]]]]],[],1,null,["loc",[null,[155,12],[230,21]]]],
          ["inline","get-size",[["get","treatmentItems",["loc",[null,[238,92],[238,106]]]]],[],["loc",[null,[238,81],[238,108]]]],
          ["block","each",[["get","treatmentItems",["loc",[null,[247,20],[247,34]]]]],[],2,null,["loc",[null,[247,12],[314,21]]]],
          ["inline","get-size",[["get","stationItems",["loc",[null,[320,92],[320,104]]]]],[],["loc",[null,[320,81],[320,106]]]],
          ["block","each",[["get","stationItems",["loc",[null,[329,20],[329,32]]]]],[],3,null,["loc",[null,[329,12],[392,21]]]],
          ["inline","get-size",[["get","paymentItems",["loc",[null,[400,92],[400,104]]]]],[],["loc",[null,[400,81],[400,106]]]],
          ["block","each",[["get","paymentItems",["loc",[null,[409,20],[409,32]]]]],[],4,null,["loc",[null,[409,12],[460,21]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 482,
                "column": 10
              },
              "end": {
                "line": 484,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[483,65],[483,81]]]],"selected",""],[],["loc",[null,[483,44],[483,97]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[483,124],[483,131]]]]],[],["loc",[null,[483,99],[483,133]]]],
            ["content","station.name",["loc",[null,[483,134],[483,150]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 479,
              "column": 6
            },
            "end": {
              "line": 486,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[482,18],[482,26]]]]],[],0,null,["loc",[null,[482,10],[484,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 530,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        dom.setAttribute(el1,"id","treatmentOverviewNav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex align-items-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"style","width: 30%");
        dom.setAttribute(el4,"class","u-hide@tablet");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","btn-group");
        dom.setAttribute(el4,"role","group");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        var el6 = dom.createTextNode("Kombinierte\n          Ansicht\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        var el6 = dom.createTextNode(" Nur Kacheln\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        var el6 = dom.createTextNode("Nur Liste\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-10/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Neue Behandlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","with-context-bar grid quick-filter mb10");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-16/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        dom.setAttribute(el3,"style","font-size: 12px");
        var el4 = dom.createTextNode("Mitarbeiter:innen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24 text-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        dom.setAttribute(el3,"style","font-size: 12px");
        var el4 = dom.createTextNode("Ausgangsrechnungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","treatmentOverview");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid");
        dom.setAttribute(el2,"id","topTiles");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        dom.setAttribute(el2,"class","site-content card transaction-filter");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper mb30 mt20 grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 col l-12/24");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Filter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Bereit zum Bezahlen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Station");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element53 = dom.childAt(fragment, [0]);
        var element54 = dom.childAt(element53, [1, 1]);
        var element55 = dom.childAt(element54, [3]);
        var element56 = dom.childAt(element55, [1]);
        var element57 = dom.childAt(element55, [3]);
        var element58 = dom.childAt(element55, [5]);
        var element59 = dom.childAt(element53, [3, 1]);
        var element60 = dom.childAt(fragment, [2]);
        var element61 = dom.childAt(fragment, [4]);
        var element62 = dom.childAt(element61, [3]);
        var element63 = dom.childAt(element62, [1]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element64, [5]);
        var element67 = dom.childAt(element64, [7]);
        var element68 = dom.childAt(element64, [9]);
        var element69 = dom.childAt(element64, [11]);
        var morphs = new Array(28);
        morphs[0] = dom.createMorphAt(dom.childAt(element54, [1]),1,1);
        morphs[1] = dom.createAttrMorph(element56, 'class');
        morphs[2] = dom.createElementMorph(element56);
        morphs[3] = dom.createAttrMorph(element57, 'class');
        morphs[4] = dom.createElementMorph(element57);
        morphs[5] = dom.createAttrMorph(element58, 'class');
        morphs[6] = dom.createElementMorph(element58);
        morphs[7] = dom.createElementMorph(element59);
        morphs[8] = dom.createMorphAt(dom.childAt(element60, [1, 3]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element60, [3, 3]),0,0);
        morphs[10] = dom.createAttrMorph(element61, 'class');
        morphs[11] = dom.createMorphAt(dom.childAt(element61, [1]),1,1);
        morphs[12] = dom.createAttrMorph(element65, 'class');
        morphs[13] = dom.createElementMorph(element65);
        morphs[14] = dom.createAttrMorph(element66, 'class');
        morphs[15] = dom.createElementMorph(element66);
        morphs[16] = dom.createAttrMorph(element67, 'class');
        morphs[17] = dom.createElementMorph(element67);
        morphs[18] = dom.createAttrMorph(element68, 'class');
        morphs[19] = dom.createElementMorph(element68);
        morphs[20] = dom.createAttrMorph(element69, 'class');
        morphs[21] = dom.createElementMorph(element69);
        morphs[22] = dom.createMorphAt(element63,3,3);
        morphs[23] = dom.createMorphAt(element62,3,3);
        morphs[24] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[25] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[26] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[27] = dom.createMorphAt(fragment,12,12,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[5,32],[5,42]]]]],[],[]],"placeHolder","Kunde suchen","search","applyFilter"],["loc",[null,[5,8],[5,92]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[9,94],[9,102]]]],"splitted"],[],["loc",[null,[9,90],[9,114]]]],"selected",""],[],["loc",[null,[9,69],[10,105]]]]]]],
        ["element","action",["changeView","splitted"],[],["loc",[null,[8,16],[8,50]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[14,91],[14,99]]]],"tiles"],[],["loc",[null,[14,87],[14,108]]]],"selected",""],[],["loc",[null,[14,66],[15,91]]]]]]],
        ["element","action",["changeView","tiles"],[],["loc",[null,[13,16],[13,47]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[18,90],[18,98]]]],"list"],[],["loc",[null,[18,86],[18,106]]]],"selected",""],[],["loc",[null,[18,65],[19,90]]]]]]],
        ["element","action",["changeView","list"],[],["loc",[null,[17,16],[17,46]]]],
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[25,7],[25,47]]]],
        ["block","each",[["get","users",["loc",[null,[35,14],[35,19]]]]],[],0,null,["loc",[null,[35,6],[60,15]]]],
        ["block","link-to",["cash.choose"],["classNames","is-highlighted"],1,null,["loc",[null,[65,9],[65,128]]]],
        ["attribute","class",["concat",[["get","mainView",["loc",[null,[69,37],[69,45]]]]]]],
        ["block","if",[["get","users",["loc",[null,[71,6],[71,11]]]]],[],2,null,["loc",[null,[71,0],[465,7]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showAll",["loc",[null,[473,61],[473,68]]]],"selected",""],[],["loc",[null,[473,40],[473,85]]]]]]],
        ["element","action",["toggleQuickfilter","all"],[],["loc",[null,[473,87],[473,123]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showWaiting",["loc",[null,[474,61],[474,72]]]],"selected",""],[],["loc",[null,[474,40],[474,88]]]]]]],
        ["element","action",["toggleQuickfilter","waiting"],[],["loc",[null,[474,90],[474,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showTreatment",["loc",[null,[475,61],[475,74]]]],"selected",""],[],["loc",[null,[475,40],[475,90]]]]]]],
        ["element","action",["toggleQuickfilter","treatment"],[],["loc",[null,[475,92],[475,134]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showPayment",["loc",[null,[476,61],[476,72]]]],"selected",""],[],["loc",[null,[476,40],[476,88]]]]]]],
        ["element","action",["toggleQuickfilter","payment"],[],["loc",[null,[476,90],[476,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showStation",["loc",[null,[477,61],[477,72]]]],"selected",""],[],["loc",[null,[477,40],[477,88]]]]]]],
        ["element","action",["toggleQuickfilter","station"],[],["loc",[null,[477,90],[477,130]]]],
        ["block","if",[["get","showStation",["loc",[null,[479,12],[479,23]]]]],[],3,null,["loc",[null,[479,6],[486,13]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[490,14],[490,19]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[491,13],[491,17]]]]],[],[]],"selectedUsers",["subexpr","@mut",[["get","selectedUsers",["loc",[null,[492,22],[492,35]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[493,25],[493,33]]]]],[],[]],"filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[494,19],[494,29]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[495,22],[495,35]]]]],[],[]],"showAll",["subexpr","@mut",[["get","showAll",["loc",[null,[496,16],[496,23]]]]],[],[]],"showAdmission",["subexpr","@mut",[["get","showAdmission",["loc",[null,[497,22],[497,35]]]]],[],[]],"showWaiting",["subexpr","@mut",[["get","showWaiting",["loc",[null,[498,20],[498,31]]]]],[],[]],"showTreatment",["subexpr","@mut",[["get","showTreatment",["loc",[null,[499,22],[499,35]]]]],[],[]],"showPayment",["subexpr","@mut",[["get","showPayment",["loc",[null,[500,20],[500,31]]]]],[],[]],"showStation",["subexpr","@mut",[["get","showStation",["loc",[null,[501,20],[501,31]]]]],[],[]],"showStationOverride",["subexpr","is-test-mandant",[],[],["loc",[null,[502,28],[502,45]]]],"abort","abort","openTime","openTime","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToStation","movePatientToStation","exportInvoice","openExportInvoicePanel","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[489,6],[510,8]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[513,67],[513,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[513,84],[513,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[513,103],[513,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[513,126],[513,144]]]]],[],[]]],["loc",[null,[513,0],[513,146]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[519,8],[519,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[520,10],[520,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[521,13],[521,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[522,17],[522,36]]]]],[],[]]],["loc",[null,[515,0],[522,38]]]],
        ["inline","export-invoice-panel",[],["exportInvoice","openExportInvoicePanel","confirm","exportInvoice","actionReceiver",["subexpr","@mut",[["get","exportInvoicePanel",["loc",[null,[527,17],[527,35]]]]],[],[]]],["loc",[null,[524,0],[527,37]]]],
        ["inline","select-app-time",[],["save","saveDate","actionReceiver",["subexpr","@mut",[["get","selectAppTime",["loc",[null,[528,49],[528,62]]]]],[],[]]],["loc",[null,[528,0],[528,64]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});