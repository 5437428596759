define('jason-frontend/routes/stables/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  var modelHookRun;
  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.findRecord('stable', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    actions: {
      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model');

        var data = {
          id: model.id,
          name: model.get('name'),
          street: model.get('street'),
          postalCode: model.get('postalCode'),
          town: model.get('town'),
          email: model.get('email'),
          phone: model.get('phone')
        };

        if (model.get('owner')) {
          data.ownerId = model.get('owner').id;
        } else {
          jason.notifiction.error('Bitte eine Besitzer:in wählen', '');
          return;
        }

        $.ajax({
          url: "/api/stables/" + data.id,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('stables.index');
        }, function (data) {
          self.transitionTo('stables.index');
        });
      }
    }
  });

});