define('jason-frontend/routes/etigafeed/edit', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {

    model: function model(params) {
      if (params.id > 0) {
        return this.store.find('etigaFeed', params.id);
      } else {
        var model = this.store.createRecord('etigaFeed');
        Ember['default'].set(model, 'type', 'text');
        Ember['default'].set(model, 'channelApp', true);
        Ember['default'].set(model, 'validPeriod', moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"));
        return model;
      }
    },
    searchTextmodules: function searchTextmodules() {
      var self = this;
      $.ajax({
        url: "/api/textModules?type=def&filterName=" + this.get('controller').get('filterName'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('allItems', data.textModule);
        setTimeout(function () {
          $(".dragable").draggable({ revert: true, helper: "clone", appendTo: 'body' });
        }, 500);
      });
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      var self = this;

      controller.set('patientCategories', this.store.find('patient-category'));

      self.initEditor();

      $('body').removeClass('tiny-overlay-disabled');
    },
    initEditor: function initEditor() {
      var self = this,
          controller = self.get('controller');
      tinymce.remove();
      var dfreeBodyConfig = {
        selector: '.mce-content-body',
        license_key: 'gpl',
        menubar: false,
        language: 'de',
        inline: true,
        statusbar: false,
        quickbar: false,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        file_picker_types: 'image',
        automatic_uploads: true,
        block_unsupported_drop: false,
        paste_retain_style_properties: 'color',
        paste_webkit_styles: 'color',
        images_upload_handler: this.imageUploadHandler,
        plugins: ['autolink', 'noneditable', 'link', 'lists', 'paste', 'image'],
        setup: function setup(editor) {
          editor.on("keyup", function () {
            $('#textPreview').html(tinymce.editors['editorText'].getContent());
            $('#textPreviewExtended').html(tinymce.editors['editorExpandedText'].getContent());
          });
          editor.on('init', function (args) {
            $('#textPreview').html(tinymce.editors['editorText'].getContent());
            $('#textPreviewExtended').html(tinymce.editors['editorExpandedText'].getContent());
          });
          editor.ui.registry.addButton('addImage', {
            text: '',
            icon: 'image',
            onAction: function onAction(_) {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-image-upload-and-select-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
              controller.get('imageUploadAndSelectPanel').send('load', self, controller.get('type'), controller.get('model').patient ? controller.get('model').patient.id : 0);
            }
          });
        },
        toolbar: 'bold italic | forecolor | alignleft aligncenter alignright | bullist numlist | addImage',
        contextmenu: 'image imagetools lists',
        noneditable_noneditable_class: 'placeholder'
      };
      setTimeout(function () {
        tinymce.init(dfreeBodyConfig);
      }, 500);
    },
    actions: {
      openAnalytics: function openAnalytics(item) {
        var self = this;
        this.get('controller').get('etigaFeedAnalyticsPanel').send('load', item);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-etiga-feed-analytics-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectMultistageTreatmentDetail: function selectMultistageTreatmentDetail(provider, selected, title) {
        var self = this;
        this.get('controller').get('treatmentMultiStageSelectPanel').send('load', provider, selected, self, title);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-multistage-select-panel'
          },
          callbacks: {
            close: function close() {
              if (provider === 'diagnoses') {
                self.get('controller').get('treatmentMultiStageSelectPanel').send('updateReference', 'diagnosis', self.get('controller').get('model'));
              } else if (provider === 'products') {
                self.get('controller').get('treatmentMultiStageSelectPanel').send('updateReference', 'products', self.get('controller').get('model'));
              } else if (provider === 'patientCategories') {
                self.get('controller').get('treatmentMultiStageSelectPanel').send('updateReference', 'patientCategories', self.get('controller').get('model'));
              }
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      toggleExtended: function toggleExtended(value) {
        this.get('controller').set('showExtended', value);
      },
      previewLink: function previewLink(link) {
        if (link) {
          window.open(link);
        }
      },
      openImageSelectPanel: function openImageSelectPanel() {
        var self = this;
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-image-upload-and-select-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        this.get('controller').get('imageUploadAndSelectPanel').send('load', self, 'etiga', 0);
      },
      insertImage: function insertImage(id, type) {
        if (type && type === 'etiga') {
          Ember['default'].set(this.get('controller').get('model'), 'imageUrl', 'https://res.cloudinary.com/myjason/image/upload/w_800,h_800,c_limit/' + id);
        } else {
          tinymce.activeEditor.insertContent('<img src="https://res.cloudinary.com/myjason/image/upload/w_800,h_800,c_limit/' + id + '"/>');
          if (tinymce.activeEditor.id === 'editorText') {
            $('#textPreview').html(tinymce.editors['editorText'].getContent());
          }
        }
      },
      selectMedia: function selectMedia(id) {
        $.ajax({
          url: "/api/media/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var hostAppendix = 'https://portal.myjason.at';
          if (location.hostname === "localhost") {
            hostAppendix = 'http://localhost:8080/';
          }
          if (location.hostname === 'portal.myjason.eu') {
            hostAppendix = 'https://portal.myjason.eu';
          }
          tinymce.activeEditor.insertContent('<img src="' + hostAppendix + '/api/media/' + data.media.id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken() + '"/>');
        });
      },
      save: function save() {

        var category = null;
        var digitalRace = null;
        if (this.get('controller').get('model').get('patientCategory')) {
          category = {
            id: this.get('controller').get('model').get('patientCategory').id
          };
        }
        if (this.get('controller').get('model').get('digitalRace')) {
          digitalRace = {
            id: this.get('controller').get('model').get('digitalRace').id
          };
        }

        var diagnosis = new Array();
        var products = new Array();
        var patientCategories = new Array();
        if (this.get('controller').get('model').get('diagnosis')) {
          $.each(this.get('controller').get('model').get('diagnosis'), function () {
            var data = {
              id: $(this)[0].id
            };
            diagnosis.push(data);
          });
        }
        if (this.get('controller').get('model').get('patientCategories')) {
          $.each(this.get('controller').get('model').get('patientCategories'), function () {
            var data = {
              id: $(this)[0].id
            };
            patientCategories.push(data);
          });
        }
        if (this.get('controller').get('model').get('products')) {
          $.each(this.get('controller').get('model').get('products'), function () {
            var data = {
              id: $(this)[0].id
            };
            products.push(data);
          });
        }

        var self = this;
        var data = {
          id: this.get('controller').get('model').id,
          text: tinymce.editors['editorText'].getContent(),
          expandedText: tinymce.editors['editorExpandedText'].getContent(),
          title: this.get('controller').get('model').get('title'),
          titleSecondary: this.get('controller').get('model').get('titleSecondary'),
          externalLink: this.get('controller').get('model').get('externalLink'),
          imageUrl: this.get('controller').get('model').get('imageUrl'),
          type: this.get('controller').get('model').get('type'),
          channelApp: this.get('controller').get('model').get('channelApp'),
          channelPortal: this.get('controller').get('model').get('channelPortal'),
          active: this.get('controller').get('model').get('active'),
          channelWeb: this.get('controller').get('model').get('channelWeb'),
          validPeriod: this.get('controller').get('model').get('validPeriod'),
          topic: this.get('controller').get('model').get('topic'),
          ageFrom: this.get('controller').get('model').get('ageFrom'),
          ageTo: this.get('controller').get('model').get('ageTo'),
          weightFrom: this.get('controller').get('model').get('weightFrom'),
          weightTo: this.get('controller').get('model').get('weightTo'),
          diagnosis: diagnosis,
          products: products,
          patientCategories: patientCategories,
          patientCategory: category,
          digitalRace: digitalRace
        };

        var id = this.get('controller').get('model').id;

        if (id === null) {
          id = 0;
        }
        $.ajax({
          url: "/api/etigaFeeds",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (id === 0) {
            Ember['default'].set(self.get('controller').get('model'), 'id', data.etigaFeed.id);
          }
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      }
    }
  });

});