define('jason-frontend/components/treatment-now', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    fullMode: true,
    _onReady: (function () {
      this.initAutoSaveTrigger();
    }).on('didInsertElement'),
    _onDestroy: (function () {
      if (!Ember['default'].isBlank(this.get('timerRunId'))) {
        Ember['default'].run.cancel(this.get('timerRunId'));
        this.set('timerRunId', null);
      }
    }).on('didDestroyElement'),
    initAutoSaveTrigger: function initAutoSaveTrigger() {
      var self = this;
      var timerRunId = Ember['default'].run.later(this, function () {
        if (self.get('inputDirty')) {
          self.sendAction('autoSave');
        }
        self.initAutoSaveTrigger();
      }, 10000);

      this.set('timerRunId', timerRunId);
    },
    actions: {
      scan: function scan() {
        this.sendAction('scan');
      },
      openDigitalForm: function openDigitalForm(entry) {
        this.sendAction('openDigitalForm', entry);
      },
      downloadBarcode: function downloadBarcode(id) {
        this.sendAction('downloadBarcode', id);
      },
      addTemplateToPosition: function addTemplateToPosition(entry) {
        this.sendAction('addTemplateToPosition', entry);
      },
      downloadSlopingBarcode: function downloadSlopingBarcode(id) {
        this.sendAction('downloadSlopingBarcode', id);
      },
      showLabs: function showLabs(id) {
        this.sendAction('showLabs', id);
      },
      openSlopingInfos: function openSlopingInfos(id) {
        this.sendAction('openSlopingInfos', id);
      },
      openTemplateEdit: function openTemplateEdit(entryId, instanceId, readOnly, patientId) {
        this.sendAction('openTemplateEdit', entryId, instanceId, readOnly, patientId);
      },
      openInventoryStockPanel: function openInventoryStockPanel(entry) {
        this.sendAction('openInventoryStockPanel', entry, true);
      },
      checkInventoryInput: function checkInventoryInput(id) {
        this.sendAction('checkInventoryInput', id);
      },
      openLaboklinChoosePanel: function openLaboklinChoosePanel(entryId, laboklinFormId, article, privateTreatment, subType) {
        this.sendAction('openLaboklinChoosePanel', entryId, laboklinFormId, article, privateTreatment, subType);
      },
      openXrayEntryPanel: function openXrayEntryPanel(entryId, laboklinFormId, article) {
        this.sendAction('openXrayEntryPanel', entryId, laboklinFormId, article);
      },
      load: function load(model) {
        this.set('model', model);
      },
      changeQuantity: function changeQuantity(entry, offerId, productName) {
        this.sendAction('changeQuantity', entry, offerId, productName);
      },
      moveUp: function moveUp(entry, offerId) {
        this.sendAction('moveUp', entry, offerId);
      },
      moveDown: function moveDown(entry, offerId) {
        this.sendAction('moveDown', entry, offerId);
      },
      openCustomerReductions: function openCustomerReductions(customerId) {
        this.sendAction('openCustomerReductions', customerId);
      },
      changeGrossPrice: function changeGrossPrice(price, entryId, offerId, productName) {
        this.sendAction('changeGrossPrice', price, entryId, offerId, productName);
      },
      gotFactorChanged: function gotFactorChanged(entry) {
        this.sendAction('gotFactorChanged', entry);
      },
      changeApplied: function changeApplied(applied, entryId, offerId, productName) {
        this.sendAction('changeApplied', applied, entryId, offerId, productName);
      },
      changeText: function changeText(text, entryId, offerId, productName) {
        this.sendAction('changeText', text, entryId, offerId, productName);
      },
      addArticle: function addArticle(offerId) {
        this.sendAction('addArticle', offerId);
      },
      deleteProduct: function deleteProduct(entryId, offerId, productName) {
        this.sendAction('deleteProduct', entryId, offerId, productName);
      },
      dirtyInput: function dirtyInput() {
        this.sendAction('dirtyInput');
        this.get('model').set('isDirty', true);
      },
      triggerAutosave: function triggerAutosave() {
        this.sendAction('autoSave');
      },
      lockEntries: function lockEntries(offerId, value) {
        this.sendAction('lockEntries', offerId, value);
      },
      toggleMedicalInfo: function toggleMedicalInfo(value) {
        this.set('medicalAreaShown', value);
      },
      assistantChanged: function assistantChanged() {
        this.sendAction('dirtyInput');
      },
      doctorChanged: function doctorChanged() {
        this.sendAction('dirtyInput');
      },
      changeRelativeDiscount: function changeRelativeDiscount(entryId, discount, invoiceId, productName) {
        this.sendAction('relativeDiscountChanged', entryId, discount, invoiceId, productName);
      },
      changeAbsoluteDiscount: function changeAbsoluteDiscount(entryId, discount, invoiceId, productName) {
        this.sendAction('absoluteDiscountChanged', entryId, discount, invoiceId, productName);
      },
      changeDiscount: function changeDiscount(discount, absolute, entryId, invoiceId, productName) {
        this.sendAction('discountChanged', discount, absolute, entryId, invoiceId, productName);
      }
    }
  });

});