define('jason-frontend/components/stable-select-customer-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    customer: null,
    applyFilter: function applyFilter() {
      var self = this;

      var term = this.get('filterName');
      var patientTerm = this.get('filterPatient');
      var splitted;
      if (term) {
        splitted = term.split(' ');
      }

      this.externalContext.findPaged('customer', {
        filterName: term,
        filterPatient: patientTerm
      }).then(function (entries) {
        self.set('model', entries);
      });
    },
    actions: {
      selectPatient: function selectPatient(id) {
        var self = this;
        $.ajax({
          url: "/api/stables/" + this.get('stableId') + "/addPatient/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Tier', 'Erfolgreich dem Stall hinzugefügt');
          self.sendAction('reload');
          $.ajax({
            url: "/api/stables/" + self.get('stableId'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('stable', data.stable);
          }, function () {});
        });
      },
      removePatient: function removePatient(stableId, id) {
        jason.notifiction.notifiy('Tier', 'Erfolgreich aus dem Stall entfernt');

        var self = this;
        $.ajax({
          url: "/api/stables/" + stableId + "/addPatient/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          $.ajax({
            url: "/api/stables/" + self.get('stableId'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('stable', data.stable);
          }, function () {});
        });
        this.sendAction('reload');
      },
      load: function load(stableId, externalContext) {
        this.set('externalContext', externalContext);
        this.set('stableId', stableId);
        var self = this;

        $.ajax({
          url: "/api/stables/" + stableId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('stable', data.stable);
        }, function () {});

        this.applyFilter();
      },
      triggerSearch: function triggerSearch() {
        this.applyFilter();
      },
      select: function select(id) {
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});