define('jason-frontend/components/select-inventory-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    showInput: true,
    isPeriodicNumber: function isPeriodicNumber(num) {
      var str = num.toString();
      var decimalIndex = str.indexOf('.');
      if (decimalIndex === -1) return false;

      var fractionalPart = str.slice(decimalIndex + 1);
      return fractionalPart.length > 5; // Adjust this threshold as needed
    },
    roundPeriodicNumber: function roundPeriodicNumber(num) {
      var rounded = Math.ceil(num * 100) / 100;
      return Number(rounded.toFixed(2));
    },
    actions: {
      copy: function copy(id) {
        this.set('stockIdInput', id + 'QR');
      },
      confirm: function confirm() {
        this.set('showInput', true);

        var id = new String(this.get('stockIdInput')).toLowerCase();
        id = id.replace('ehapo', '');
        id = id.replace('ahapo', '');

        if (id.indexOf("qr") == -1) {
          return;
        }

        id = id.replace('qr', '');

        if (id.length == 0) {
          jason.notifiction.error('Eingabe nicht korrekt', '');
          this.set('stockIdInput', '');
          $('#stockIdInput').prop('disabled', false);
          $('#stockIdInput').focus();
          return;
        }

        this.sendAction('checkInventoryInput', id, this.get('baseUnitId'));
      },
      confirmOut: function confirmOut() {
        this.set('showSuccess', true);

        if (this.get('outgoing') > this.get('selectedStock').flatStock) {
          jason.notifiction.error('Fehler', 'Die gewählte Menge kann nicht höher als der Packungsbestand sein');
          return;
        }
        if (this.get('outgoing') == 0) {
          jason.notifiction.error('Fehler', 'Die gewählte Menge muss größer 0 sein');
          return;
        }

        if (this.get('baseUnitId') == this.get('selectedStock').unit.id && this.get('outgoing') > this.get('necessary')) {
          jason.notifiction.error('Fehler', 'Die gewählte Menge ist größer als die verrechnete - bitte anpassen');
          return;
        }

        var potential = false;

        if (this.get('baseUnitId') !== this.get('selectedStock').unit.id && this.get('selectedStock').conversionFactor != 1) {
          potential = true;
        }

        var self = this;
        var data = {
          entryId: this.get('entryId'),
          productId: this.get('productId'),
          offerId: this.get('offerId'),
          invoiceId: this.get('offerId'),
          treatmenPatientId: this.get('treatmentPatientId'),
          stockId: this.get('selectedStock').id,
          potentialConversion: potential,
          amount: new String(this.get('outgoing')).replace(',', '.')
        };

        setTimeout(function () {
          self.sendAction('doBookout', data);
        }, 500);

        $.magnificPopup.close();
      },
      removeMovement: function removeMovement(id) {
        var self = this;
        $.magnificPopup.close();
        setTimeout(function () {
          self.sendAction('removeMovement', id);
        }, 500);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      showLoad: function showLoad() {
        this.set('stockIdInput', '');
        this.set('showInput', true);
        this.set('showDetails', false);
        this.set('selectedStock', null);
      },
      load: function load(items, necessary, productId, productName, treatmenPatientId, offerId, entryId, connectedMovements, fullyBooked, alreadyBooked, baseUnit, baseUnitId) {

        var self = this;

        this.set('treatmentPatientId', treatmenPatientId);
        this.set('productName', productName);
        this.set('items', items);
        this.set('stockIdInput', null);

        this.set('showInput', true);
        this.set('selectedStock', null);
        this.set('showDetails', false);
        this.set('necessary', necessary);
        this.set('baseUnit', baseUnit);
        this.set('baseUnitId', baseUnitId);
        this.set('fullyBooked', fullyBooked);

        this.set('entryId', entryId);
        this.set('offerId', offerId);
        this.set('productId', productId);
        this.set('connectedMovements', connectedMovements);

        this.set('alreadyBooked', alreadyBooked);

        var outgoing = necessary - alreadyBooked;
        this.set('outgoing', outgoing);
        this.set('outgoingOriginal', outgoing);

        setTimeout(function () {
          self.autoSubmitForm();
        }, 500);

        $('#stockIdInput').focus();
      },
      selectStock: function selectStock(item) {
        this.set('selectedStock', item);
        this.set('showInput', false);
        this.set('showDetails', true);

        var flatStock = item.flatStock * item.conversionFactor;

        if (item.conversionFactor < 0) {
          flatStock = item.flatStock / item.conversionFactor * -1;
        }

        if (flatStock < this.get('outgoingOriginal')) {
          var converted = item.flatStock;
          var outgoing = new String(converted).replace('.', ',');
          this.set('outgoing', outgoing); // *
        } else {
            if (item.conversionFactor >= 0) {
              var value = this.get('outgoingOriginal') / item.conversionFactor;
              if (this.isPeriodicNumber(value)) {
                value = this.roundPeriodicNumber(value);
              }
              this.set('outgoing', new String(value).replace('.', ','));
            } else {
              var value = this.get('outgoingOriginal') * (item.conversionFactor * -1);
              if (this.isPeriodicNumber(value)) {
                value = this.roundPeriodicNumber(value);
              }
              this.set('outgoing', new String(value).replace('.', ','));
            }
          }
      }
    },
    autoSubmitForm: function autoSubmitForm() {

      var self = this;
      Ember['default'].run.later(this, function () {
        var form = $('#checkNumberForm');
        if (form && !$('#stockIdInput').prop('disabled')) {
          form.submit();
        }
        if ($(document).find('#modal-select-inventory-panel').length != 0 && !$(document).find('#modal-select-inventory-panel').hasClass('mfp-hide')) {
          self.autoSubmitForm();
        }
      }, 500);
    }
  });

});