define('jason-frontend/routes/textmodules/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    model: function model(params) {
      if (params.id > 0) {
        return this.store.find('textModule', params.id);
      } else {
        return this.store.createRecord('textModule');
      }
    },
    searchTextmodules: function searchTextmodules() {
      var self = this;
      $.ajax({
        url: "/api/textModules?type=def&filterName=" + this.get('controller').get('filterName'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('allItems', data.textModule);
        setTimeout(function () {
          $(".dragable").draggable({ revert: true, helper: "clone", appendTo: 'body' });
        }, 500);
      });
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      var self = this;

      var type = params.params['textmodules.edit'].type;
      controller.set('type', type);

      if (type === 'def') {
        this.get('controller').set('selectedTab', 'animal');
      } else if (type === 'invoice') {
        this.get('controller').set('selectedTab', 'invoice');
      } else if (type === 'email') {
        this.get('controller').set('selectedTab', 'animal');
      } else {
        this.get('controller').set('selectedTab', 'treatment');
      }

      $.ajax({
        url: "/api/editor/0/textmodule",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('editor', data.editor);
      });
      self.initEditor();

      $('body').removeClass('tiny-overlay-disabled');

      $('form.search-box input[type=text]').focus();

      setTimeout(function () {
        $.ajax({
          url: "/api/products/" + self.get('controller').get('model').id + "/textModules",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('selectedTextModules', data.textModuleArticle);
        }, function () {});
        self.searchTextmodules();
      }, 500);
    },
    initEditor: function initEditor() {
      var self = this,
          controller = self.get('controller');
      tinymce.remove();
      var dfreeBodyConfig = {
        selector: '.mce-content-body',
        license_key: 'gpl',
        menubar: false,
        language: 'de',
        inline: true,
        statusbar: false,
        quickbar: false,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        file_picker_types: 'image',
        automatic_uploads: true,
        block_unsupported_drop: false,
        paste_retain_style_properties: 'color',
        paste_webkit_styles: 'color',
        images_upload_handler: this.imageUploadHandler,
        plugins: ['autolink', 'noneditable', 'link', 'pagebreak', 'table', 'lists', 'paste', 'image'],
        setup: function setup(editor) {
          editor.ui.registry.addButton('addImage', {
            text: '',
            icon: 'image',
            onAction: function onAction(_) {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-image-upload-and-select-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
              controller.get('imageUploadAndSelectPanel').send('load', self, controller.get('type'), controller.get('model').patient ? controller.get('model').patient.id : 0);
            }
          });
        },
        toolbar: 'formatselect | bold italic underline | forecolor | fontsizeselect lineheight | alignleft aligncenter alignright alignjustify | bullist numlist | table addImage | pagebreak',
        contextmenu: 'image imagetools table lists',
        noneditable_noneditable_class: 'placeholder',
        pagebreak_separator: '<!--pagebreak-->',
        block_formats: 'Paragraph=p;Kopfzeile 1=h1;Kopfzeile 2=h2;Kopfzeile 3=h3;Kopfzeile 4=h4;Vorformatiert=pre',
        fontsize_formats: '8px 10px 12px 14px 18px 24px 36px'
      };
      setTimeout(function () {
        tinymce.init(dfreeBodyConfig);
      }, 500);
    },
    actions: {
      searchTextModules: function searchTextModules() {
        this.searchTextmodules();
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      selectTab: function selectTab(id) {
        this.get('controller').set('selectedTab', id);
      },
      insertImage: function insertImage(id) {
        tinymce.activeEditor.insertContent('<img src="http://res.cloudinary.com/myjason/image/upload/w_800,h_800,c_limit/' + id + '"/>');
      },
      selectMedia: function selectMedia(id) {
        $.ajax({
          url: "/api/media/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var hostAppendix = 'https://portal.myjason.at';
          if (location.hostname === "localhost") {
            hostAppendix = 'http://localhost:8080';
          }
          if (location.hostname === 'portal.myjason.eu') {
            hostAppendix = 'https://portal.myjason.eu';
          }
          tinymce.activeEditor.insertContent('<img src="' + hostAppendix + '/api/media/' + data.media.id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken() + '"/>');
        });
      },
      save: function save() {

        var self = this;
        var data = {
          id: this.get('controller').get('model').id,
          text: tinymce.activeEditor.getContent(),
          title: this.get('controller').get('model').get('title'),
          subject: this.get('controller').get('model').get('subject'),
          type: this.get('controller').get('type'),
          description: this.get('controller').get('model').get('description')
        };

        var id = this.get('controller').get('model').id;

        if (id === null) {
          id = 0;
        }
        $.ajax({
          url: "/api/textModules/" + id,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (id === 0) {
            Ember['default'].set(self.get('controller').get('model'), 'id', data.textModule.id);
          }
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      }
    }
  });

});