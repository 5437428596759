define('jason-frontend/templates/components/invoice-entry', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 6
              },
              "end": {
                "line": 5,
                "column": 131
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"style","line-height: 5px !important;");
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/arrow-blank.svg");
            dom.setAttribute(el2,"style","height: 5px;");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 6,
                "column": 119
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/arrow-up.svg");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element24);
            return morphs;
          },
          statements: [
            ["element","action",["moveUp",["get","entry.id",["loc",[null,[6,44],[6,52]]]]],[],["loc",[null,[6,26],[6,54]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 119
              },
              "end": {
                "line": 6,
                "column": 240
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"style","line-height: 5px !important;");
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/arrow-blank.svg");
            dom.setAttribute(el2,"style","height: 5px;");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 6
              },
              "end": {
                "line": 7,
                "column": 122
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/arrow-down.svg");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element23);
            return morphs;
          },
          statements: [
            ["element","action",["moveDown",["get","entry.id",["loc",[null,[7,45],[7,53]]]]],[],["loc",[null,[7,25],[7,55]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","input-group move-up-down");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element25 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element25,1,1);
          morphs[1] = dom.createMorphAt(element25,3,3);
          morphs[2] = dom.createMorphAt(element25,5,5);
          return morphs;
        },
        statements: [
          ["block","if",[["get","last",["loc",[null,[5,12],[5,16]]]]],[],0,null,["loc",[null,[5,6],[5,138]]]],
          ["block","unless",[["get","first",["loc",[null,[6,16],[6,21]]]]],[],1,2,["loc",[null,[6,6],[6,251]]]],
          ["block","unless",[["get","last",["loc",[null,[7,16],[7,20]]]]],[],3,null,["loc",[null,[7,6],[7,133]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 4
            },
            "end": {
              "line": 17,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"type","text");
          dom.setAttribute(el1,"class","js-datetimepicker input input--date-picker");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element22, 'value');
          morphs[1] = dom.createAttrMorph(element22, 'data-day-entry-id');
          morphs[2] = dom.createElementMorph(element22);
          return morphs;
        },
        statements: [
          ["attribute","value",["concat",[["get","entry.day",["loc",[null,[16,36],[16,45]]]]]]],
          ["attribute","data-day-entry-id",["concat",[["get","entry.id",["loc",[null,[16,121],[16,129]]]]]]],
          ["element","action",["changeDay",["get","entry",["loc",[null,[16,154],[16,159]]]],["get","invoiceId",["loc",[null,[16,160],[16,169]]]]],["on","change"],["loc",[null,[16,133],[16,183]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 19,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","entry.day",["loc",[null,[18,6],[18,19]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 2
            },
            "end": {
              "line": 46,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","cursor");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element21);
          morphs[1] = dom.createMorphAt(element21,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openSlopingInfos",["get","entry",["loc",[null,[43,50],[43,55]]]]],[],["loc",[null,[43,22],[43,57]]]],
          ["inline","button-sloping",[],["color","#999999","size","38","showStroke",true,"content","Dosierungsinformationen","cssClasses",["subexpr","css-bool-evaluator",[["subexpr","is-empty",[["get","entry.slopingInfo",["loc",[null,[44,141],[44,158]]]]],[],["loc",[null,[44,131],[44,159]]]],"","selected"],[],["loc",[null,[44,111],[44,174]]]]],["loc",[null,[44,6],[44,176]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 6
              },
              "end": {
                "line": 52,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","text");
            dom.setAttribute(el1,"class","input input--editable tooltipstered input--full-width");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element20, 'title');
            morphs[1] = dom.createAttrMorph(element20, 'data-article-text-entry-id');
            morphs[2] = dom.createAttrMorph(element20, 'value');
            morphs[3] = dom.createElementMorph(element20);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","entry.articleName",["loc",[null,[51,24],[51,41]]]]]]],
            ["attribute","data-article-text-entry-id",["concat",[["get","entry.id",["loc",[null,[51,149],[51,157]]]]]]],
            ["attribute","value",["concat",[["get","entry.articleName",["loc",[null,[51,170],[51,187]]]]]]],
            ["element","action",["changeText",["get","entry",["loc",[null,[51,213],[51,218]]]],["get","invoice.id",["loc",[null,[51,219],[51,229]]]],["get","entry.articleName",["loc",[null,[51,230],[51,247]]]],["get","entry.articleName",["loc",[null,[51,248],[51,265]]]]],["on","focusOut"],["loc",[null,[51,191],[51,281]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 6
              },
              "end": {
                "line": 54,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","entry.articleName",["loc",[null,[53,14],[53,35]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 4
            },
            "end": {
              "line": 55,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-name-editable",[["get","entry.article",["loc",[null,[50,30],[50,43]]]]],[],["loc",[null,[50,12],[50,44]]]]],[],0,1,["loc",[null,[50,6],[54,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 4
            },
            "end": {
              "line": 57,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","entry.articleName",["loc",[null,[56,12],[56,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 4
            },
            "end": {
              "line": 60,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","mt3 block small");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","entry.article.unit.name",["loc",[null,[59,36],[59,63]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 4
            },
            "end": {
              "line": 62,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","mt3 block small");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element19,0,0);
          morphs[1] = dom.createMorphAt(element19,2,2);
          return morphs;
        },
        statements: [
          ["content","entry.unitQuantity",["loc",[null,[61,36],[61,58]]]],
          ["content","entry.unit.name",["loc",[null,[61,59],[61,78]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 63,
              "column": 4
            },
            "end": {
              "line": 65,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","mt3 block small");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","entry.subText",["loc",[null,[64,36],[64,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 6
              },
              "end": {
                "line": 75,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","text");
            dom.setAttribute(el2,"class","input price-input");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element18, 'data-gross-price-entry-id');
            morphs[1] = dom.createAttrMorph(element18, 'value');
            morphs[2] = dom.createElementMorph(element18);
            return morphs;
          },
          statements: [
            ["attribute","data-gross-price-entry-id",["concat",[["get","entry.id",["loc",[null,[72,41],[72,49]]]]]]],
            ["attribute","value",["concat",[["subexpr","format-number-german",[["subexpr","get-inclusive-price",[["get","entry.price",["loc",[null,[73,63],[73,74]]]],["get","entry.tax.percentage",["loc",[null,[73,75],[73,95]]]]],[],["loc",[null,[73,42],[73,96]]]]],[],["loc",[null,[73,19],[73,98]]]]]]],
            ["element","action",["changeGrossPrice",["get","entry",["loc",[null,[73,128],[73,133]]]],["get","entry.tax.percentage",["loc",[null,[73,134],[73,154]]]],["get","invoice.id",["loc",[null,[73,155],[73,165]]]],["get","entry.article.name",["loc",[null,[73,166],[73,184]]]]],["on","focusOut"],["loc",[null,[73,100],[73,200]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 6
              },
              "end": {
                "line": 77,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","entry.price",["loc",[null,[76,59],[76,70]]]],["get","entry.tax.percentage",["loc",[null,[76,71],[76,91]]]]],[],["loc",[null,[76,38],[76,92]]]]],["loc",[null,[76,14],[76,94]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 4
            },
            "end": {
              "line": 78,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[69,17],[69,21]]]],["subexpr","not",[["subexpr","is-tgd",[["get","entry",["loc",[null,[69,35],[69,40]]]]],[],["loc",[null,[69,27],[69,41]]]]],[],["loc",[null,[69,22],[69,42]]]]],[],["loc",[null,[69,12],[69,43]]]]],[],0,1,["loc",[null,[69,6],[77,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 8
              },
              "end": {
                "line": 93,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","input-group discount-spinner-legacy");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","number");
            dom.setAttribute(el2,"class","form-control ui-spinner-input");
            dom.setAttribute(el2,"name","spinner");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var element15 = dom.childAt(element14, [1]);
            var element16 = dom.childAt(element14, [3]);
            var element17 = dom.childAt(element14, [5]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element15, 'data-discount-entry-id');
            morphs[1] = dom.createAttrMorph(element15, 'value');
            morphs[2] = dom.createElementMorph(element15);
            morphs[3] = dom.createAttrMorph(element16, 'class');
            morphs[4] = dom.createAttrMorph(element16, 'data-qty-entry-id');
            morphs[5] = dom.createElementMorph(element16);
            morphs[6] = dom.createAttrMorph(element17, 'class');
            morphs[7] = dom.createAttrMorph(element17, 'data-qty-entry-id');
            morphs[8] = dom.createElementMorph(element17);
            return morphs;
          },
          statements: [
            ["attribute","data-discount-entry-id",["concat",[["get","entry.id",["loc",[null,[86,49],[86,57]]]]]]],
            ["attribute","value",["concat",[["get","entry.discount",["loc",[null,[87,32],[87,46]]]]]]],
            ["element","action",["changeDiscount",["get","entry",["loc",[null,[87,76],[87,81]]]],["get","invoice.id",["loc",[null,[87,82],[87,92]]]],["get","entry.absoluteDiscount",["loc",[null,[87,93],[87,115]]]],["get","entry.article.name",["loc",[null,[87,116],[87,134]]]],["get","entry.discount",["loc",[null,[87,135],[87,149]]]]],["on","focusOut"],["loc",[null,[87,50],[87,165]]]],
            ["attribute","class",["concat",["quantityspinn btn percentage ",["subexpr","get-relative-discount-class",[["get","entry.absoluteDiscount",["loc",[null,[88,85],[88,107]]]]],[],["loc",[null,[88,55],[88,109]]]]]]],
            ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[89,40],[89,48]]]]]]],
            ["element","action",["setRelativeDiscount",["get","entry",["loc",[null,[89,83],[89,88]]]],["get","invoice.id",["loc",[null,[89,89],[89,99]]]],["get","entry.discount",["loc",[null,[89,100],[89,114]]]],["get","entry.absoluteDiscount",["loc",[null,[89,115],[89,137]]]],["get","entry.article.name",["loc",[null,[89,138],[89,156]]]]],[],["loc",[null,[89,52],[89,158]]]],
            ["attribute","class",["concat",["quantityspinn btn absolute ",["subexpr","get-absolute-discount-class",[["get","entry.absoluteDiscount",["loc",[null,[90,83],[90,105]]]]],[],["loc",[null,[90,53],[90,107]]]]]]],
            ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[91,40],[91,48]]]]]]],
            ["element","action",["setAbsoluteDiscount",["get","entry",["loc",[null,[91,83],[91,88]]]],["get","invoice.id",["loc",[null,[91,89],[91,99]]]],["get","entry.discount",["loc",[null,[91,100],[91,114]]]],["get","entry.absoluteDiscount",["loc",[null,[91,115],[91,137]]]],["get","entry.article.name",["loc",[null,[91,138],[91,156]]]]],[],["loc",[null,[91,52],[91,158]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 10
                },
                "end": {
                  "line": 96,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.discount",["loc",[null,[95,36],[95,50]]]]],[],[]]],["loc",[null,[95,12],[95,52]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 96,
                  "column": 10
                },
                "end": {
                  "line": 98,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" %\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","entry.discount",["loc",[null,[97,12],[97,30]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 8
              },
              "end": {
                "line": 99,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","entry.absoluteDiscount",["loc",[null,[94,16],[94,38]]]]],[],0,1,["loc",[null,[94,10],[98,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 6
            },
            "end": {
              "line": 100,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[83,19],[83,23]]]],["subexpr","not",[["subexpr","is-tgd",[["get","entry",["loc",[null,[83,37],[83,42]]]]],[],["loc",[null,[83,29],[83,43]]]]],[],["loc",[null,[83,24],[83,44]]]]],[],["loc",[null,[83,14],[83,45]]]]],[],0,1,["loc",[null,[83,8],[99,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 8
              },
              "end": {
                "line": 111,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element13);
            morphs[1] = dom.createMorphAt(element13,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadBarcode",["get","entry.laboklinFormId",["loc",[null,[108,40],[108,60]]]]],[],["loc",[null,[108,13],[108,62]]]],
            ["inline","button-barcode",[],["size","35","showStroke",true,"content","Dosierungs-Etikett herunterladen"],["loc",[null,[109,12],[109,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 112,
                "column": 8
              },
              "end": {
                "line": 116,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element12);
            morphs[1] = dom.createMorphAt(element12,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadSlopingBarcode",["get","entry.id",["loc",[null,[113,47],[113,55]]]]],[],["loc",[null,[113,13],[113,57]]]],
            ["inline","button-barcode",[],["size","35","showStroke",true,"content","Etikett herunterladen"],["loc",[null,[114,12],[114,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 6
            },
            "end": {
              "line": 117,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","gt",[["get","entry.laboklinFormId",["loc",[null,[107,18],[107,38]]]],0],[],["loc",[null,[107,14],[107,41]]]]],[],0,null,["loc",[null,[107,8],[111,15]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","entry.article.lab",["loc",[null,[112,24],[112,41]]]]],[],["loc",[null,[112,19],[112,42]]]],["subexpr","not",[["get","entry.article.privateLab",["loc",[null,[112,48],[112,72]]]]],[],["loc",[null,[112,43],[112,73]]]]],[],["loc",[null,[112,14],[112,74]]]]],[],1,null,["loc",[null,[112,8],[116,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 124,
                    "column": 12
                  },
                  "end": {
                    "line": 128,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"checked","");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                if (this.cachedFragment) { dom.repairClonedNode(element10,[],true); }
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element10, 'value');
                morphs[1] = dom.createAttrMorph(element10, 'data-bankadvice-entry-id');
                morphs[2] = dom.createAttrMorph(element10, 'id');
                morphs[3] = dom.createElementMorph(element10);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.bankadvice",["loc",[null,[125,32],[125,48]]]]]]],
                ["attribute","data-bankadvice-entry-id",["concat",[["get","entry.id",["loc",[null,[125,80],[125,88]]]]]]],
                ["attribute","id",["concat",["bankAdvice",["get","entry.id",["loc",[null,[126,39],[126,47]]]]]]],
                ["element","action",["changeBankadvice",["get","entry",["loc",[null,[127,67],[127,72]]]],["get","invoice.id",["loc",[null,[127,73],[127,83]]]],["get","entry.article.name",["loc",[null,[127,84],[127,102]]]]],[],["loc",[null,[127,39],[127,104]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 128,
                    "column": 12
                  },
                  "end": {
                    "line": 132,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element9, 'value');
                morphs[1] = dom.createAttrMorph(element9, 'data-bankadvice-entry-id');
                morphs[2] = dom.createAttrMorph(element9, 'id');
                morphs[3] = dom.createElementMorph(element9);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.bankadvice",["loc",[null,[129,32],[129,48]]]]]]],
                ["attribute","data-bankadvice-entry-id",["concat",[["get","entry.id",["loc",[null,[129,80],[129,88]]]]]]],
                ["attribute","id",["concat",["bankAdvice",["get","entry.id",["loc",[null,[130,39],[130,47]]]]]]],
                ["element","action",["changeBankadvice",["get","entry",["loc",[null,[131,67],[131,72]]]],["get","invoice.id",["loc",[null,[131,73],[131,83]]]],["get","entry.article.name",["loc",[null,[131,84],[131,102]]]]],[],["loc",[null,[131,39],[131,104]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 123,
                  "column": 10
                },
                "end": {
                  "line": 136,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [2]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createAttrMorph(element11, 'for');
              morphs[2] = dom.createMorphAt(element11,1,1);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","entry.bankadvice",["loc",[null,[124,18],[124,34]]]]],[],0,1,["loc",[null,[124,12],[132,19]]]],
              ["attribute","for",["concat",["bankAdvice",["get","entry.id",["loc",[null,[133,38],[133,46]]]]]]],
              ["inline","button-bankadvice",[],["size","38"],["loc",[null,[134,14],[134,45]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 122,
                "column": 8
              },
              "end": {
                "line": 137,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["subexpr","is-deposit-invoice",[["get","invoice",["loc",[null,[123,41],[123,48]]]]],[],["loc",[null,[123,21],[123,49]]]]],[],["loc",[null,[123,16],[123,50]]]]],[],0,null,["loc",[null,[123,10],[136,17]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 138,
                  "column": 10
                },
                "end": {
                  "line": 140,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"checked","true");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(fragment, [1]),[],true); }
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element7, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[139,41],[139,49]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 140,
                  "column": 10
                },
                "end": {
                  "line": 142,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element6, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[141,41],[141,49]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 137,
                "column": 8
              },
              "end": {
                "line": 146,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [2]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createAttrMorph(element8, 'for');
            morphs[2] = dom.createMorphAt(element8,1,1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","entry.bankadvice",["loc",[null,[138,16],[138,32]]]]],[],0,1,["loc",[null,[138,10],[142,17]]]],
            ["attribute","for",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[143,40],[143,48]]]]]]],
            ["inline","button-bankadvice",[],["size","38"],["loc",[null,[144,14],[144,45]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 121,
              "column": 6
            },
            "end": {
              "line": 147,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","edit",["loc",[null,[122,14],[122,18]]]]],[],0,1,["loc",[null,[122,8],[146,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 152,
                "column": 12
              },
              "end": {
                "line": 156,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"checked","");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element3,[],true); }
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element3, 'value');
            morphs[1] = dom.createAttrMorph(element3, 'data-applied-entry-id');
            morphs[2] = dom.createAttrMorph(element3, 'id');
            morphs[3] = dom.createElementMorph(element3);
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","entry.applied",["loc",[null,[153,30],[153,43]]]]]]],
            ["attribute","data-applied-entry-id",["concat",[["get","entry.id",["loc",[null,[153,72],[153,80]]]]]]],
            ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[154,39],[154,47]]]]]]],
            ["element","action",["changeApplied",["get","entry",["loc",[null,[155,59],[155,64]]]],["get","invoice.id",["loc",[null,[155,65],[155,75]]]],["get","entry.article.name",["loc",[null,[155,76],[155,94]]]]],[],["loc",[null,[155,34],[155,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 156,
                "column": 12
              },
              "end": {
                "line": 160,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'value');
            morphs[1] = dom.createAttrMorph(element2, 'data-applied-entry-id');
            morphs[2] = dom.createAttrMorph(element2, 'id');
            morphs[3] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","entry.applied",["loc",[null,[157,30],[157,43]]]]]]],
            ["attribute","data-applied-entry-id",["concat",[["get","entry.id",["loc",[null,[157,72],[157,80]]]]]]],
            ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[158,39],[158,47]]]]]]],
            ["element","action",["changeApplied",["get","entry",["loc",[null,[159,59],[159,64]]]],["get","invoice.id",["loc",[null,[159,65],[159,75]]]],["get","entry.article.name",["loc",[null,[159,76],[159,94]]]]],[],["loc",[null,[159,34],[159,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 150,
              "column": 4
            },
            "end": {
              "line": 165,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","checkbox-wrapper");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element4,1,1);
          morphs[1] = dom.createAttrMorph(element5, 'for');
          morphs[2] = dom.createMorphAt(element5,1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","entry.applied",["loc",[null,[152,18],[152,31]]]]],[],0,1,["loc",[null,[152,12],[160,19]]]],
          ["attribute","for",["concat",["checkboxExample",["get","entry.id",["loc",[null,[161,41],[161,49]]]]]]],
          ["inline","button-applied",[],["size","38","selected",["subexpr","@mut",[["get","entry.applied",["loc",[null,[162,51],[162,64]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[162,93],[162,120]]]],"Verabreicht","Angewandt"],[],["loc",[null,[162,73],[162,147]]]]],["loc",[null,[162,14],[162,149]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 169,
                "column": 8
              },
              "end": {
                "line": 173,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["openInventoryStockPanel",["get","entry",["loc",[null,[170,63],[170,68]]]]],[],["loc",[null,[170,28],[170,70]]]],
            ["inline","button-inventory",[],["color","#999999","size","34","showStroke",true,"selected",["subexpr","@mut",[["get","entry.fullyBooked",["loc",[null,[171,82],[171,99]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["get","entry.fullyBooked",["loc",[null,[171,128],[171,145]]]],"Warenentnahme: Vollständig ausgebucht","Warenentnahme: Noch nicht ausgebucht"],[],["loc",[null,[171,108],[171,225]]]],"cssClasses",["subexpr","css-bool-evaluator",[["get","entry.fullyBooked",["loc",[null,[171,257],[171,274]]]],"fullybooked","notbooked"],[],["loc",[null,[171,237],[171,301]]]]],["loc",[null,[171,12],[171,303]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 167,
              "column": 4
            },
            "end": {
              "line": 175,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","checkbox-wrapper verticaltop pt2");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-ehapo-available",[["get","entry",["loc",[null,[169,34],[169,39]]]],["get","entry.article",["loc",[null,[169,40],[169,53]]]]],[],["loc",[null,[169,14],[169,54]]]]],[],0,null,["loc",[null,[169,8],[173,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 176,
              "column": 4
            },
            "end": {
              "line": 182,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","checkbox-wrapper verticaltop pt2");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openTemplatePanel",["get","invoice.id",["loc",[null,[178,38],[178,48]]]],["get","entry.templateInstanceId",["loc",[null,[178,49],[178,73]]]],true,["get","entry.patient",["loc",[null,[178,79],[178,92]]]]],[],["loc",[null,[178,9],[178,94]]]],
          ["inline","button-template",[],["size","34","selected",true,"showStroke",true,"content","Textvorlage bearbeiten","cssClasses",""],["loc",[null,[179,8],[179,114]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 186,
              "column": 4
            },
            "end": {
              "line": 189,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","small block");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" % Mwst");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.total",["loc",[null,[187,33],[187,44]]]]],[],[]]],["loc",[null,[187,9],[187,46]]]],
          ["content","entry.tax.percentage",["loc",[null,[188,30],[188,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 224,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center l-1/24");
        dom.setAttribute(el1,"data-label","");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--toggle l-3/24 l-1/24@tablet");
        dom.setAttribute(el1,"data-label","");
        var el2 = dom.createTextNode("\n     ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","cursor");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell l-9/24 l-4/24@tablet l-2/24@desk ");
        dom.setAttribute(el1,"data-label","Tag");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center table__cell--no-padding l-10/24 l-4/24@tablet l-2/24@desk");
        dom.setAttribute(el1,"data-label","Menge");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","spinner spinner--arrows");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","spinner__plus");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el4 = dom.createElement("svg");
        dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el4,"width","16");
        dom.setAttribute(el4,"height","16");
        dom.setAttribute(el4,"viewBox","0 0 16 16");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"fill","#ADCCD4");
        dom.setAttribute(el5,"fill-rule","evenodd");
        dom.setAttribute(el5,"d","M2.66375576,7.964 C2.49395855,7.964 2.32389582,7.90120761 2.19445697,7.77549415 C1.93518101,7.52432458 1.93518101,7.11694607 2.19445697,6.8657765 L5.68545581,3.48193566 L2.19445697,0.0982234982 C1.93518101,-0.153074743 1.93518101,-0.560453255 2.19445697,-0.811622824 C2.45346742,-1.06279239 2.8740441,-1.06279239 3.13305455,-0.811622824 L7.56291648,3.48193566 L3.13305455,7.77549415 C3.0036157,7.90120761 2.83355297,7.964 2.66375576,7.964 L2.66375576,7.964 Z");
        dom.setAttribute(el5,"transform","rotate(-90 8.781 4.482)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        dom.setNamespace(null);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3,"type","text");
        dom.setAttribute(el3,"class","input spinner__input");
        dom.setAttribute(el3,"name","spinner");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","spinner__minus");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el4 = dom.createElement("svg");
        dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el4,"width","16");
        dom.setAttribute(el4,"height","16");
        dom.setAttribute(el4,"viewBox","0 0 16 16");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"fill","#ADCCD4");
        dom.setAttribute(el5,"fill-rule","evenodd");
        dom.setAttribute(el5,"d","M2.66375576,7.964 C2.49395855,7.964 2.32389582,7.90120761 2.19445697,7.77549415 C1.93518101,7.52432458 1.93518101,7.11694607 2.19445697,6.8657765 L5.68545581,3.48193566 L2.19445697,0.0982234982 C1.93518101,-0.153074743 1.93518101,-0.560453255 2.19445697,-0.811622824 C2.45346742,-1.06279239 2.8740441,-1.06279239 3.13305455,-0.811622824 L7.56291648,3.48193566 L3.13305455,7.77549415 C3.0036157,7.90120761 2.83355297,7.964 2.66375576,7.964 L2.66375576,7.964 Z");
        dom.setAttribute(el5,"transform","matrix(0 1 1 0 4.3 3.737)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--left l-4/24 l-4/24@tablet l-1/24@desk");
        dom.setAttribute(el1,"data-label","Dosierung");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell l-15/24@tablet l-5/24@desk");
        dom.setAttribute(el1,"data-label","Artikel/Leistung");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center--desktop table__cell--right l-8/24 l-2/24@desk");
        dom.setAttribute(el1,"data-label","Brutto");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center l-8/24 l-6/24@tablet l-3/24@desk");
        dom.setAttribute(el1,"data-label","Rabatt");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","single-choice");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--right l-12/24@phone l-8/24@tablet l-4/24@desk icon-bar");
        dom.setAttribute(el1,"data-label","");
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("pt5 pb0");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","checkbox-wrapper");
        dom.setAttribute(el2,"style","vertical-align: top;padding-top: 0px;");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","checkbox-wrapper");
        dom.setAttribute(el2,"style","width:  31px;");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--right l-12/24@phone  l-9/24@tablet l-2/24@desk");
        dom.setAttribute(el1,"data-label","Gesamtpreis");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--action table__cell--right l-1/24");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","exp icon-button icon-button--delete list-action-square");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__second-row hidden");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__cell l-1/24 table__cell--paceholder");
        dom.setAttribute(el2,"data-label","");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__cell l-12/24@tablet l-6/24@desk");
        dom.setAttribute(el2,"data-label","");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Arzt:");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__cell l-12/24@tablet l-8/24@desk");
        dom.setAttribute(el2,"data-label","");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Assistent/in:");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element26 = dom.childAt(fragment, [6, 1]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element26, [3]);
        var element29 = dom.childAt(element26, [5]);
        var element30 = dom.childAt(fragment, [10]);
        var element31 = dom.childAt(fragment, [16]);
        var element32 = dom.childAt(fragment, [20, 1]);
        var element33 = dom.childAt(fragment, [22]);
        var morphs = new Array(27);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]),1,1);
        morphs[3] = dom.createAttrMorph(element27, 'data-qty-entry-id');
        morphs[4] = dom.createElementMorph(element27);
        morphs[5] = dom.createAttrMorph(element28, 'data-qty-entry-id');
        morphs[6] = dom.createAttrMorph(element28, 'value');
        morphs[7] = dom.createElementMorph(element28);
        morphs[8] = dom.createAttrMorph(element29, 'data-qty-entry-id');
        morphs[9] = dom.createElementMorph(element29);
        morphs[10] = dom.createMorphAt(dom.childAt(fragment, [8]),1,1);
        morphs[11] = dom.createMorphAt(element30,1,1);
        morphs[12] = dom.createMorphAt(element30,2,2);
        morphs[13] = dom.createMorphAt(element30,3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(fragment, [12]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(fragment, [14, 1]),1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element31, [3]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element31, [5]),1,1);
        morphs[18] = dom.createMorphAt(element31,7,7);
        morphs[19] = dom.createMorphAt(element31,9,9);
        morphs[20] = dom.createMorphAt(element31,10,10);
        morphs[21] = dom.createMorphAt(dom.childAt(fragment, [18]),1,1);
        morphs[22] = dom.createElementMorph(element32);
        morphs[23] = dom.createMorphAt(element32,1,1);
        morphs[24] = dom.createAttrMorph(element33, 'data-id');
        morphs[25] = dom.createMorphAt(dom.childAt(element33, [3]),3,3);
        morphs[26] = dom.createMorphAt(dom.childAt(element33, [5]),3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[3,13],[3,17]]]],["subexpr","gt",[["get","entry.position",["loc",[null,[3,22],[3,36]]]],0],[],["loc",[null,[3,18],[3,39]]]]],[],["loc",[null,[3,8],[3,40]]]]],[],0,null,["loc",[null,[3,2],[9,9]]]],
        ["inline","button-toggle",[],["entry",["subexpr","@mut",[["get","entry",["loc",[null,[12,45],[12,50]]]]],[],[]],"toggle","toggle"],["loc",[null,[12,23],[12,68]]]],
        ["block","if",[["get","edit",["loc",[null,[15,10],[15,14]]]]],[],1,2,["loc",[null,[15,4],[19,11]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[23,52],[23,60]]]]]]],
        ["element","action",["increaseQuantity",["get","entry",["loc",[null,[23,92],[23,97]]]],["get","invoice.id",["loc",[null,[23,98],[23,108]]]],["get","entry.article.name",["loc",[null,[23,109],[23,127]]]]],[],["loc",[null,[23,64],[23,129]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[31,75],[31,83]]]]]]],
        ["attribute","value",["concat",[["get","entry.quantity",["loc",[null,[31,111],[31,125]]]]]]],
        ["element","action",["changeQuantity",["get","entry",["loc",[null,[31,155],[31,160]]]],["get","invoice.id",["loc",[null,[31,161],[31,171]]]],["get","entry.article.name",["loc",[null,[31,172],[31,190]]]],["get","entry.quantity",["loc",[null,[31,191],[31,205]]]]],["on","focusOut"],["loc",[null,[31,129],[31,221]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[33,53],[33,61]]]]]]],
        ["element","action",["decreaseQuantity",["get","entry",["loc",[null,[33,93],[33,98]]]],["get","invoice.id",["loc",[null,[33,99],[33,109]]]],["get","entry.article.name",["loc",[null,[33,110],[33,128]]]]],[],["loc",[null,[33,65],[33,130]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.type",["loc",[null,[42,17],[42,27]]]],"def"],[],["loc",[null,[42,13],[42,34]]]],["get","edit",["loc",[null,[42,36],[42,40]]]],["subexpr","is-product",[["get","entry",["loc",[null,[42,53],[42,58]]]]],[],["loc",[null,[42,41],[42,59]]]]],[],["loc",[null,[42,8],[42,60]]]]],[],3,null,["loc",[null,[42,2],[46,9]]]],
        ["block","if",[["get","edit",["loc",[null,[49,10],[49,14]]]]],[],4,5,["loc",[null,[49,4],[57,11]]]],
        ["block","unless",[["get","entry.unit",["loc",[null,[58,14],[58,24]]]]],[],6,7,["loc",[null,[58,4],[62,15]]]],
        ["block","if",[["get","entry.subText",["loc",[null,[63,10],[63,23]]]]],[],8,null,["loc",[null,[63,4],[65,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","entry.type",["loc",[null,[68,18],[68,28]]]],"def"],[],["loc",[null,[68,14],[68,35]]]],["subexpr","eq",[["get","entry.type",["loc",[null,[68,40],[68,50]]]],"template"],[],["loc",[null,[68,36],[68,62]]]]],[],["loc",[null,[68,10],[68,63]]]]],[],9,null,["loc",[null,[68,4],[78,11]]]],
        ["block","if",[["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","entry.type",["loc",[null,[82,25],[82,35]]]],"template"],[],["loc",[null,[82,21],[82,47]]]],["subexpr","eq",[["get","entry.type",["loc",[null,[82,52],[82,62]]]],"def"],[],["loc",[null,[82,48],[82,69]]]]],[],["loc",[null,[82,17],[82,70]]]],["subexpr","is-discount-available",[["get","entry.article",["loc",[null,[82,94],[82,107]]]],["get","type",["loc",[null,[82,108],[82,112]]]]],[],["loc",[null,[82,71],[82,113]]]]],[],["loc",[null,[82,12],[82,114]]]]],[],10,null,["loc",[null,[82,6],[100,13]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[106,16],[106,26]]]],"def"],[],["loc",[null,[106,12],[106,33]]]]],[],11,null,["loc",[null,[106,6],[117,13]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[121,16],[121,26]]]],"def"],[],["loc",[null,[121,12],[121,33]]]]],[],12,null,["loc",[null,[121,6],[147,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.type",["loc",[null,[150,19],[150,29]]]],"def"],[],["loc",[null,[150,15],[150,36]]]],["subexpr","is-applied-available",[["get","entry",["loc",[null,[150,59],[150,64]]]],["get","type",["loc",[null,[150,65],[150,69]]]]],[],["loc",[null,[150,37],[150,70]]]]],[],["loc",[null,[150,10],[150,71]]]]],[],13,null,["loc",[null,[150,4],[165,11]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.type",["loc",[null,[167,19],[167,29]]]],"def"],[],["loc",[null,[167,15],[167,36]]]],["subexpr","not",[["get","entry.bankadvice",["loc",[null,[167,42],[167,58]]]]],[],["loc",[null,[167,37],[167,59]]]],["subexpr","not",[["subexpr","is-deposit-entry",[["get","entry",["loc",[null,[167,83],[167,88]]]]],[],["loc",[null,[167,65],[167,89]]]]],[],["loc",[null,[167,60],[167,90]]]]],[],["loc",[null,[167,10],[167,91]]]]],[],14,null,["loc",[null,[167,4],[175,11]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[176,14],[176,24]]]],"template"],[],["loc",[null,[176,10],[176,36]]]]],[],15,null,["loc",[null,[176,4],[182,11]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[186,14],[186,24]]]],"def"],[],["loc",[null,[186,10],[186,31]]]]],[],16,null,["loc",[null,[186,4],[189,11]]]],
        ["element","action",["delete",["get","entry.id",["loc",[null,[192,25],[192,33]]]],["get","invoice.id",["loc",[null,[192,34],[192,44]]]],["get","entry.article.name",["loc",[null,[192,45],[192,63]]]]],[],["loc",[null,[192,7],[192,65]]]],
        ["inline","button-delete-list",[],["size","40","content","","showStroke",false,"color","#fff"],["loc",[null,[193,6],[193,79]]]],
        ["attribute","data-id",["concat",[["get","entry.id",["loc",[null,[197,51],[197,59]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[202,18],[202,25]]]]],[],[]],"value",["subexpr","@mut",[["get","doctor",["loc",[null,[203,16],[203,22]]]]],[],[]],"cssClass","input--dropdown input--small doctor unchanged entry-doctor","optionLabelPath","shortName","allowClear",true,"placeholder","keine/r"],["loc",[null,[201,8],[208,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[213,16],[213,26]]]]],[],[]],"value",["subexpr","@mut",[["get","assistant",["loc",[null,[214,14],[214,23]]]]],[],[]],"cssClass","input--dropdown input--small assistant unchanged entry-assistant","optionLabelPath","shortName","allowClear",true,"placeholder","keine/r"],["loc",[null,[212,6],[219,10]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16]
    };
  }()));

});