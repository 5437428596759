define('jason-frontend/models/mandant-account', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    number: DS['default'].attr('string'),
    name: DS['default'].attr('string'),
    shortName: DS['default'].attr('string'),
    internalId: DS['default'].attr('number')
  });

});