define('jason-frontend/components/patients-edit-form', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    store: Ember['default'].inject.service(),
    digitalRaceEnabled: true,
    watchCounter: 1,
    actions: {
      openChipPanel: function openChipPanel(model) {
        this.sendAction('openChipPanel', model);
      },
      showImagePanel: function showImagePanel() {
        var controller = this.get('controller');
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-image-upload-and-select-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        controller.get('imageUploadAndSelectPanel').send('load', self, 'simple', 0);
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.get('store').find('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      },
      openLogbook: function openLogbook() {
        this.sendAction('openLogbook');
      },
      imageChanged: function imageChanged(id) {
        this.set('patientImage', id);
        this.sendAction('imageChanged', id);
      },
      changeUser: function changeUser(id) {
        this.sendAction('changeUser', id);
      }
    },
    watchCategory: (function () {

      var self = this;

      if (this.get('digitalRaceEnabled') === true) {
        setTimeout(function () {
          try {
            $.ajax({
              url: "/api/digitalRaces/" + self.get('model').get('category').get('id'),
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              if (data.digitalRace.length > 0) {
                if (self.get('model').get('digitalRace')) {
                  if (self.get('model').get('category').get('id') !== self.get('model').get('digitalRace').patientCategoryId) {
                    self.get('model').set('digitalRace', null);
                  }
                }
              }
              self.set('digitalRaces', data.digitalRace);
            });
          } catch (e) {}
        }, 200);
      }
    }).observes('model.category')
  });

});