define('jason-frontend/models/template', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    attributes: DS['default'].attr('string'),
    category: DS['default'].attr(),
    priceNet: DS['default'].attr(),
    tax: DS['default'].attr(),
    signatureRequested: DS['default'].attr('boolean'),
    editable: DS['default'].attr('boolean')
  });

});