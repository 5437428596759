define('jason-frontend/helpers/show-consultation', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isConsultationShown = isConsultationShown;

  function isConsultationShown() {
    return API['default'].getMandant() >= 10000 || Number(API['default'].getMandant()) === 153 || Number(API['default'].getMandant()) === 3 || Number(API['default'].getMandant()) === 109 || Number(API['default'].getMandant()) === 228 || Number(API['default'].getMandant()) === 761 || Number(API['default'].getMandant()) === 781;
  }

  exports['default'] = Ember['default'].Helper.helper(isConsultationShown);

});