define('jason-frontend/components/search-inventory-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    externalContext: null,
    selectedArticle: null,
    selectedStock: null,
    viewName: 'product',
    correctionTypes: [{ name: "Bestand", id: "existing" }, { name: "Schwund", id: "fade" }, { name: "Diebstahl", id: "theft" }, { name: "Ordinationsverbrauch", id: "practiceusage" }, { name: "Zugang", id: "add" }, { name: "Abgelaufen", id: "expired" }, { name: "Ausgelaufen", id: "notavailable" }, { name: "Ausschuss", id: "waste" }, { name: "Abschreiben", id: "deprecate" }],
    applyFilter: function applyFilter() {
      var filterName = this.get('filterName');
      var self = this;
      this.externalContext.findPaged('aggInventoryStock', {
        filterCode: filterName,
        pageSize: this.get('perPage')
      }).then(function (entries) {
        self.set('items', entries);
        self.set('showLoading', false);
      });
    },
    actions: {
      confirmCorrection: function confirmCorrection() {
        var self = this;
        var shouldStand = parseFloat(String(this.get('selectedStock').shouldStand).replace(",", "."));

        if (shouldStand < 0) {
          jason.notifiction.error('Fehler', 'Der IST-Bestand muss größer/gleich 0 sein');
          return;
        }
        if (shouldStand > this.get('selectedStock').packageQuantity) {
          //!this.get('warningSend') &&
          jason.notifiction.errorLong('Achtung', 'Der IST-Bestand darf nicht größer als die Verpackungsmenge sein');
          return;
        }

        Ember['default'].set(this.get('selectedStock'), 'subType', this.get('selectedStock').subType.id);
        Ember['default'].set(this.get('selectedStock'), 'stocktakingId', this.get('stocktakingId'));
        Ember['default'].set(this.get('selectedStock'), 'shouldStand', shouldStand);
        this.sendAction('save', this.get('selectedStock'), this.get('stocktakingId'), false, false);
        this.set('viewName', 'vpe');
        this.set('selectedStock', null);

        setTimeout(function () {
          var article = self.get('selectedArticle');
          $.ajax({
            url: "/api/inventoryStocks/product/" + article.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('stockItems', data.inventoryStock);
            self.set('stockSummary', data.meta.summary);
          }, function () {});
        }, 1000);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      back: function back() {
        this.set('selectedArticle', null);
        this.set('selectedStock', null);
        this.set('viewName', 'product');
      },
      backToVpe: function backToVpe() {
        this.set('viewName', 'vpe');
        this.set('selectedStock', null);
      },
      selectStock: function selectStock(item) {
        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + item.id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('viewName', 'correction');
          self.set('selectedStock', data.inventoryStock);
          Ember['default'].set(self.get('selectedStock'), 'subType', {
            id: 'existing',
            name: 'Bestand'
          });
          Ember['default'].set(self.get('selectedStock'), 'shouldStand', item.flatStock);
        }, function () {});
      },
      selectProduct: function selectProduct(item) {
        var self = this;

        $.ajax({
          url: "/api/products/" + item.get('productId'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('viewName', 'vpe');
          self.set('selectedArticle', data.product);
        }, function () {});

        $.ajax({
          url: "/api/inventoryStocks/product/" + item.get('productId'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('stockItems', data.inventoryStock);
          self.set('stockSummary', data.meta.summary);
        }, function () {});
      },
      search: function search() {
        this.applyFilter();
      },
      load: function load(externalContext, id) {
        this.set('externalContext', externalContext);
        this.set('selectedArticle', null);
        this.set('stocktakingId', id);
        this.set('viewName', 'product');
        setTimeout(function () {
          $('.search__input').focus();
        }, 1000);
        this.applyFilter();
      }
    }
  });

});